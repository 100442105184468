import { TeamOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Col,
  Descriptions,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import Api from "../../../api/api";
import podium from "../../../assets/images/podium.png";
import quickbooks from "../../../assets/images/quickbooks.png";
import FloatingCard from "../../../components/FloatingCard";
import { useAuthorization } from "../../../providers/AuthorizationContext";

const { Title } = Typography;

import { CopyToClipboard } from "react-copy-to-clipboard";

interface ZapierApiKeyDisplayProps {
  apiKey: string;
}

const ZapierApiKeyDisplay: React.FC<ZapierApiKeyDisplayProps> = ({
  apiKey,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    message.success("API Key copied to clipboard");
  };

  return (
    <>
      <CopyToClipboard
        text={apiKey}
        onCopy={handleCopy}
      >
        <Button type="default">
          {copied ? "Copied!" : "Copy to clipboard"}
        </Button>
      </CopyToClipboard>
      <Input
        style={{ border: "none" }}
        value={apiKey}
        readOnly
      />
    </>
  );
};

const handleSyncPodiumContacts = async () => {
  // Call the API to redirect to the Podium authn page
  const link = await Api.getPodiumAuthenticationLink();
  window.location.replace(link);
};

const handleSyncQuickbookContacts = async () => {
  // Call the API to redirect to the Podium authn page
  const link = await Api.getIntuitAuthenticationLink();
  window.location.replace(link);
};

const Integrations: React.FC<{ zapierApiKey: string }> = ({ zapierApiKey }) => {
  const { hasAllLinkPermissions } = useAuthorization();
  return (
    <FloatingCard
      style={{ width: "100%" }}
      title={
        <Title
          level={3}
          style={{ marginTop: 12 }}
        >
          <Row gutter={[10, 10]}>
            <Col>
              <Avatar icon={<TeamOutlined />} />
            </Col>
            <Col style={{ paddingTop: 7 }}>Integrations</Col>
          </Row>
        </Title>
      }
    >
      <Space
        style={{ width: "100%" }}
        direction="vertical"
      >
        {!hasAllLinkPermissions && (
          <Row>
            <Col flex="auto">
              <Alert
                message={
                  <Row justify="center">
                    <Col>
                      <Typography.Text>
                        Free accounts do not include integrations.
                      </Typography.Text>
                    </Col>
                  </Row>
                }
                type="info"
                showIcon={false}
                closable={false}
                banner
              />
            </Col>
          </Row>
        )}

        <Row
          gutter={[10, 10]}
          justify="start"
        >
          <Col>
            <Descriptions
              layout="vertical"
              colon={false}
              size="small"
              // column={2}
            >
              <>
                <Descriptions.Item label="Zapier API Key">
                  <ZapierApiKeyDisplay apiKey={zapierApiKey} />
                </Descriptions.Item>
              </>
            </Descriptions>
          </Col>
        </Row>
        <Row
          gutter={[10, 10]}
          justify="start"
        >
          <Col>
            <Descriptions
              layout="vertical"
              colon={false}
              size="small"
              // column={2}
            >
              <>
                <Descriptions.Item label="Other Integrations">
                  <Space>
                    <Button
                      size="large"
                      type="default"
                      onClick={handleSyncPodiumContacts}
                      disabled={!hasAllLinkPermissions}
                    >
                      <img
                        src={podium}
                        style={{ width: 20, marginRight: 5 }}
                      />{" "}
                      Sync Podium Contacts
                    </Button>
                    <Button
                      size="large"
                      type="default"
                      onClick={handleSyncQuickbookContacts}
                      disabled={!hasAllLinkPermissions}
                    >
                      <img
                        src={quickbooks}
                        style={{ width: 20, marginRight: 5 }}
                      />{" "}
                      Quickbook
                    </Button>
                  </Space>
                </Descriptions.Item>
              </>
            </Descriptions>
          </Col>
        </Row>
      </Space>
    </FloatingCard>
  );
};

export default Integrations;
