import styled from "@emotion/styled";
import type { MenuProps } from "antd";
import { Col, Dropdown, Row, Typography } from "antd";
import { useHistory } from "react-router-dom";

const MobileSiderStyled = styled.div({
  "&.mobileSiderRoot": {
    width: "100%",
    height: "50px",
    marginTop: 3,
    ".navigationLink": {
      fontSize: 16,
      textAlign: "center",
      padding: 5,
    },
    ".navigationText": {
      fontSize: 11,
    },
  },
});

import {
  BarChartOutlined,
  CreditCardOutlined,
  FlagOutlined,
  HomeOutlined,
  LikeOutlined,
  LogoutOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

export default ({ handleLogout }) => {
  const history = useHistory();

  const items: MenuProps["items"] = [
    {
      label: (
        <div onClick={() => history.push("/company-settings")}>Company</div>
      ),
      key: "0",
      style: {
        fontSize: "1rem",
      },
    },
    {
      label: (
        <div onClick={() => history.push("/referral-settings")}>Referral</div>
      ),
      key: "1",
      style: {
        fontSize: "1rem",
      },
    },
  ];

  return (
    <MobileSiderStyled className="mobileSiderRoot">
      <Row
        className="mobileSiderRoot"
        style={{
          width: "100%",
          height: "50px",
          marginTop: 3,
          cursor: "pointer",
        }}
        justify="center"
        align="middle"
      >
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/app")}
        >
          <HomeOutlined />
          <br />
          <Typography.Text className="navigationText">Home</Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/referrals")}
        >
          <LikeOutlined />
          <br />
          <Typography.Text className="navigationText">
            Referrals
          </Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/customers")}
        >
          <TeamOutlined />
          <br />
          <Typography.Text className="navigationText">
            Customers
          </Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/employees")}
        >
          <ShopOutlined />
          <br />
          <Typography.Text className="navigationText">
            Employees
          </Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/campaigns")}
        >
          <FlagOutlined />
          <br />
          <Typography.Text className="navigationText">
            Campaigns
          </Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/metrics")}
        >
          <BarChartOutlined />
          <br />
          <Typography.Text className="navigationText">Metrics</Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => history.push("/billing")}
        >
          <CreditCardOutlined />
          <br />
          <Typography.Text className="navigationText">Billing</Typography.Text>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
        >
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
          >
            <div onClick={(e) => e.preventDefault()}>
              <UserOutlined />
              <br />
              <Typography.Text className="navigationText">
                Settings
              </Typography.Text>
            </div>
          </Dropdown>
        </Col>
        <Col
          flex="auto"
          className="navigationLink"
          onClick={() => handleLogout()}
        >
          <LogoutOutlined />
          <br />
          <Typography.Text className="navigationText">Logout</Typography.Text>
        </Col>
      </Row>
    </MobileSiderStyled>
  );
};
