import { EditOutlined, ShareAltOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { Company } from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import Toast from "../../../components/Toast";
import TypingText from "../../../components/TypingText";
import { useCompany } from "../../../providers/CompanyContext";
const { Text } = Typography;

const { Title } = Typography;
const { TextArea } = Input;

const ReferralShareMessageStyle = styled.div({
  "&.rewardSettingsRoot": {
    ".custom-description-item-value": {
      display: "inline-flex",
    },
    ".shaded-box": {
      backgroundColor: "#ffecd1" /* Adjust the background color as needed */,
      padding: "2px 4px",
      borderRadius: "8px",
    },
  },
});

const ReferralShareMessage: React.FC = () => {
  const { company, updateCompany } = useCompany();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedData, setEditedData] = useState<any>({});

  const onEditClick = (company: Company) => {
    setEditMode(true);
    setEditedData(company);
  };

  const getReferralSettings = () => {
    const result = {};

    if (
      editedData.messageBody !== undefined &&
      company.settings?.referral.customDiscountMessage.body !==
        editedData.messageBody
    ) {
      result["customDiscountMessage"] = {};
      result["customDiscountMessage"]["body"] = editedData.messageBody;
    }

    return result;
  };

  const onSaveClick = async () => {
    try {
      const referralSettings = getReferralSettings();
      const settings = {
        ...(Object.keys(referralSettings).length > 0 && {
          referral: referralSettings,
        }),
      };

      await updateCompany({
        settings,
      });
      setEditMode(false);
      setEditedData({});
      Toast("success", "Update success!");
    } catch (e) {
      Toast("error", "Error saving");
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setEditedData({});
  };

  const onInputChange = (field: "messageBody", value: string) => {
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const highlightKeywords = (text: string): string => {
    const highlightedText = (text || "").replace(/\$companyName/g, (match) => {
      return `<span class="shaded-box">${match}</span>`;
    });

    return `<span>${highlightedText}</span>`;
  };

  return (
    <ReferralShareMessageStyle className="rewardSettingsRoot">
      <FloatingCard
        title={
          <Title
            level={3}
            style={{ marginTop: 12 }}
          >
            <Row gutter={[10, 10]}>
              <Col>
                <Avatar icon={<ShareAltOutlined />} />
              </Col>
              <Col style={{ paddingTop: 7 }}>Referral Share Message</Col>
            </Row>
          </Title>
        }
        extra={
          <>
            {editMode ? (
              <Space direction="horizontal">
                <Button
                  type="primary"
                  onClick={onSaveClick}
                >
                  Save
                </Button>
                <Button onClick={onCancelClick}>Cancel</Button>
              </Space>
            ) : (
              <Button
                style={{
                  marginLeft: "20px",
                  alignItems: "center",
                  display: "flex",
                }}
                type="ghost"
                onClick={() => onEditClick(company)}
              >
                <EditOutlined style={{ fontSize: "20px" }} /> Edit
              </Button>
            )}
          </>
        }
      >
        <Row gutter={10}>
          <Col>
            <Descriptions
              layout="vertical"
              colon={false}
              size="small"
            >
              <Descriptions.Item
                style={{ textAlign: "left" }}
                label="Message"
              >
                {editMode ? (
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="Nothing yet"
                    value={
                      editedData.messageBody ??
                      company.settings?.referral.customDiscountMessage.body
                    }
                    onChange={(e) =>
                      onInputChange("messageBody", e.target.value)
                    }
                  />
                ) : (
                  <div
                    className="custom-description-item-value"
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywords(
                        company.settings?.referral.customDiscountMessage.body
                      ),
                    }}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider orientation="left">
          <Typography.Text type="secondary">
            Example Share Message
          </Typography.Text>
        </Divider>
        <FloatingCard>
          <Col>
            <Text style={{ fontWeight: 200, fontSize: "24px" }}>
              <Row justify="center">
                <Space direction="horizontal">
                  <Col>
                    <TypingText
                      text={`Hi! Thanks for using John's link!`}
                      duration={40}
                      style={{ fontWeight: 200, fontSize: "24px" }}
                    />
                  </Col>
                  <Col>
                    <div
                      style={{ fontWeight: 200, fontSize: "24px" }}
                      className="waving-hand"
                    >
                      👋
                    </div>
                  </Col>
                </Space>
              </Row>
            </Text>
            <Text style={{ fontWeight: 200, fontSize: "20px" }}>
              {company.settings?.referral.customDiscountMessage.body}
            </Text>
          </Col>
        </FloatingCard>
      </FloatingCard>
    </ReferralShareMessageStyle>
  );
};

export default ReferralShareMessage;
