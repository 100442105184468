import { CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { isEmailValid } from "lib/utils";
import React, { useEffect, useState } from "react";

const EmailInput: React.FC<{
  disabled?: boolean;
  onChange: (email: string) => unknown;
  placeholder?: string;
  setIsValid: (isValid: boolean) => unknown;
  style?: React.CSSProperties;
}> = ({
  disabled,
  onChange,
  placeholder = "Enter email",
  setIsValid,
  style,
}) => {
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    // this is for parent component use. This could be done in onChange, but this is more adaptable to future changes
    setIsValid(valid);
    onChange(email);
  }, [email, valid]);

  return (
    <Input
      disabled={disabled !== undefined ? disabled : false}
      size="large"
      value={email}
      placeholder={placeholder}
      onChange={(e) => {
        const email = e.target.value;
        // this is for component internal use
        setValid(isEmailValid(email));
        setEmail(email);
      }}
      style={{
        ...(valid && {
          borderColor: "#008000e3",
        }),
        background: "transparent",
        ...style,
      }}
      prefix={<UserOutlined style={{ ...(valid && { color: "#008000e3" }) }} />}
      suffix={
        valid ? (
          <CheckCircleOutlined style={{ color: "#008000e3" }} />
        ) : undefined
      }
    />
  );
};

export default EmailInput;
