import { gql } from "@apollo/client";

export const CORE_COMPANY_FIELDS = gql`
  fragment CoreCompanyFields on Company {
    id
    name
    domain
    phone
    isSubscriptionActive
    stats {
      clicksLast30Days
      messagesLast30Days
    }
    users {
      id
      firstName
      lastName
      email
      phone
    }
    zapierApiToken
    urlEncodedName
    settings {
      referral {
        logoUrl
        headerBackgroundColor
        bannerBackgroundColor
        defaultMessage
        discountAmount
        discountDescription
        discountType
        customMessage {
          greeting
          body
          banner
        }
        customDiscountMessage {
          body
        }
        customNewReferralMessage {
          greeting
          body
          enabled
        }
      }
      reward {
        defaultAmount
      }
    }
  }
`;

export const CORE_USER_FIELDS = gql`
  ${CORE_COMPANY_FIELDS}
  fragment CoreUserFields on User {
    id
    createdAt
    firstName
    lastName
    phone
    email
    permissions
  }
`;
