import Icon from "@ant-design/icons";

const CrossedCalendarOutlinedSVG = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    {/* Original Calendar Path */}
    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z" />

    {/* Added "X" Path */}
    <path
      d="M412 530L612 730M412 730L612 530"
      stroke="currentColor"
      strokeWidth="64"
      strokeLinecap="round"
    />
  </svg>
);

const CrossedCalendarOutlined = (props) => (
  <Icon
    component={CrossedCalendarOutlinedSVG}
    {...props}
  />
);

export default CrossedCalendarOutlined;
