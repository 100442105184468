import { Form, Select, Typography } from "antd";
import { PublicEmployee } from "layout/referralForm/ReferralForm";
import React, { useEffect, useState } from "react";

const { Text } = Typography;

const EmployeeInput: React.FC<{
  employeeOptions: PublicEmployee[];
  disabled?: boolean;
  setIsValid: (valid: Boolean) => unknown;
  fadeInOffset?: number;
  setValue: (value: string) => unknown;
}> = ({ employeeOptions, disabled, setIsValid, fadeInOffset, setValue }) => {
  const [employeeId, setEmployeeId] = useState("");

  useEffect(() => {
    setIsValid(
      employeeOptions.map((employee) => employee.id).includes(employeeId)
    );
    setValue(employeeId);
  }, [employeeId]);

  return (
    <>
      <Form.Item
        className={`appear-from-top-${fadeInOffset}`}
        name="employeeId"
        label={
          <Text
            style={{
              fontWeight: 200,
              fontSize: "20px",
            }}
          >
            Which Employee Assisted You
          </Text>
        }
      >
        <Select
          size="large"
          onChange={setEmployeeId}
          style={{ textAlign: "left" }}
        >
          {employeeOptions.map((employee) => (
            <Select.Option
              key={employee.id}
              value={employee.id}
            >
              {employee.firstName} {employee.lastName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default EmployeeInput;
