import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import SigninApi from "../api/signin";
import { REFERPRO_SIGNED_IN_USER, REFERPRO_TOKEN_NAME } from "../constants";

interface SignedInUser {
  id: string;
  firstName: string;
  lastName: string;
}

// Create the AuthenticationContext
interface AuthenticationContextType {
  signinWithAuth0Token: (token: string) => Promise<{ jwt: string | null }>;
  signedInUser: SignedInUser | null;
  isSignedIn: () => boolean;
}

const AuthenticationContext = createContext<AuthenticationContextType>({
  signinWithAuth0Token: async () => ({ jwt: null }),
  signedInUser: null,
  isSignedIn: () => false,
});

const setSignedInToken = (token: string) => {
  localStorage.setItem(REFERPRO_TOKEN_NAME, token);
};

// Define the AuthenticationProvider component
const AuthenticationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [jwt, setJwt] = useState<string | null>(null);
  const [signedInUser, setSignedInUser] = useState<SignedInUser | null>(null);

  useEffect(() => {
    const getAuthenticationDetailsFromLocalStorage = () => {
      if (jwt === null) {
        const token = localStorage.getItem(REFERPRO_TOKEN_NAME);
        if (token) {
          setJwt(token);
        }
      }

      if (signedInUser === null) {
        const user = localStorage.getItem(REFERPRO_SIGNED_IN_USER);
        if (user) {
          setSignedInUser(JSON.parse(user));
        }
      }
    };

    if (jwt !== null) {
      setSignedInToken(jwt);
    }
    if (signedInUser !== null) {
      localStorage.setItem(
        REFERPRO_SIGNED_IN_USER,
        JSON.stringify(signedInUser)
      );
    }

    getAuthenticationDetailsFromLocalStorage();
  }, [jwt, signedInUser]);

  const signinWithAuth0Token = async (auth0Token: string) => {
    if (jwt) {
      return jwt;
    }

    const { user, accessToken } = await SigninApi.auth0SignIn(auth0Token);
    if (!user) {
      // Maybe do something
    } else {
      setSignedInUser(user);
    }

    if (!accessToken) {
      throw new Error("Failed to sign in");
    }

    setJwt(accessToken);
    return accessToken;
  };

  return (
    <AuthenticationContext.Provider
      value={{
        signinWithAuth0Token,
        signedInUser,
        isSignedIn: () => !!localStorage.getItem(REFERPRO_TOKEN_NAME),
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

// Custom hook to use the AuthenticationContext
export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within an AuthenticationProvider"
    );
  }
  return context;
};

export { AuthenticationContext, AuthenticationProvider };
