import { CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { isNameValid } from "lib/utils";
import React, { useEffect, useState } from "react";

const NameInput: React.FC<{
  disabled?: boolean;
  onChange: (name: string) => unknown;
  placeholder?: string;
  setIsValid: (isValid: boolean) => unknown;
  style?: React.CSSProperties;
}> = ({
  disabled,
  onChange,
  placeholder = "Enter name",
  setIsValid,
  style,
}) => {
  const [name, setName] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    // this is for parent component use. This could be done in onChange, but this is more adaptable to future changes
    setIsValid(valid);
    onChange(name);
  }, [name, valid]);

  return (
    <Input
      disabled={disabled !== undefined ? disabled : false}
      size="large"
      value={name}
      placeholder={placeholder}
      onChange={(e) => {
        const name = e.target.value;
        // this is for component internal use
        setValid(isNameValid(name));
        setName(name);
      }}
      style={{
        ...(valid && {
          borderColor: "#008000e3",
        }),
        background: "transparent",
        ...style,
      }}
      prefix={<UserOutlined style={{ ...(valid && { color: "#008000e3" }) }} />}
      suffix={
        valid ? (
          <CheckCircleOutlined style={{ color: "#008000e3" }} />
        ) : undefined
      }
    />
  );
};

export default NameInput;
