import { EditOutlined, LikeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Alert,
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { Company } from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import Toast from "../../../components/Toast";
import { useCompany } from "../../../providers/CompanyContext";
import TextBubble from "./TextBubble";

const { Paragraph, Title } = Typography;
const { TextArea } = Input;

const ReferralRequestMessageStyle = styled.div({
  "&.rewardSettingsRoot": {
    ".custom-description-item-value": {
      display: "inline-flex",
    },
    ".shaded-box": {
      backgroundColor: "#ffecd1" /* Adjust the background color as needed */,
      padding: "2px 4px",
      borderRadius: "8px",
    },
  },
});

const renderTextMessage = (company: Company) => {
  // Replace all the \n
  const bodyParts = company.settings.referral.customMessage.body
    .replace(/\$link/g, "LINK GOES HERE")
    .split("\n")
    .map((part, index) => (
      <Paragraph
        key={index}
        style={{ color: "white", marginBottom: 0 }}
      >
        {part}
      </Paragraph>
    ));

  const message = (
    <>
      <Paragraph style={{ color: "white", marginBottom: 0 }}>
        {(company.settings.referral.customMessage.greeting || "")
          .replace(/\$firstName/g, "John")
          .replace(/\$companyName/g, company.name)}
      </Paragraph>
      <Paragraph />
      {bodyParts}
      <Paragraph />
      <Paragraph style={{ color: "white", marginBottom: 0 }}>
        {company.settings.referral.customMessage.body.includes("$link") ||
          "LINK GOES HERE"}
      </Paragraph>
      <Paragraph style={{ color: "white", marginBottom: 0 }}>
        Reply STOP to opt out
      </Paragraph>
    </>
  );

  return (
    <div
      style={{ background: "rgb(255, 255, 255)", padding: 10, borderRadius: 5 }}
    >
      <Row>
        <TextBubble
          message={
            <Paragraph style={{ color: "black", marginBottom: 0 }}>
              This is what a text will look like from ReferPro ...
            </Paragraph>
          }
          color="gray"
        />
      </Row>
      <Row>
        <Col offset={4}>
          <TextBubble
            message={message}
            color="blue"
          />
        </Col>
      </Row>
    </div>
  );
};

const ReferralRequestMessage: React.FC = () => {
  const { company, updateCompany } = useCompany();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedData, setEditedData] = useState<any>({});

  const onEditClick = (company: Company) => {
    setEditMode(true);
    setEditedData(company);
  };

  const getReferralSettings = () => {
    const result = {};

    if (
      editedData.messageBody !== undefined &&
      company.settings?.referral.customMessage.body !== editedData.messageBody
    ) {
      result["customMessage"] = {};
      result["customMessage"]["body"] = editedData.messageBody;
    }

    if (
      editedData.messageGreeting !== undefined &&
      company.settings?.referral.customMessage.greeting !==
        editedData.messageGreeting
    ) {
      if (result["customMessage"] === undefined) {
        result["customMessage"] = {};
      }
      result["customMessage"]["greeting"] = editedData.messageGreeting;
    }

    if (
      editedData.banner !== undefined &&
      company.settings?.referral.customMessage.banner !== editedData.banner
    ) {
      if (result["customMessage"] === undefined) {
        result["customMessage"] = {};
      }
      result["customMessage"]["banner"] = editedData.banner;
    }

    return result;
  };

  const onSaveClick = async () => {
    try {
      const referralSettings = getReferralSettings();

      const settings = {
        ...(Object.keys(referralSettings).length > 0 && {
          referral: referralSettings,
        }),
      };

      await updateCompany({
        settings,
      });
      setEditMode(false);
      setEditedData({});
      Toast("success", "Referral request message updated!");
    } catch (e) {
      Toast("error", "Error saving referral request message");
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setEditedData({});
  };

  const onInputChange = (
    field: "messageBody" | "messageGreeting" | "banner",
    value: string
  ) => {
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const highlightKeywords = (text: string): string => {
    const highlightedText = (text || "").replace(
      /\$firstName|\$link|\$companyName/g,
      (match) => {
        return `<span class="shaded-box">${match}</span>`;
      }
    );

    return `<span>${highlightedText}</span>`;
  };

  return (
    <ReferralRequestMessageStyle className="rewardSettingsRoot">
      <FloatingCard
        title={
          <Title
            level={3}
            style={{ marginTop: 12 }}
          >
            <Row gutter={[10, 10]}>
              <Col>
                <Avatar icon={<LikeOutlined />} />
              </Col>
              <Col style={{ paddingTop: 7 }}>Referral Request</Col>
            </Row>
          </Title>
        }
        extra={
          <>
            {editMode ? (
              <Space direction="horizontal">
                <Button
                  type="primary"
                  onClick={onSaveClick}
                >
                  Save
                </Button>
                <Button onClick={onCancelClick}>Cancel</Button>
              </Space>
            ) : (
              <Button
                style={{
                  marginLeft: "20px",
                  alignItems: "center",
                  display: "flex",
                }}
                type="ghost"
                onClick={() => onEditClick(company)}
              >
                <EditOutlined style={{ fontSize: "20px" }} /> Edit
              </Button>
            )}
          </>
        }
      >
        <Row gutter={10}>
          <Col>
            <Descriptions
              layout="vertical"
              colon={false}
              size="small"
            >
              <Descriptions.Item
                style={{ textAlign: "left" }}
                label="Greeting"
              >
                {editMode ? (
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="Nothing yet"
                    value={
                      editedData.messageGreeting ??
                      company.settings?.referral.customMessage.greeting
                    }
                    onChange={(e) =>
                      onInputChange("messageGreeting", e.target.value)
                    }
                  />
                ) : (
                  <div
                    className="custom-description-item-value"
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywords(
                        company.settings?.referral.customMessage.greeting
                      ),
                    }}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              layout="vertical"
              colon={false}
              size="small"
            >
              <Descriptions.Item
                style={{ textAlign: "left" }}
                label="Body"
              >
                {editMode ? (
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="Nothing yet"
                    value={
                      editedData.messageBody ??
                      company.settings?.referral.customMessage.body
                    }
                    onChange={(e) =>
                      onInputChange("messageBody", e.target.value)
                    }
                  />
                ) : (
                  <div
                    className="custom-description-item-value"
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywords(
                        company.settings?.referral.customMessage.body
                      ),
                    }}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider orientation="left">
          <Typography.Text type="secondary">
            Example Text Message
          </Typography.Text>
        </Divider>
        <Row gutter={10}>
          <Col>
            <Typography style={{ textAlign: "left" }}>
              {renderTextMessage(company)}
            </Typography>
          </Col>
        </Row>
        <Row style={{ height: 25 }}></Row>
        <Divider />
        <Row gutter={10}>
          <Col>
            <Descriptions
              layout="vertical"
              colon={false}
              size="small"
            >
              <Descriptions.Item
                style={{ textAlign: "left" }}
                label="Banner shown when entering a referral"
              >
                {editMode ? (
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="Nothing yet"
                    value={
                      editedData.banner ??
                      company.settings?.referral.customMessage.banner
                    }
                    onChange={(e) => onInputChange("banner", e.target.value)}
                  />
                ) : (
                  <div
                    className="custom-description-item-value"
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywords(
                        company.settings?.referral.customMessage.banner
                      ),
                    }}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider>Example Banner</Divider>
        <Row gutter={10}>
          <Col flex="auto">
            <Alert
              style={{
                fontSize: 16,
                fontWeight: 200,
                width: "100%",
                textAlign: "left",
              }}
              message={
                <Typography.Text>
                  <strong>
                    {company.settings?.referral.customMessage.banner}
                  </strong>
                </Typography.Text>
              }
              showIcon
              icon={"💸"}
              closable={false}
              banner
            />
          </Col>
        </Row>
      </FloatingCard>
    </ReferralRequestMessageStyle>
  );
};

export default ReferralRequestMessage;
