import { gql } from "@apollo/client";
import { CORE_COMPANY_FIELDS } from "./fragments";

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  ${CORE_COMPANY_FIELDS}
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...CoreCompanyFields
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        phone
        createdAt
      }
    }
  }
`;

export const DEACTIVATE_CAMPAIGN = gql`
  mutation DeactivateCampaign($input: DeactivateCampaignInput!) {
    deactivateCampaign(input: $input) {
      campaign {
        id
      }
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      success
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation DeleteEmployee($input: DeleteEmployeeInput!) {
    deleteEmployee(input: $input) {
      success
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      employee {
        id
      }
    }
  }
`;

export const SEND_REFERRAL_LINK = gql`
  mutation SendReferralLink($input: SendReferralLinkInput!) {
    sendReferralLink(input: $input) {
      success
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      campaign {
        id
        createdAt
      }
    }
  }
`;
