import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/named
import YouTube, { YouTubeProps } from "react-youtube";

interface Props {
  videoId: string;
}

const CompanyVideo: React.FC<Props> = ({ videoId }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: isMobile ? "200" : "300",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0, // this is the default value
    },
  };
  if (videoId) {
    return (
      <Row justify="center">
        <Col span={24}>
          <YouTube
            videoId={videoId}
            opts={opts}
            onReady={onPlayerReady}
          />
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default CompanyVideo;
