import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LinkOutlined,
  MailOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Input,
  Popconfirm,
  Row,
  Space,
  Timeline,
  Typography,
} from "antd";
import ChooseCampaignGroups from "components/ChooseCampaignGroup";
import React, { useEffect, useState } from "react";
import {
  CampaignGroup,
  Customer,
  DeliveryMethod,
  ReferralLink,
} from "../../../__generated__/graphql";
import Toast from "../../../components/Toast";
import {
  DELETE_CUSTOMER,
  SEND_REFERRAL_LINK,
  UPDATE_CUSTOMER,
} from "../../../data/mutations";
import { LIST_CUSTOMERS_PAGINATED } from "../../../data/queries";
import { formatDateTime } from "../../../lib/date";

const { Text } = Typography;

const ExpandedCustomerRow: React.FC<{ customer: Customer }> = ({
  customer,
}) => {
  const [isSendingText, setIsSendingText] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [customerReferralLinks, setCustomerReferralLinks] = useState<
    ReferralLink[]
  >([]);

  useEffect(() => {
    if (customer) {
      const uniqueTargets = {}; // acts as hash map
      const filteredReferralLinks = customer.referralLinks.filter((link) => {
        // use the target value as the key in the object
        // if key already exists, return false
        // if key does not exist. Create new key-value pair
        if (!uniqueTargets[link.target]) {
          uniqueTargets[link.target] = true;
          return true;
        }
        return false;
      });
      setCustomerReferralLinks(filteredReferralLinks);
    }
  }, [customer]);

  const renderDescriptionsItem = (
    label: string,
    value: string,
    field: keyof Customer
  ) => {
    if (editMode) {
      return (
        <Descriptions.Item label={label}>
          <Input
            value={editedData[field] ?? value}
            onChange={(e) => onInputChange(field, e.target.value)}
          />
        </Descriptions.Item>
      );
    } else {
      return <Descriptions.Item label={label}>{value}</Descriptions.Item>;
    }
  };
  const renderCampaignGroups = (campaignGroups: CampaignGroup[]) => {
    if (editMode) {
      return (
        <Descriptions.Item label="campaignGroupIds">
          <ChooseCampaignGroups
            initialGroupIds={campaignGroups.map((group) => group.id)}
            onChoose={(groupIds) => {
              setEditedData((prevData) => ({
                ...prevData,
                campaignGroupIds: groupIds,
              }));
            }}
          />
        </Descriptions.Item>
      );
    } else {
      return (
        <Descriptions
          title="Groups"
          layout="vertical"
          colon={false}
          column={{ xs: 1, lg: 1 }}
        >
          {campaignGroups && campaignGroups.length > 0 ? (
            campaignGroups.map((group) => (
              <Descriptions.Item label={group.name}>
                {group.description ? (
                  <Text key={group.id}>{group.description}</Text>
                ) : null}
              </Descriptions.Item>
            ))
          ) : (
            <Descriptions.Item
              label="No groups assigned"
              children={null}
            />
          )}
        </Descriptions>
      );
    }
  };
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState<any>({});

  const onInputChange = (field: keyof Customer, value: string) => {
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    refetchQueries: [
      { query: LIST_CUSTOMERS_PAGINATED, variables: { first: 1000 } },
      "ListCustomersPaginatedQuery",
    ],
  });

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    refetchQueries: [
      {
        query: LIST_CUSTOMERS_PAGINATED,
        variables: { first: 1000 },
      },
      "ListCustomersPaginatedQuery",
    ],
  });

  const [sendReferralLink] = useMutation(SEND_REFERRAL_LINK, {
    refetchQueries: [
      { query: LIST_CUSTOMERS_PAGINATED, variables: { first: 1000 } },
      "ListCustomersPaginatedQuery",
    ],
  });

  const onEditClick = () => {
    setEditMode(true);
    setEditedData(customer);
  };

  const onCancelClick = () => {
    setEditMode(false);
    setEditedData({});
  };

  const onSaveClick = async () => {
    try {
      await updateCustomer({
        variables: {
          input: {
            customerId: customer.id,
            updateAttributes: {
              ...(customer.firstName !== editedData.firstName && {
                firstName: editedData.firstName,
              }),
              ...(customer.lastName !== editedData.lastName && {
                lastName: editedData.lastName,
              }),
              ...(customer.email !== editedData.email && {
                email: editedData.email,
              }),
              ...(customer.phone !== editedData.phone && {
                phone: editedData.phone,
              }),
              ...(editedData.campaignGroupIds && {
                campaignGroupIds: editedData.campaignGroupIds,
              }),
            },
          },
        },
      });
      setEditMode(false);
      setEditedData({});
      Toast("success", "Update customer success!");
    } catch (e) {
      Toast("error", "Error saving customer");
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={24}>
          <Descriptions
            title={"Customer Info"}
            layout="vertical"
            colon={false}
            size="small"
            extra={
              <>
                {editMode ? (
                  <Space direction="horizontal">
                    <Button
                      type="primary"
                      onClick={onSaveClick}
                    >
                      Save
                    </Button>
                    <Button onClick={onCancelClick}>Cancel</Button>
                  </Space>
                ) : (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <>
                              <EditOutlined /> Edit
                            </>
                          ),
                          onClick: onEditClick,
                        },
                        {
                          key: "2",
                          label: (
                            <>
                              <Popconfirm
                                title={`Deleting ${customer.firstName} ${customer.lastName}`}
                                description="Are you sure?"
                                onConfirm={() =>
                                  deleteCustomer({
                                    variables: {
                                      input: {
                                        customerId: customer.id,
                                      },
                                    },
                                  })
                                }
                                okText="Yes"
                                cancelText="Cancel"
                              >
                                <DeleteOutlined /> Delete
                              </Popconfirm>
                            </>
                          ),
                        },
                      ],
                    }}
                    placement="bottomRight"
                  >
                    <Button type="link">
                      <EllipsisOutlined style={{ fontSize: "20px" }} />
                    </Button>
                  </Dropdown>
                )}
              </>
            }
          >
            {renderDescriptionsItem(
              "First Name",
              customer.firstName,
              "firstName"
            )}
            {renderDescriptionsItem("Last Name", customer.lastName, "lastName")}
            {renderDescriptionsItem("Email", customer.email, "email")}
            {renderDescriptionsItem("Phone", customer.phone, "phone")}
          </Descriptions>
          {renderCampaignGroups(customer.campaignGroups)}
        </Col>
      </Row>
      <Divider />
      <Row justify="start">
        <Col span={24}>
          {customerReferralLinks.length > 0 &&
            customerReferralLinks.map((item, i) => {
              return (
                <Row
                  justify="start"
                  key={i}
                >
                  <Col span={24}>
                    <Card>
                      <Space
                        direction="vertical"
                        size="large"
                      >
                        <Row>
                          <a href={item.target}>{item.target}</a>
                        </Row>
                        <Row>
                          <Typography.Text>{`Clicks: ${item.clicks}`}</Typography.Text>
                        </Row>
                        <Row>
                          <Timeline
                            items={[
                              {
                                children: (
                                  <Typography.Text>
                                    Created{" - "}
                                    {formatDateTime(item.createdAt, false)}
                                  </Typography.Text>
                                ),
                                dot: <LinkOutlined />,
                              },
                              ...item.deliveries.map((delivery) => ({
                                color: "green",
                                dot:
                                  delivery.method === DeliveryMethod.Email ? (
                                    <MailOutlined />
                                  ) : (
                                    <MessageOutlined />
                                  ),
                                children: (
                                  <Typography.Text>
                                    Sent{" - "}
                                    {formatDateTime(delivery.sentAt, false)}
                                  </Typography.Text>
                                ),
                              })),
                              ...(item.deliveries.length === 0
                                ? [
                                    {
                                      color: "gray",
                                      children: "Not yet sent",
                                    },
                                  ]
                                : []),
                            ]}
                          />
                        </Row>
                        <Row>
                          <Col
                            span={22}
                            offset={1}
                          >
                            <Button
                              type="default"
                              size="large"
                              style={{ maxWidth: 250, width: "100%" }}
                              loading={isSendingText}
                              onClick={async () => {
                                try {
                                  setIsSendingText(true);
                                  await sendReferralLink({
                                    variables: {
                                      input: {
                                        referralLinkId: item.id,
                                        deliveryMethods: [DeliveryMethod.Text],
                                      },
                                    },
                                  });
                                  Toast("success", "Text sent!");
                                } catch (e) {
                                  Toast("error", "Error sending text.");
                                } finally {
                                  setIsSendingText(false);
                                }
                              }}
                            >
                              <MessageOutlined /> Send via Text
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={22}
                            offset={1}
                          >
                            <Button
                              type="default"
                              size="large"
                              style={{ maxWidth: 250, width: "100%" }}
                              loading={isSendingEmail}
                              onClick={async () => {
                                try {
                                  setIsSendingEmail(true);
                                  await sendReferralLink({
                                    variables: {
                                      input: {
                                        referralLinkId: item.id,
                                        deliveryMethods: [DeliveryMethod.Email],
                                      },
                                    },
                                  });
                                  Toast("success", "Email sent!");
                                } catch (e) {
                                  Toast("error", "Error sending email.");
                                } finally {
                                  setIsSendingEmail(false);
                                }
                              }}
                            >
                              <MailOutlined />
                              Send via Email
                            </Button>
                          </Col>
                        </Row>
                      </Space>
                    </Card>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    </>
  );
};

export default ExpandedCustomerRow;
