import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import weekday from "dayjs/plugin/weekday";
import React from "react";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const dateFormat = "MM-DD-YYYY";

const CampaignDatePicker: React.FC<{
  onDateChange: (unixTimestamp: number) => void;
}> = ({ onDateChange }) => {
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    date = date.set("hour", 0);
    date = date.set("minute", 0);
    date = date.set("seconds", 0);
    onDateChange(date.unix());
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };

  return (
    <DatePicker
      size="large"
      presets={[
        { label: "Today", value: dayjs() },
        { label: "Tomorrow", value: dayjs().add(+1, "d") },
      ]}
      onChange={onChange}
      format={dateFormat}
      disabledDate={disabledDate}
      placeholder="Campaign Start Date"
      style={{ width: "100%" }}
    />
  );
};

export default CampaignDatePicker;
