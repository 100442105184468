import { LoadingOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ReferralType } from "__generated__/graphql";
import { Layout, Result, Spin } from "antd";
import Toast from "components/Toast";
import { CompanyBranding } from "layout/referralForm/ReferralForm";
import { getAlternateCompanyName } from "lib/utils";
import React from "react";
import {
  GetLinkQuery,
  GetLinkQueryVariables,
  LinkType,
} from "../../__generated__/public/graphql";
import CompanyReferralForm from "./components/CompanyReferralForm";
import CustomerReferralForm from "./components/CustomerReferralForm";
import EmployeeReferralForm from "./components/EmployeeReferralForm";
import GeneralEmployeeReferralForm from "./components/GeneralEmployeeReferralForm";
import ShareReferralForm from "./components/ShareReferralForm";

export const GET_LINK = gql`
  # if you want to query more data, you will need to add it to the cache on the backend
  query GetLink($target: String!) {
    getLink(target: $target) {
      id
      company {
        id
        name
        settings {
          referral {
            logoUrl
            headerBackgroundColor
            bannerBackgroundColor
            youtubeVideoId
            discountAmount
            discountDescription
            discountType
            customMessage {
              banner
            }
            customDiscountMessage {
              body
            }
          }
          reward {
            defaultAmount
          }
        }
        employeeList {
          id
          firstName
          lastName
        }
      }
      customer {
        firstName
      }
      employee {
        firstName
      }
      type
    }
  }
`;
const CREATE_REFERRAL = gql`
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input)
  }
`;

const CREATE_COMPANY_REFERRAL = gql`
  mutation CreateCompanyReferral($input: CreateCompanyReferralInput!) {
    createCompanyReferral(input: $input) {
      error
      referralLink {
        id
        target
        customer {
          id
          firstName
        }
      }
    }
  }
`;

const CREATE_EMPLOYEE_REFERRAL = gql`
  mutation CreateEmployeeReferral($input: CreateEmployeeReferralInput!) {
    createEmployeeReferral(input: $input)
  }
`;

const CREATE_GENERAL_EMPLOYEE_REFERRAL = gql`
  mutation CreateGeneralEmployeeReferral(
    $input: CreateGeneralEmployeeReferralInput!
  ) {
    createGeneralEmployeeReferral(input: $input) {
      error
    }
  }
`;

interface Props {
  target: string;
}

const CollectReferralData: React.FC<Props> = ({ target }) => {
  let companyBranding: CompanyBranding = {
    logoUrl: "",
    headerBackgroundColor: "",
    bannerBackgroundColor: "",
    youtubeVideoId: "",
  };

  // This hack ensures that we are using the production URL
  target = target.replace("http://localhost:3000", "https://referpro.co");
  const isEmbedded = /\/r\/.+\/embed$/i.test(target);
  const { loading, data, error } = useQuery<
    GetLinkQuery,
    GetLinkQueryVariables
  >(GET_LINK, { variables: { target } });
  const [createReferral, createReferralResult] = useMutation(CREATE_REFERRAL);
  const [createCompanyReferral, createCompanyReferralResult] = useMutation(
    CREATE_COMPANY_REFERRAL
  );
  const [createEmployeeReferral, createEmployeeReferralResult] = useMutation(
    CREATE_EMPLOYEE_REFERRAL
  );
  const [createGeneralEmployeeReferral, createGeneralEmployeeReferralResult] =
    useMutation(CREATE_GENERAL_EMPLOYEE_REFERRAL);
  companyBranding.headerBackgroundColor =
    data?.getLink.company.settings.referral.headerBackgroundColor;
  companyBranding.logoUrl = data?.getLink.company.settings.referral.logoUrl;
  companyBranding.bannerBackgroundColor =
    data?.getLink.company.settings.referral.bannerBackgroundColor;
  companyBranding.youtubeVideoId =
    data?.getLink.company.settings.referral.youtubeVideoId;
  companyBranding.alternateCompanyName = getAlternateCompanyName(
    data?.getLink.company.id
  );
  const saveCustomerReferral = async (
    name: string,
    phone: string,
    referralType: ReferralType = ReferralType.Standard
  ) => {
    try {
      const [firstName, lastName] = name.split(" ");
      await createReferral({
        variables: {
          input: {
            referralLinkId: data.getLink.id,
            firstName,
            lastName,
            phone,
            type: referralType,
          },
        },
      });
      switch (referralType) {
        case ReferralType.Standard:
          Toast("success", `Success saving referral for ${name}`);
          break;
        case ReferralType.Self:
          Toast("success", `Success saving referral`);
          break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //redirect to thank you page
      window.location.href = `${window.location.href}/thank-you`;
      return true;
    } catch (error) {
      Toast("error", "Error saving referral");
      return false;
    }
  };

  const saveSharableReferral = async (
    name: string,
    phone: string,
    referralType: ReferralType = ReferralType.Standard
  ) => {
    try {
      const [firstName, lastName] = name.split(" ");
      await createReferral({
        variables: {
          input: {
            referralLinkId: data.getLink.id,
            firstName,
            lastName,
            phone,
            type: referralType,
          },
        },
      });
      Toast("success", `Success saving referral`);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //redirect to thank you page
      window.location.href = `${window.location.href}/thank-you`;
      return true;
    } catch (error) {
      Toast("error", "Error saving referral");
      return false;
    }
  };

  const saveCompanyReferral = async (
    referredByName: string,
    referredByPhone: string,
    referredByEmail: string,
    referralName: string,
    referralPhone: string,
    referralType: ReferralType = ReferralType.Standard
  ) => {
    try {
      const [referredByFirstName, referredByLastName] =
        referredByName.split(" ");
      const [referralFirstName, referralLastName] = referralName.split(" ");
      const response = await createCompanyReferral({
        variables: {
          input: {
            companyId: data.getLink.company.id,
            referredByFirstName,
            referredByLastName,
            referredByPhone,
            referredByEmail,
            referralFirstName,
            referralLastName,
            referralPhone,
            type: referralType,
          },
        },
      });
      switch (referralType) {
        case ReferralType.Standard:
          Toast("success", `Success saving referral for ${referralName}`);
          break;
        case ReferralType.Self:
          Toast("success", `Success saving referral`);
          break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //redirect to thank you page
      window.location.href = `${response.data.createCompanyReferral.referralLink.target}/thank-you`;
      return true;
    } catch (error) {
      Toast("error", "Error saving referral");
      return false;
    }
  };

  const saveEmployeeReferral = async (
    referredByName: string,
    referredByPhone: string,
    referredByEmail: string,
    referralName: string,
    referralPhone: string,
    referralType: ReferralType = ReferralType.Standard
  ) => {
    try {
      const [referredByFirstName, referredByLastName] =
        referredByName.split(" ");
      const [referralFirstName, referralLastName] = referralName.split(" ");
      await createEmployeeReferral({
        variables: {
          input: {
            referralLinkId: data.getLink.id,
            referredByFirstName,
            referredByLastName,
            referredByPhone,
            referredByEmail,
            referralFirstName,
            referralLastName,
            referralPhone,
            type: referralType,
          },
        },
      });
      switch (referralType) {
        case ReferralType.Standard:
          Toast("success", `Success saving referral for ${referralName}`);
          break;
        case ReferralType.Self:
          Toast("success", `Success saving referral`);
          break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //redirect to thank you page
      window.location.href = `${window.location.href}/thank-you`;
      return true;
    } catch (error) {
      Toast("error", "Error saving referral");
      return false;
    }
  };

  const saveGeneralEmployeeReferral = async (
    referredByName: string,
    referredByPhone: string,
    referredByEmail: string,
    referralName: string,
    referralPhone: string,
    employeeId: string,
    referralType: ReferralType = ReferralType.Standard
  ) => {
    try {
      const [referredByFirstName, referredByLastName] =
        referredByName.split(" ");
      const [referralFirstName, referralLastName] = referralName.split(" ");
      await createGeneralEmployeeReferral({
        variables: {
          input: {
            referralLinkId: data.getLink.id,
            referredByFirstName,
            referredByLastName,
            referredByPhone,
            referredByEmail,
            referralFirstName,
            referralLastName,
            referralPhone,
            selectedEmployeeId: employeeId,
            type: referralType,
          },
        },
      });
      switch (referralType) {
        case ReferralType.Standard:
          Toast("success", `Success saving referral for ${referralName}`);
          break;
        case ReferralType.Self:
          Toast("success", `Success saving referral`);
          break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //redirect to thank you page
      window.location.href = `${window.location.href}/thank-you`;
      return true;
    } catch (error) {
      Toast("error", "Error saving referral");
      return false;
    }
  };

  const ReferralErrorDisplay = () => (
    <Result
      style={{ marginTop: 100 }}
      status="500"
      title="This company has disabled their referral program"
      subTitle="Reach out to them directly to submit a referral"
    />
  );

  const renderContent = () => {
    if (error) {
      return <ReferralErrorDisplay />;
    }

    if (loading) {
      return (
        <Result
          style={{ marginTop: 100 }}
          icon={
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24 }}
                  spin
                />
              }
              size="large"
            />
          }
          title="One moment . . ."
        />
      );
    }

    if (!data?.getLink) {
      // This is a different case than the one above, and we should report is as such ...
      return <ReferralErrorDisplay />;
    }

    let referralType = data.getLink.type;
    // just in case the link is not set up correctly
    if (
      (data.getLink.type === LinkType.Shareable ||
        data.getLink.type == LinkType.Self) &&
      !data.getLink.customer
    ) {
      referralType = LinkType.Company;
    }

    switch (referralType) {
      case LinkType.Shareable:
        return (
          <ShareReferralForm // this is for people who are getting referred
            companyId={data.getLink.company.id}
            firstName={data.getLink.customer?.firstName}
            companyName={data.getLink.company.name}
            saveCustomerReferral={saveSharableReferral}
            discountAmount={
              data.getLink.company.settings.referral.discountAmount
            }
            discountDescription={
              data.getLink.company.settings.referral.discountDescription
            }
            discountType={data.getLink.company.settings.referral.discountType}
            customDiscountMessage={
              data.getLink.company.settings.referral.customDiscountMessage.body
            }
            companyBranding={companyBranding}
          />
        );
      case LinkType.Self:
        return (
          <CustomerReferralForm // this is for people to share with others
            companyId={data.getLink.company.id}
            firstName={data.getLink.customer.firstName}
            companyName={data.getLink.company.name}
            rewardAmount={data.getLink.company.settings.reward.defaultAmount}
            saveCustomerReferral={saveCustomerReferral}
            referralLinkId={data.getLink.id}
            referralBannerMessage={
              data.getLink.company.settings.referral.customMessage.banner
            }
            companyBranding={companyBranding}
          />
        );
      case LinkType.Company:
        return (
          <CompanyReferralForm // this is for companies to refer others
            companyId={data.getLink.company.id}
            companyName={data.getLink.company.name}
            saveCompanyReferral={saveCompanyReferral}
            referralBannerMessage={
              data.getLink.company.settings.referral.customMessage.banner
            }
            isEmbedded={isEmbedded}
            companyBranding={companyBranding}
          />
        );
      case LinkType.Employee:
        return (
          <EmployeeReferralForm
            companyId={data.getLink.company.id}
            companyName={data.getLink.company.name}
            employeeName={data.getLink.employee?.firstName}
            saveEmployeeReferral={saveEmployeeReferral}
            referralBannerMessage={
              data.getLink.company.settings.referral.customMessage.banner
            }
            companyBranding={companyBranding}
          />
        );
      case LinkType.GeneralEmployee:
        return (
          <GeneralEmployeeReferralForm
            companyId={data.getLink.company.id}
            companyName={data.getLink.company.name}
            saveGeneralEmployeeReferral={saveGeneralEmployeeReferral}
            referralBannerMessage={
              data.getLink.company.settings.referral.customMessage.banner
            }
            companyBranding={companyBranding}
            employeeOptions={data.getLink.company.employeeList}
          />
        );
      default:
        return <ReferralErrorDisplay />;
    }
  };

  return <Layout style={{ minHeight: "100vh" }}>{renderContent()}</Layout>;
};

export default CollectReferralData;
