import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

const { Text } = Typography;

const TypingText: React.FC<{
  text: string;
  duration: number;
  style?: React.CSSProperties;
}> = ({ text, duration, style }) => {
  const [typedText, setTypedText] = useState("");
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    let timer;
    if (showText) {
      timer = setTimeout(() => {
        const currentLength = typedText.length;
        if (currentLength < text.length) {
          const nextChar = text.charAt(currentLength);
          setTypedText((prevText) => prevText + nextChar);
        }
      }, duration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [typedText, text, duration, showText]);

  useEffect(() => {
    setShowText(true);
  }, []);

  return (
    <CSSTransition
      in={showText}
      timeout={duration}
      classNames="fade"
      unmountOnExit
    >
      <Text style={style}>{typedText}</Text>
    </CSSTransition>
  );
};

export default TypingText;
