export const REFERPRO_TOKEN_NAME = "referpro_token";
export const REFERPRO_USER_ID = "referpro_user_id";
export const BASE_URL = "https://dev-api.referpro.co";
// export const BASE_URL =
//   "https://0mr0is5zf0.execute-api.us-east-1.amazonaws.com/";
export const REFERPRO_SIGNED_IN_USER = "referpro_signed_in_user_details";
export const REFERPRO_PERMISSIONS = "referpro_permissions";

export const RIGHT_BEFORE_EPOCH = "1969-12-31T23:59:59.999Z";

export const COMPANY_IDS = {
  DAZZLE: "4511ba88-a26a-4714-9b2d-21329e0cbbbb",
  PERFORMANCE_CARPET: "6bc51916-5e88-42aa-9ab1-8f521c3fbb4a",
  HOMETOWN_HEROES_HVAC: "28d193c2-c66d-4ebb-b82f-47fa5955d9e3",
  JUNK_OUT_ABQ: "c70fe408-2593-4dbd-8ac9-98b5c313893a",
  FIND_HOW_YOU_FLOURISH: "55356f76-6da2-404d-a88f-31c9c1479789",
  LGCY: "0e9832e1-e7b4-4e5a-884e-6f579b186360",
  SOFTWATER_SPECIALISTS: "e8233868-4348-4811-8cc3-60f9c715168f",
  SOLAR_EXPRESS: "d38a3754-8825-4cad-bdfd-147c30fa69e7",
  PRINCE_GEORGE_COUNTY_KUIK_DRY: "a1ab07e9-e8ba-4124-ba19-091ef78090c1",
  SKIN_SPA: "b195cfb5-4583-4c4b-99b4-ea205767e116",
  BLUE_DIAMOND_DETAILING: "fcf9b002-ace5-4235-9eba-41c6b1d1b40f",
  TIMOTHYS_COOLEST_COMPANY: "65f5fa05-e868-4bed-9a99-5145d573b2fb",
  BARE_NECESSITY: {
    SCOTTSDALE: "65f5fa05-e868-4bed-9a99-5145d573b2fb",
    TUSCON: "3f4bab05-96a6-40be-a3a9-8fd8aa594b9a",
    GILBERT: "ccbfa6d7-8ac1-498f-81fe-8d7c56940c23",
  },
  DOOR_SERV_PRO: {
    MARYLAND: "3ae4bf70-ba11-4a1c-869c-c7539260b165",
    VIRGINIA: "efbb5e9b-4d4a-4e2f-9cf3-29c746c43060",
    PENNSYLVANIA: "5a28229b-9b6d-4437-bb92-444fec2d5d3a",
    WEST_VIRGINIA: "1a12df5c-35f4-48b5-b000-c37ccf889c3d",
  },
};

export const YOUTUBE_VIDEOS = {
  [COMPANY_IDS.PERFORMANCE_CARPET]: "KOWnND0S9Iw",
  [COMPANY_IDS.HOMETOWN_HEROES_HVAC]: "L50WC6qHN5k",
  [COMPANY_IDS.JUNK_OUT_ABQ]: "l2iSjQs9y08",
  [COMPANY_IDS.FIND_HOW_YOU_FLOURISH]: "mc8yWzyKoyY",
  [COMPANY_IDS.SOFTWATER_SPECIALISTS]: "RSAjCs7OrEo",
  [COMPANY_IDS.SOLAR_EXPRESS]: "YnU6mUNJm0I",
  [COMPANY_IDS.SKIN_SPA]: "kAZUzLm83Vw",
};

export const REFERRAL_PAGE_IMAGES = {
  [COMPANY_IDS.PRINCE_GEORGE_COUNTY_KUIK_DRY]: "/kwikReferralPageImage.png",
};
