import { Col, Row, Space, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { useCompany } from "../../providers/CompanyContext"; // adjust the path as needed
import SettingsStyle from "./SettingsStyle";
import CompanyBranding from "./components/CompanyBranding";
import CompanyQRCodeDisplay from "./components/CompanyQRCodeDisplay";
import NewReferralMessage from "./components/NewReferralMessage";
import ReferralRequestMessage from "./components/ReferralRequestMessage";
import ReferralShareMessage from "./components/ReferralShareMessage";

const ReferralSettings = () => {
  const { loading, company, updateCompany } = useCompany();
  return (
    <SettingsStyle className="settingsStyleRoot">
      <Content>
        {loading ? (
          <Row
            justify="center"
            style={{ marginTop: 180, width: "100%" }}
          >
            <Spin size="large" />
          </Row>
        ) : (
          <>
            <Row
              justify="center"
              style={{ width: "100%" }}
            >
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                xl={{ span: 10 }}
              >
                <Space direction="vertical">
                  <Row>
                    <ReferralRequestMessage />
                  </Row>
                </Space>
              </Col>
              <Col
                xs={{ span: 22, offset: 0 }}
                sm={{ span: 20 }}
                xl={{ span: 10, offset: 1 }}
              >
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <NewReferralMessage />
                  </Row>
                  <Row>
                    <ReferralShareMessage />
                  </Row>
                </Space>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{ width: "100%" }}
            >
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                xl={{ span: 10 }}
              >
                <Space direction="vertical">
                  <Row>
                    <CompanyBranding />
                  </Row>
                </Space>
              </Col>
              <Col
                xs={{ span: 22, offset: 0 }}
                sm={{ span: 20 }}
                xl={{ span: 10, offset: 1 }}
              >
                <Space direction="vertical">
                  <Row>
                    {company && (
                      <CompanyQRCodeDisplay company={company as any} />
                    )}
                  </Row>
                </Space>
              </Col>
            </Row>
          </>
        )}
      </Content>
    </SettingsStyle>
  );
};

const Settings = () => {
  return <ReferralSettings />;
};

export default Settings;
