import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, REFERPRO_TOKEN_NAME } from "../constants";

import { Company, Customer, Link, Referral } from "../types";
// eslint-disable-next-line import/extensions
import referrals from "./fixtures/referrals.json";

const CUSTOMER_EXPAND = ["referrals", "clicks"];

export default class Api {
  static async createSubscription(plan: "lite" | "pro") {
    const response = await Api.post<{ url: string }>(
      "subscriptions/create-checkout-session",
      { plan }
    );
    if (response.url) {
      window.location.replace(response.url);
    }

    throw new Error("Could not create checkout session");
  }
  static async markReferralAsSuccessful(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsSuccessfulNow`);
  }
  static async markReferralAsUnsuccessful(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsUnsuccessful`);
  }

  static async markReferralAsContacted(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsContactedNow`);
  }
  static async markReferralAsNotContacted(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsNotContacted`);
  }

  static async markReferralAsBooked(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsBookedNow`);
  }
  static async markReferralAsNotBooked(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsNotBooked`);
  }

  static async markReferralAsCompleted(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsCompletedNow`);
  }

  static async markReferralAsNotCompleted(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsNotCompleted`);
  }

  static async markReferralAsArchived(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsArchivedNow`);
  }

  static async markReferralAsNotArchived(referralId: string) {
    await Api.post(`referrals/${referralId}/markAsNotArchived`);
  }

  static async searchCustomers(searchText: string) {
    const response = await Api.get<{ customers }>(
      `customers/search/${searchText}`
    );
    return response.customers;
  }

  static async getCompany(companyId): Promise<Company> {
    const response = await Api.get<{ company: Company }>(
      `companies/${companyId}`
    );
    return response.company;
  }

  static async getCustomer(customerId): Promise<Customer> {
    const response = await Api.get<{ customer: Customer }>(
      `customers/${customerId}`,
      {
        data: {
          expand: CUSTOMER_EXPAND,
        },
      }
    );
    return response.customer;
  }

  static async listReferrals(): Promise<Referral[]> {
    return Promise.resolve(referrals.referrals as any as Referral[]);
  }

  static async listLinks(): Promise<Link[]> {
    const response = await Api.get<{ links: Link[] }>("links");
    return response.links;
  }

  static async createCustomer(
    firstName,
    lastName,
    email,
    phone
  ): Promise<Customer> {
    const response = await Api.post<{ customer: Customer }>("customers/", {
      firstName,
      lastName,
      email,
      phone,
    });
    return response.customer;
  }

  static async createCustomerLink(customerId): Promise<Link> {
    const response = await Api.post<{ link: Link }>(`links/${customerId}`);
    return response.link;
  }

  static async createCampaignGroup(name, description) {
    const response = await Api.post("campaigns/groups/create", {
      name: name,
      description,
    });
    return response.group;
  }

  static async exportReferrals() {
    const response = await Api.get(`referrals/export/csv`, {
      responseType: "blob",
    });

    const blob = new Blob([response], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "customer_referrals.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up the Blob URL
    URL.revokeObjectURL(url);
  }

  static async getReferralInformation(
    pathName
  ): Promise<{ company: Company; customer: Customer }> {
    const response = await Api.get<{ link: Link }>("links", {
      params: { path: pathName },
    });
    const link = response.link;
    const [company, customer] = await Promise.all([
      Api.getCompany(link.companyId),
      Api.getCustomer(link.customerId),
    ]);
    return { company, customer };
  }

  static async getPodiumAuthenticationLink() {
    const response = await Api.get<{ authCodeUrl: string }>(
      "integrations/podium/auth-code-url"
    );
    return response.authCodeUrl;
  }

  static async createPodiumAuthoriationToken(authCode: string) {
    const response = await Api.post<string>(
      `integrations/podium/auth-code/${authCode}`
    );
    return response;
  }

  static async importPodiumContacts() {
    const response = await Api.post<string>(
      `integrations/podium/import-contacts`
    );
    return response;
  }

  static async getIntuitAuthenticationLink() {
    const response = await Api.get<{ authCodeUrl: string }>(
      "integrations/intuit/auth-code-url"
    );
    return response.authCodeUrl;
  }

  static async uploadCompanyLogo(logo: File, companyId: string) {
    const formData = new FormData();
    formData.append("logo", logo);
    const response = await Api.post<{ logoUrl: string }>(
      `companies/${companyId}/logo`,
      formData
    );
    return response.logoUrl;
  }

  static async get<T = any>(path, optionalConfig?: AxiosRequestConfig<any>) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${Api.getAccessToken()}`,
      },
      ...optionalConfig,
    };

    const response = await axios.get<T>(`${BASE_URL}/${path}`, config);
    return response.data;
  }

  static async post<T = any>(path, body = {}) {
    const config = {
      headers: {
        Authorization: `Bearer ${Api.getAccessToken()}`,
      },
    };

    const response = await axios.post<T>(`${BASE_URL}/${path}`, body, config);
    return response.data;
  }
  static async put<T = any>(path, body = {}) {
    const config = {
      headers: {
        Authorization: `Bearer ${Api.getAccessToken()}`,
      },
    };

    const response = await axios.put<T>(`${BASE_URL}/${path}`, body, config);
    return response.data;
  }

  static getAccessToken() {
    return localStorage.getItem(REFERPRO_TOKEN_NAME);
  }
}
