import { ReferralType } from "__generated__/graphql";
import { Col, Divider, Form, Row } from "antd";
import CompanyImage from "components/referralForm/basicComponents/CompanyImage";
import CompanyVideo from "components/referralForm/basicComponents/CompanyVideo";
import ReferralFormTitle from "components/referralForm/basicComponents/ReferralFormTitle";
import EmployeeInput from "components/referralForm/EmployeeInput";
import ReferredByPersonInput from "components/referralForm/ReferredByPersonInput";
import ReferredPersonInput from "components/referralForm/ReferredPersonInput";
import ReferralSubmitButtons from "components/referralForm/SubmitReferral";
import ReferralFormLayout, {
  CompanyBranding,
  PublicEmployee,
} from "layout/referralForm/ReferralForm";
import PosthogHandler from "lib/posthog";
import React from "react";

const GeneralEmployeeReferralForm: React.FC<{
  companyId: string;
  companyName: string;
  employeeOptions: PublicEmployee[];
  saveGeneralEmployeeReferral: (
    referredByName: string,
    referredByPhone: string,
    referredByEmail: string,
    referralName: string,
    referralPhone: string,
    employeeId: string,
    referralType: ReferralType
  ) => any;
  referralBannerMessage?: string;
  isEmbedded?: boolean;
  companyBranding?: CompanyBranding;
}> = ({
  companyId,
  companyName,
  saveGeneralEmployeeReferral,
  employeeOptions,
  referralBannerMessage,
  isEmbedded = false,
  companyBranding = {},
}) => {
  const [employeeId, setEmployeeId] = React.useState("");
  const [referredByName, setReferredByName] = React.useState("");
  const [referredByPhoneNumber, setReferredByPhoneNumber] = React.useState("");
  const [referredByEmail, setReferredByEmail] = React.useState("");
  const [referralName, setReferralName] = React.useState("");
  const [referralPhoneNumber, setReferralPhoneNumber] = React.useState("");

  const [employeeValid, setEmployeeValid] = React.useState(false);
  const [referredByValid, setReferredByValid] = React.useState(false);
  const [referredValid, setReferredValid] = React.useState(false);

  if (companyBranding.alternateCompanyName) {
    companyName = companyBranding.alternateCompanyName;
  }

  return (
    <ReferralFormLayout
      companyId={companyId}
      referralBannerMessage={
        PosthogHandler.isFeatureFlagEnabledForVariant(
          "reward-as-header",
          "test"
        )
          ? undefined
          : referralBannerMessage
      }
      isEmbedded={isEmbedded}
      companyBranding={companyBranding}
    >
      <ReferralFormTitle
        title={
          PosthogHandler.isFeatureFlagEnabledForVariant(
            "reward-as-header",
            "test"
          )
            ? referralBannerMessage
            : `Refer a friend to ${companyName}!`
        }
        wavingHand={true}
        emoji={
          PosthogHandler.isFeatureFlagEnabledForVariant(
            "reward-as-header",
            "test"
          )
            ? "🎉"
            : "👋"
        }
      />
      <Divider />
      <CompanyVideo videoId={companyBranding.youtubeVideoId} />
      <CompanyImage companyId={companyId} />
      <Row justify="center">
        <Col>
          <Form
            name="materialForm"
            layout="vertical"
          >
            <EmployeeInput
              employeeOptions={employeeOptions}
              fadeInOffset={0}
              setValue={(value: string) => {
                PosthogHandler.capture(
                  "general_employee_referral_form_employee_selected",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    employeeId: value,
                  }
                );
                setEmployeeId(value);
              }}
              setIsValid={(valid: boolean) => {
                setEmployeeValid(valid);
              }}
            />
            <ReferredByPersonInput
              setIsValid={(valid: boolean) => {
                setReferredByValid(valid);
              }}
              setValues={(values: {
                name: string;
                phoneNumber: string;
                email: string;
              }) => {
                PosthogHandler.capture(
                  "general_employee_referral_form_referred_by_person_input",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referredByName: values.name,
                    referredByPhoneNumber: values.phoneNumber,
                    referredByEmail: values.email,
                  }
                );
                setReferredByName(values.name);
                setReferredByPhoneNumber(values.phoneNumber);
                setReferredByEmail(values.email);
              }}
              fadeInOffset={1}
              disabled={!employeeValid}
            />
            <ReferredPersonInput
              companyName={companyName}
              setIsValid={(valid: boolean) => {
                setReferredValid(valid);
              }}
              setValues={(values: { name: string; phoneNumber: string }) => {
                PosthogHandler.capture(
                  "general_employee_referral_form_referred_person_input",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referralName: values.name,
                    referralPhoneNumber: values.phoneNumber,
                  }
                );
                setReferralName(values.name);
                setReferralPhoneNumber(values.phoneNumber);
              }}
              fadeInOffset={4}
              disabled={!employeeValid || !referredByValid}
            />
            <ReferralSubmitButtons
              submitReferral={async () => {
                PosthogHandler.capture(
                  "general_employee_referral_form_submit_referral",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referredByName,
                    referredByPhoneNumber,
                    referredByEmail,
                    referralName,
                    referralPhoneNumber,
                    employeeId,
                  }
                );
                await saveGeneralEmployeeReferral(
                  referredByName,
                  referredByPhoneNumber,
                  referredByEmail,
                  referralName,
                  referralPhoneNumber,
                  employeeId,
                  ReferralType.Standard
                );
              }}
              submitSelfReferral={async () => {
                PosthogHandler.capture(
                  "general_employee_referral_form_submit_self_referral",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referredByName,
                    referredByPhoneNumber,
                    referredByEmail,
                    employeeId,
                  }
                );
                await saveGeneralEmployeeReferral(
                  referredByName,
                  referredByPhoneNumber,
                  referredByEmail,
                  referredByName,
                  referredByPhoneNumber,
                  employeeId,
                  ReferralType.Self
                );
              }}
              isReadyToSubmitReferral={
                employeeValid && referredByValid && referredValid
              }
              isReadyToSubmitSelfReferral={
                employeeValid &&
                referredByValid &&
                !referralName &&
                !referralPhoneNumber
              }
              fadeInOffset={6}
            />
          </Form>
        </Col>
      </Row>
    </ReferralFormLayout>
  );
};

export default GeneralEmployeeReferralForm;
