import { DownloadOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { Employee } from "../../../__generated__/graphql";

interface EmployeeQRCodeDisplayProps {
  employee: Employee;
}

const EmployeeQRCodeDisplay: React.FC<EmployeeQRCodeDisplayProps> = ({
  employee,
}) => {
  const [qrCodeData, setQRCodeData] = useState<string>("");

  useEffect(() => {
    // Generate the QR code data
    const generateQRCodeData = () => {
      const data = employee.personalLink.target;
      setQRCodeData(data);
    };

    generateQRCodeData();
  }, [employee]);

  const downloadQRCode = () => {
    const canvas = document.getElementById(
      "employeeQRCode"
    ) as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${employee.firstName}-${employee.lastName}-qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const generateEmployeeQRCode = () => {};

  return (
    <>
      <Row
        justify="center"
        align="middle"
      >
        <Col span={10}>
          {qrCodeData ? (
            <Button
              type="primary"
              onClick={downloadQRCode}
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              QR Code
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={generateEmployeeQRCode}
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              Create QR Code
              <QrcodeOutlined style={{ fontSize: "20px" }} />
            </Button>
          )}
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ marginTop: "10px" }}
      >
        <Col span={10}>
          {qrCodeData ? (
            <QRCode
              id="employeeQRCode"
              value={qrCodeData}
              size={150}
            />
          ) : (
            <div
              style={{
                border: "2px solid #eee",
                width: "150px",
                height: "150px",
              }}
            ></div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmployeeQRCodeDisplay;
