import { AlignLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Button, Col, Form, Input, Row, Space, Typography } from "antd";
import Api from "api/api";
import ScheduleADemo from "components/ScheduleADemo";
import React from "react";
import FloatingCard from "../../../components/FloatingCard";
import { useAuthorization } from "../../../providers/AuthorizationContext";

const renderMissingPermissionAlert = () => {
  return (
    <Alert
      message={
        <>
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            <Row justify="center">
              <Col>
                <Typography.Text>
                  Free accounts do not include Campaign creation. Want all the
                  features?
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <ScheduleADemo />
              </Col>
            </Row>
          </Space>
        </>
      }
      type="info"
      showIcon={false}
      closable={false}
      banner
      style={{ marginBottom: 10 }}
    />
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1285px) {
    flex-direction: column;
  }
`;

interface CreateCampaignGroupFormProps {
  style?: React.CSSProperties;
}

const CreateNewCampaignGroupForm: React.FC<CreateCampaignGroupFormProps> = ({
  style,
}) => {
  const [form] = Form.useForm();

  const { canCreateCampaigns } = useAuthorization();

  const onFinish = async (values) => {
    const { name, description } = values;
    await Api.createCampaignGroup(name, description);
    form.resetFields();
  };

  return (
    <FloatingCard
      title="Create New Campaign Group"
      style={style || {}}
    >
      {!canCreateCampaigns && renderMissingPermissionAlert()}
      <Form
        form={form}
        onFinish={onFinish}
        size="middle"
        disabled={!canCreateCampaigns}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter a group name!",
            },
          ]}
        >
          <Input
            placeholder="Group Name"
            style={{
              width: "100%",
            }}
            prefix={<AlignLeftOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter a group description!",
            },
          ]}
        >
          <Input
            placeholder="GroupDescription"
            style={{
              width: "100%",
            }}
            prefix={<AlignLeftOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Space align="center">
            <ButtonWrapper>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ minWidth: 200 }}
              >
                Create
              </Button>
              <Button
                onClick={() => form.resetFields()}
                size="large"
                style={{ minWidth: 200 }}
              >
                Reset
              </Button>
            </ButtonWrapper>
          </Space>
        </Form.Item>
      </Form>
    </FloatingCard>
  );
};

export default CreateNewCampaignGroupForm;
