import { gql } from "@apollo/client";
import { CORE_COMPANY_FIELDS, CORE_USER_FIELDS } from "./fragments";

export const GET_COMPANY = gql`
  ${CORE_COMPANY_FIELDS}
  query GetCompany {
    getCompany {
      ...CoreCompanyFields
    }
  }
`;

export const GET_EMPLOYEE_LEADERBOARD = gql`
  query GetEmployeeLeaderboard {
    getEmployeeLeaderboard {
      leaderboard {
        employee {
          firstName
          lastName
        }
        totalReferrals
        totalSuccessfulReferrals
        totalRewardedReferrals
      }
    }
  }
`;

export const GET_FEED = gql`
  query GetFeed {
    getFeed {
      groups {
        date
        entries {
          id
          createdAt
          ... on Referral {
            firstName
            lastName
            email
            message
            phone
            referredBy {
              id
              firstName
              lastName
            }
            referredByEmployee {
              id
              firstName
              lastName
            }
            reward {
              id
              amount
              type
              status
            }
            markedAsSuccessfulDate
            markedAsContactedDate
            markedAsBookedDate
            markedAsCompletedDate
            markedAsArchivedDate
            type
          }
        }
      }
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  ${CORE_USER_FIELDS}
  ${CORE_COMPANY_FIELDS}
  query GetLoggedInUser {
    getLoggedInUser {
      ...CoreUserFields
      company {
        ...CoreCompanyFields
      }
    }
  }
`;

export const GET_USER = gql`
  ${CORE_USER_FIELDS}
  ${CORE_COMPANY_FIELDS}
  query GetUser($id: ID!) {
    getUser(id: $id) {
      ...CoreUserFields
      company {
        ...CoreCompanyFields
      }
    }
  }
`;

export const LIST_CUSTOMERS = gql`
  query ListCustomers {
    listCustomers {
      customers {
        id
        firstName
        lastName
        email
        phone
        referralLinks {
          id
          target
          createdAt
          deliveries {
            sentAt
            success
            method
          }
          clicks
        }
        campaignGroups {
          id
          name
        }
      }
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query SearchCustomers($searchText: String!, $limit: Int) {
    searchCustomers(searchText: $searchText, limit: $limit) {
      customers {
        id
        firstName
        lastName
        email
        phone
        referralLinks {
          id
          target
          createdAt
          deliveries {
            sentAt
            success
            method
          }
          clicks
        }
        campaignGroups {
          id
          name
        }
      }
    }
  }
`;

export const LIST_CUSTOMERS_PAGINATED = gql`
  query ListCustomersPaginated($first: Int!, $after: ID) {
    listCustomers(paginationInfo: { first: $first, after: $after }) {
      customers {
        id
        firstName
        lastName
        email
        phone
        referralLinks {
          id
          target
          createdAt
          deliveries {
            sentAt
            success
            method
          }
          clicks
        }
        campaignGroups {
          id
          name
        }
      }
      endCursor
    }
  }
`;

export const LIST_EMPLOYEES_PAGINATED = gql`
  query ListEmployeesPaginated($first: Int!, $after: ID) {
    listEmployeesWithPaginationInfo(
      paginationInfo: { first: $first, after: $after }
    ) {
      employees {
        id
        firstName
        lastName
        email
        phone
        personalLink {
          target
        }
      }
      endCursor
    }
  }
`;

export const LIST_REFERRALS = gql`
  query ListReferrals {
    listReferrals {
      id
      firstName
      lastName
      createdAt
      email
      message
      phone
      referredBy {
        id
        firstName
        lastName
        phone
      }
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;

export const LIST_CAMPAIGNS = gql`
  query ListCampaigns {
    listCampaigns {
      id
      name
      createdAt
      finishedAt
      deactivatedAt
      config {
        iterations
        frequency
        sendAtHour
        sendAtTimezone
      }
      executions {
        id
        iteration
        createdAt
        finishedAt
        success
        startsAt
        nextBatchAt
        batches {
          id
          number
          finishedAt
          offset
          size
        }
      }
      campaignGroups {
        id
        name
        description
      }
    }
  }
`;
export const LIST_CAMPAIGN_GROUPS = gql`
  query ListCampaignGroups {
    listCampaignGroups {
      id
      name
      description
    }
  }
`;
