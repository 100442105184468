import { Button, Form, Input, notification, Spin, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SetUpAPI from "../../../api/setup";
import FloatingCard from "../../../components/FloatingCard";
import TypingText from "../../../components/TypingText";

interface FormInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: number;
  businessName: string;
  businessDomain: string;
}

const SetUpForm: React.FC<{
  userId: string;
  setReferProToken: (token: string) => void;
}> = ({ userId, setReferProToken }) => {
  const [password, setPassword] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, contextHolder] = notification.useNotification();

  const onFinish = async (values: FormInputs) => {
    setIsSubmitting(true);
    const response = await SetUpAPI.call(userId, password);
    setIsSubmitting(false);
    if (response) {
      setReferProToken(response.accessToken);
      message.success({
        message: "Success!",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error({
      message: "Error completing setup",
      description: errorInfo,
    });
  };

  const [form] = Form.useForm();

  return (
    <>
      {contextHolder}
      <FloatingCard
        title={
          <Typography.Title>
            <TypingText
              text="Hello! Let's finish setting up ..."
              duration={80}
            />
          </Typography.Title>
        }
      >
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="row-col"
          form={form}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
            ]}
            hasFeedback
            label="Create a password"
            labelCol={{ span: 24 }}
          >
            <Input.Password
              onChange={(e) => setPassword(e.target.value)}
              size="large"
              placeholder="Min 8 characters"
            />
          </Form.Item>
          <Form.Item>
            <Spin spinning={isSubmitting}>
              <Button
                size="large"
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                shape="round"
                disabled={
                  password !== "" && password.length >= 8 ? false : true
                }
              >
                COMPLETE SETUP
              </Button>
            </Spin>
          </Form.Item>
        </Form>
        <p className="font-semibold text-muted text-center">
          Already have an account?{" "}
          <Link
            to="/sign-in"
            className="font-bold text-dark"
          >
            Sign In
          </Link>
        </p>
      </FloatingCard>
    </>
  );
};

export default SetUpForm;
