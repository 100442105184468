import { Button } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

const ScheduleADemo: React.FC = () => {
  return (
    <NavLink
      onClick={(event) => {
        event.preventDefault();
        window.location.href =
          "https://calendly.com/referpro-with-andrew/30min";
      }}
      to="/"
    >
      <Button
        style={{ marginLeft: 10 }}
        type="primary"
      >
        Schedule a Demo Today!
      </Button>
    </NavLink>
  );
};

export default ScheduleADemo;
