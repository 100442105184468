import styled from "@emotion/styled";
import { Card, Statistic } from "antd";
import React, { ReactElement } from "react";

const SummaryStyle = styled.div({
  "&.root": {
    ".stat-card": {
      boxShadow: "0 6px 10px rgb(0 0 0 / 12%)",
      borderRadius: 20,
    },
  },
});

interface Props {
  title: string;
  amount: number;
  prefix: ReactElement;
  loading?: boolean;
}

const Summary: React.FC<Props> = ({
  title,
  amount,
  prefix,
  loading = false,
}) => {
  return (
    <SummaryStyle className="root">
      <Card
        bordered={false}
        bodyStyle={{ textAlign: "center" }}
        className="stat-card"
      >
        <Statistic
          loading={loading}
          title={title}
          value={amount}
          prefix={React.cloneElement(prefix, { className: "colorMain" })}
        />
      </Card>
    </SummaryStyle>
  );
};

export default Summary;
