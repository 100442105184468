import { COMPANY_IDS } from "../constants";

export const isEmpty = (str: string | null | undefined) =>
  str === null || str === undefined || str === "";

export const isNameValid = (name) => name.length > 0;
export const isPhoneNumberValid = (phoneNumber) => phoneNumber.length === 10;
export const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isFormattedPhoneNumberValid = (phoneString) => {
  // this regex removes all non-numeric characters
  const phone = phoneString.replace(/\D/g, "");
  return phone.length === 10;
};

export const unformatPhoneNumber = (phoneString) =>
  phoneString.replace(/\D/g, "");

export const formatPhoneNumber = (value: string) => {
  const numericValue = value.replace(/\D/g, "");
  let formattedValue = "";

  if (numericValue.length < 4) {
    formattedValue = numericValue;
  } else if (numericValue.length < 7) {
    formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
  } else {
    formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(
      3,
      6
    )}-${numericValue.slice(6, 10)}`;
  }

  return formattedValue;
};

export function getAlternateCompanyName(companyId: string): string | undefined {
  switch (companyId) {
    case COMPANY_IDS.DOOR_SERV_PRO.MARYLAND:
    case COMPANY_IDS.DOOR_SERV_PRO.VIRGINIA:
    case COMPANY_IDS.DOOR_SERV_PRO.PENNSYLVANIA:
    case COMPANY_IDS.DOOR_SERV_PRO.WEST_VIRGINIA:
      return "Door Serv Pro";
    default:
      return undefined;
  }
}
