import { CreditCardOutlined, DeleteOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  CardBrand,
  CreatePaymentMethodMutation,
  ListPaymentMethodsQuery,
  PaymentMethod,
} from "../../../__generated__/graphql";

import amex from "../../../assets/images/amex-logo.png";
import discover from "../../../assets/images/discover-logo.png";
import mastercard from "../../../assets/images/mastercard-logo.png";
import visa from "../../../assets/images/visa-logo.png";

const { Text } = Typography;

const LIST_PAYMENT_METHODS = gql`
  query ListPaymentMethods {
    listPaymentMethods {
      id
      lastFour
      brand
      expYear
      expMonth
    }
  }
`;

const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      success
    }
  }
`;

const getCardBrandImage = (brand: CardBrand) => {
  const img = (src, width = 35) => (
    <img
      src={src}
      style={{ width }}
    />
  );
  switch (brand) {
    case CardBrand.Visa:
      return img(visa);
    case CardBrand.Mastercard:
      return img(mastercard);
    case CardBrand.Discover:
      return img(discover, 60);
    case CardBrand.Amex:
      return img(amex);
    default:
      return <CreditCardOutlined style={{ fontSize: 25 }} />;
  }
};

const displayLastFour = (lastFour: string, brand: CardBrand): string => {
  if (brand === CardBrand.Amex) {
    return `**** ****** *${lastFour}`;
  }
  return `**** **** **** ${lastFour}`;
};

const CreatePaymentMethodContainer: React.FC<{ onFinish: () => void }> = ({
  onFinish,
}) => {
  const [createPaymentMethod, { data, loading, error }] =
    useMutation<CreatePaymentMethodMutation>(CREATE_PAYMENT_METHOD);

  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCVC] = useState("");
  const [postalCode, setPostalCode] = useState("");

  if (data) {
    onFinish();
  }

  return (
    <Spin spinning={loading}>
      {/* <AddPaymentMethod onFinish={onFinish} /> */}
      <Card
        title={<CreditCardOutlined style={{ fontSize: 25 }} />}
        extra={
          <Button
            type="link"
            className="ant-edit-link"
            onClick={() =>
              createPaymentMethod({
                variables: {
                  input: {
                    cardNumber,
                    expMonth: parseInt(expMonth),
                    expYear: parseInt(expYear),
                    cvc: parseInt(cvc),
                    postalCode: parseInt(postalCode),
                  },
                },
              })
            }
          >
            Save
          </Button>
        }
      >
        <Space direction="vertical">
          <div>
            {error && (
              <Row>
                <Col style={{ color: "red" }}>{error.message}</Col>
              </Row>
            )}
            <Row>
              <Col>Card Number</Col>
            </Row>
            <Row>
              <Col>
                <Input
                  value={cardNumber}
                  onChange={(elem) => setCardNumber(elem.target.value)}
                  placeholder="4242 4242 4242 4242"
                ></Input>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col>Expiration</Col>
            </Row>
            <Row>
              <Col span={6}>
                <Input
                  value={expMonth}
                  onChange={(elem) => setExpMonth(elem.target.value)}
                  placeholder="12"
                ></Input>
              </Col>
              <Col
                span={8}
                offset={1}
              >
                <Input
                  value={expYear}
                  onChange={(elem) => setExpYear(elem.target.value)}
                  placeholder="2025"
                ></Input>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col>CVC</Col>
            </Row>
            <Row>
              <Col span={6}>
                <Input
                  value={cvc}
                  onChange={(elem) => setCVC(elem.target.value)}
                  placeholder="123"
                ></Input>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col>Postal code</Col>
            </Row>
            <Row>
              <Col span={16}>
                <Input
                  value={postalCode}
                  onChange={(elem) => setPostalCode(elem.target.value)}
                  placeholder="90210"
                ></Input>
              </Col>
            </Row>
          </div>
        </Space>
      </Card>
    </Spin>
  );
};

const PaymentMethodContainer: React.FC<{ data: PaymentMethod }> = ({
  data,
}) => {
  return (
    <Card
      title={getCardBrandImage(data.brand)}
      extra={
        <Button
          type="link"
          className="ant-edit-link"
        >
          <DeleteOutlined style={{ fontSize: "20px", color: "darkgray" }} />
        </Button>
      }
    >
      <Row>
        <Col>
          <h3>{displayLastFour(data.lastFour, data.brand)}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4
            style={{ color: "gray" }}
          >{`Exp: ${data.expMonth}/${data.expYear}`}</h4>
        </Col>
      </Row>
    </Card>
  );
};

const AddPaymentMethodButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <Button
    size="middle"
    className="w100"
    type="primary"
    style={{
      width: "100%",
    }}
    onClick={onClick}
  >
    <CreditCardOutlined /> Add new payment method
  </Button>
);

const PaymentMethods: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { loading, error, data, refetch } =
    useQuery<ListPaymentMethodsQuery>(LIST_PAYMENT_METHODS);

  const ready = !loading && !error;

  const showCreatePaymentMethod = () => setVisible(true);

  return (
    <Card
      bordered={false}
      className="header-solid h-full  ant-list-yes"
      style={{ borderRadius: 20, boxShadow: "0 6px 10px rgb(0 0 0 / 12%)" }}
      title="Payment Methods"
    >
      {loading && <Skeleton active />}
      {error && (
        <Empty
          description="Nothing yet!"
          style={{ marginTop: 20 }}
        />
      )}
      {ready && (
        <>
          <Space
            direction="vertical"
            size={20}
            className="w100"
          >
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <AddPaymentMethodButton onClick={showCreatePaymentMethod} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {visible && (
                  <CreatePaymentMethodContainer
                    onFinish={() => {
                      setVisible(false);
                      refetch();
                    }}
                  />
                )}
              </Col>
            </Row>
            {data.listPaymentMethods.map((paymentMethod) => (
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <PaymentMethodContainer data={paymentMethod} />
                </Col>
              </Row>
            ))}
          </Space>
        </>
      )}
    </Card>
  );
};

export default PaymentMethods;
