import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Descriptions,
  Dropdown,
  Input,
  Popconfirm,
  Row,
  Space,
} from "antd";
import { DELETE_EMPLOYEE, UPDATE_EMPLOYEE } from "data/mutations";
import React, { useState } from "react";
import { Employee } from "../../../__generated__/graphql";
import Toast from "../../../components/Toast";
import { LIST_EMPLOYEES_PAGINATED } from "../../../data/queries";
import EmployeeQRCodeDisplay from "./EmployeeQRCodeDisplay";

const ExpandedEmployeeRow: React.FC<{ employee: Employee }> = ({
  employee,
}) => {
  const renderDescriptionsItem = (
    label: string,
    value: string,
    field: keyof Employee
  ) => {
    if (editMode) {
      return (
        <Descriptions.Item label={label}>
          <Input
            value={editedData[field] ?? value}
            onChange={(e) => onInputChange(field, e.target.value)}
          />
        </Descriptions.Item>
      );
    } else {
      return <Descriptions.Item label={label}>{value}</Descriptions.Item>;
    }
  };

  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState<any>({});

  const onInputChange = (field: keyof Employee, value: string) => {
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE, {
    refetchQueries: [
      { query: LIST_EMPLOYEES_PAGINATED, variables: { first: 1000 } },
      "ListEmployeesPaginatedQuery",
    ],
  });

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: LIST_EMPLOYEES_PAGINATED,
        variables: { first: 1000 },
      },
      "ListEmployeesPaginatedQuery",
    ],
  });

  const onEditClick = () => {
    setEditMode(true);
    setEditedData(employee);
  };

  const onCancelClick = () => {
    setEditMode(false);
    setEditedData({});
  };

  const onSaveClick = async () => {
    try {
      await updateEmployee({
        variables: {
          input: {
            employeeId: employee.id,
            updateAttributes: {
              ...(employee.firstName !== editedData.firstName && {
                firstName: editedData.firstName,
              }),
              ...(employee.lastName !== editedData.lastName && {
                lastName: editedData.lastName,
              }),
              ...(employee.email !== editedData.email && {
                email: editedData.email,
              }),
              ...(employee.phone !== editedData.phone && {
                phone: editedData.phone,
              }),
            },
          },
        },
      });
      setEditMode(false);
      setEditedData({});
      Toast("success", "Update employee success!");
    } catch (e) {
      Toast("error", "Error saving employee");
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={14}>
          <Descriptions
            title={"Employee Info"}
            layout="vertical"
            colon={false}
            size="small"
            extra={
              <>
                {editMode ? (
                  <Space direction="horizontal">
                    <Button
                      type="primary"
                      onClick={onSaveClick}
                    >
                      Save
                    </Button>
                    <Button onClick={onCancelClick}>Cancel</Button>
                  </Space>
                ) : (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <>
                              <EditOutlined /> Edit
                            </>
                          ),
                          onClick: onEditClick,
                        },
                        {
                          key: "2",
                          label: (
                            <>
                              <Popconfirm
                                title={`Deleting ${employee.firstName} ${employee.lastName}`}
                                description="Are you sure?"
                                onConfirm={() =>
                                  deleteEmployee({
                                    variables: {
                                      input: {
                                        employeeId: employee.id,
                                      },
                                    },
                                  })
                                }
                                okText="Yes"
                                cancelText="Cancel"
                              >
                                <DeleteOutlined /> Delete
                              </Popconfirm>
                            </>
                          ),
                        },
                      ],
                    }}
                    placement="bottomRight"
                  >
                    <Button type="link">
                      <EllipsisOutlined style={{ fontSize: "20px" }} />
                    </Button>
                  </Dropdown>
                )}
              </>
            }
          >
            {renderDescriptionsItem(
              "First Name",
              employee.firstName,
              "firstName"
            )}
            {renderDescriptionsItem("Last Name", employee.lastName, "lastName")}
            {renderDescriptionsItem("Email", employee.email, "email")}
            {renderDescriptionsItem("Phone", employee.phone, "phone")}
            <Descriptions.Item label="Personal Link">
              <a href={employee.personalLink.target}>
                {employee.personalLink.target}
              </a>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={10}>
          <EmployeeQRCodeDisplay employee={employee} />
        </Col>
      </Row>
    </>
  );
};

export default ExpandedEmployeeRow;
