import { useQuery } from "@apollo/client";
import {
  EmployeeLeaderboardEntry,
  GetEmployeeLeaderboardQuery,
} from "__generated__/graphql";
import { Empty, Row, Skeleton, Space, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import FloatingCard from "components/FloatingCard";
import SearchableTable from "components/SearchableTable";
import { GET_EMPLOYEE_LEADERBOARD } from "data/queries";
import React from "react";

const { Text } = Typography;

const columns: ColumnsType<EmployeeLeaderboardEntry> = [
  {
    title: "Name",
    key: "name",
    render: (_, employeeLeaderboardEntry) => (
      <Space>
        <Text>
          {employeeLeaderboardEntry.employee.firstName}{" "}
          {employeeLeaderboardEntry.employee.lastName}
        </Text>
      </Space>
    ),
    sorter: (a, b) => a.employee.lastName.localeCompare(b.employee.lastName),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Total Referrals",
    key: "totalReferrals",
    dataIndex: "totalReferrals",
    sorter: (a, b) => a.totalReferrals - b.totalReferrals,
    defaultSortOrder: "descend",
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Total Successful Referrals",
    key: "totalSuccessfulReferrals",
    dataIndex: "totalSuccessfulReferrals",
    sorter: (a, b) => a.totalSuccessfulReferrals - b.totalSuccessfulReferrals,
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Total Rewarded Referrals",
    key: "totalRewardedReferrals",
    dataIndex: "totalRewardedReferrals",
    sorter: (a, b) => a.totalRewardedReferrals - b.totalRewardedReferrals,
    sortDirections: ["ascend", "descend"],
  },
];

const EmployeeLeaderboard: React.FC = () => {
  const { data, loading, error } = useQuery<GetEmployeeLeaderboardQuery>(
    GET_EMPLOYEE_LEADERBOARD
  );

  const ready = data && !loading && !error;
  return (
    <FloatingCard title="Employee Leaderboard">
      {loading && <Skeleton active />}
      {error && (
        <Empty
          description="Uh oh!"
          style={{ marginTop: 20 }}
        />
      )}
      {ready && (
        <>
          <Row>
            <SearchableTable
              columns={columns}
              data={data!.getEmployeeLeaderboard.leaderboard}
            />
          </Row>
        </>
      )}
    </FloatingCard>
  );
};
export default EmployeeLeaderboard;
