import PhoneOutlined from "@ant-design/icons/lib/icons/PhoneOutlined";

const HungUpPhoneOutlined = () => (
  <PhoneOutlined
    style={{
      transform: "translateY(2px) rotate(225deg)",
    }}
  />
);

export default HungUpPhoneOutlined;
