import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import React from "react";

const { Content } = Layout;

const SignUp: React.FC<{ setReferProToken: (token: string) => void }> = ({
  setReferProToken,
}) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  loginWithRedirect();

  return <Content className="p-0"></Content>;
};

export default SignUp;
