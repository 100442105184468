import { Col, Row } from "antd";
import React from "react";
import Content from "../../layout/Content";
import { PaymentMethods, Transactions } from "./components";

import BillingStyle from "./BillingStyle";

interface Props {}

const Billing: React.FC<Props> = () => {
  return (
    <BillingStyle className="billingStyleRoot">
      <Content>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 14, offset: 1 }}
          lg={{ span: 14, offset: 1 }}
          xl={{ span: 14, offset: 1 }}
          className="mb20"
        >
          <Transactions />
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 7, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          xl={{ span: 8, offset: 0 }}
        >
          <Row justify="center">
            <Col
              xs={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 16 }}
            >
              <PaymentMethods />
            </Col>
          </Row>
        </Col>
      </Content>
    </BillingStyle>
  );
};

export default Billing;
