import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Feed,
  GetFeedQuery,
  GetFeedQueryVariables,
  ListPaymentMethodsQuery,
  ListPaymentMethodsQueryVariables,
  PaymentMethod,
  SendRewardMutation,
} from "__generated__/graphql";
import Api from "api/api";
import { GET_FEED } from "data/queries";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import ErrorBoundary from "../components/ErrorBoundary";

type ReferralContextType = {
  referralFeed: {
    data: Feed | null;
    isLoading: boolean;
    error: Error | null;
  };
  paymentMethods: {
    data: PaymentMethod[];
    isLoading: boolean;
    error: Error | null;
  };
  reward: {
    error: Error | null;
    isLoading: boolean;
  };
  markAsSuccessful: (referralId: string) => Promise<void>;
  markAsUnsuccessful: (referralId: string) => Promise<void>;
  markAsContacted: (referralId: string) => Promise<void>;
  markAsNotContacted: (referralId: string) => Promise<void>;
  markAsBooked: (referralId: string) => Promise<void>;
  markAsNotBooked: (referralId: string) => Promise<void>;
  markAsCompleted: (referralId: string) => Promise<void>;
  markAsNotCompleted: (referralId: string) => Promise<void>;
  markAsArchived: (referralId: string) => Promise<void>;
  markAsNotArchived: (referralId: string) => Promise<void>;
  sendReward: (
    referralId: string,
    customerId: string,
    rewardAmount: number,
    paymentId: string
  ) => Promise<void>;
};

const defaultReferralContext: ReferralContextType = {
  referralFeed: {
    data: null,
    isLoading: false,
    error: null,
  },
  paymentMethods: {
    data: [],
    isLoading: false,
    error: null,
  },
  reward: {
    error: null,
    isLoading: false,
  },
  markAsSuccessful: async () => {},
  markAsUnsuccessful: async () => {},
  markAsContacted: async () => {},
  markAsNotContacted: async () => {},
  markAsBooked: async () => {},
  markAsNotBooked: async () => {},
  markAsArchived: async () => {},
  markAsNotArchived: async () => {},
  markAsCompleted: async () => {},
  markAsNotCompleted: async () => {},
  sendReward: async () => {},
};

const ReferralContext = createContext<ReferralContextType>(
  defaultReferralContext
);

const SEND_REWARD = gql`
  mutation SendReward($input: SendRewardInput!) {
    sendReward(input: $input) {
      success
      error {
        message
      }
    }
  }
`;
const LIST_PAYMENT_METHODS = gql`
  query ListPaymentMethods {
    listPaymentMethods {
      id
      lastFour
      brand
      expYear
      expMonth
    }
  }
`;

// Provider component
export const ReferralProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // states which are then exposed using "useReferral"
  // both referralFeed and paymentMethods are fetched from the server, sot they have a value, loading, and error useState hooks
  const [referralFeed, setReferralFeed] = useState<{
    data: Feed | null;
    isLoading: boolean;
    error: Error | null;
  }>({
    data: null,
    isLoading: false,
    error: null,
  });

  const [paymentMethods, setPaymentMethods] = useState<{
    data: PaymentMethod[];
    isLoading: boolean;
    error: Error | null;
  }>({
    data: [],
    isLoading: false,
    error: null,
  });

  // sending reward does not have a value
  const [sendRewardError, setSendRewardError] = useState<any>(null);
  const [sendRewardLoading, setSendRewardLoading] = useState<boolean>(false);

  //graphql queries that will be abstracted
  const [getQueryReferralFeed] = useLazyQuery<
    GetFeedQuery,
    GetFeedQueryVariables
  >(GET_FEED, {
    fetchPolicy: "no-cache",
  });
  const [sendRewardGQL] = useMutation<SendRewardMutation>(SEND_REWARD);
  const [listPaymentMethods] = useLazyQuery<
    ListPaymentMethodsQuery,
    ListPaymentMethodsQueryVariables
  >(LIST_PAYMENT_METHODS, { fetchPolicy: "no-cache" });

  // functions to fetch data. The first is for the referral feed, the second is for the payment methods. They are each followed by a useEffect to handle the async fetching
  const fetchReferralFeedData = async () => {
    setReferralFeed({
      data: null, // Clear any existing data
      isLoading: true, // Set loading to true
      error: null, // Clear any existing errors
    });
    try {
      const { data } = await getQueryReferralFeed(); // Await for fetching the feed data
      if (data) {
        setReferralFeed({
          data: data.getFeed, // Directly set the data here if it exists
          isLoading: false, // Set loading to false as we now have the data
          error: null, // Ensure any previous error is cleared
        });
      }
    } catch (error) {
      setReferralFeed({
        data: null, // No data available due to error
        isLoading: false, // Loading is done but with error
        error: error, // Set the encountered error
      });
    }
  };
  useEffect(() => {
    if (!referralFeed.isLoading) {
      fetchReferralFeedData();
    }
  }, []);
  const fetchPaymentMethodsData = async () => {
    setPaymentMethods({
      data: [], // Clear any existing data
      isLoading: true, // Set loading to true
      error: null, // Clear any existing errors
    });
    try {
      const { data } = await listPaymentMethods(); // Await the API call
      if (data && data.listPaymentMethods) {
        // Directly set all the relevant states in one go to avoid state inconsistencies
        setPaymentMethods({
          data: data.listPaymentMethods,
          isLoading: false, // Loading is complete
          error: null, // Ensure any previous errors are cleared if the call is successful
        });
      } else {
        // Handle the case where data is null or doesn't contain the expected structure
        setPaymentMethods({
          data: [],
          isLoading: false,
          error: null,
        });
      }
    } catch (error) {
      // Handle errors by setting the appropriate state
      setPaymentMethods({
        data: [], // Clear any existing data on error
        isLoading: false, // Set loading to false as the operation has ended in an error
        error: error, // Set the caught error
      });
    }
  };

  useEffect(() => {
    if (!paymentMethods.isLoading) {
      fetchPaymentMethodsData();
    }
  }, []);

  // functions to expose with the context
  async function markAsSuccessful(referralId: string) {
    await Api.markReferralAsSuccessful(referralId);
  }
  async function markAsUnsuccessful(referralId: string) {
    await Api.markReferralAsUnsuccessful(referralId);
  }

  async function markAsContacted(referralId: string) {
    await Api.markReferralAsContacted(referralId);
  }
  async function markAsNotContacted(referralId: string) {
    await Api.markReferralAsNotContacted(referralId);
  }

  async function markAsBooked(referralId: string) {
    await Api.markReferralAsBooked(referralId);
  }
  async function markAsNotBooked(referralId: string) {
    await Api.markReferralAsNotBooked(referralId);
  }
  async function markAsCompleted(referralId: string) {
    await Api.markReferralAsCompleted(referralId);
  }
  async function markAsNotCompleted(referralId: string) {
    await Api.markReferralAsNotCompleted(referralId);
  }
  async function markAsArchived(referralId: string) {
    await Api.markReferralAsArchived(referralId);
  }
  async function markAsNotArchived(referralId: string) {
    await Api.markReferralAsNotArchived(referralId);
  }

  async function sendReward(
    referralId: string,
    customerId: string,
    rewardAmount: number,
    paymentMethodId: string
  ) {
    setSendRewardLoading(true);
    try {
      await sendRewardGQL({
        variables: {
          input: {
            referralId,
            customerId,
            amount: Math.round(rewardAmount * 1.029 * 100) + 30,
            paymentMethodId,
          },
        },
      });
    } catch (error) {
      setSendRewardError(error);
    } finally {
      setSendRewardLoading(false);
    }
    fetchReferralFeedData();
  }

  const value = {
    referralFeed,
    paymentMethods,
    reward: {
      error: sendRewardError,
      isLoading: sendRewardLoading,
    },
    markAsSuccessful,
    markAsUnsuccessful,
    markAsContacted,
    markAsNotContacted,
    markAsBooked,
    markAsNotBooked,
    markAsArchived,
    markAsNotArchived,
    markAsCompleted,
    markAsNotCompleted,
    sendReward,
  };

  return (
    <ErrorBoundary>
      <ReferralContext.Provider value={value}>
        {children}
      </ReferralContext.Provider>
    </ErrorBoundary>
  );
};

// Custom hook to use the context
export const useReferral = () => {
  const context = useContext(ReferralContext);
  if (context === undefined) {
    throw new Error("useReferral must be used within a ReferralProvider");
  }
  return context;
};
