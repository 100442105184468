import React, { ReactNode } from "react";

interface TextBubbleProps {
  message: ReactNode;
  color: "gray" | "blue";
}

const TextBubble: React.FC<TextBubbleProps> = ({ message, color }) => {
  const containerStyle = {
    ...styles.container,
    alignSelf: color === "blue" ? "flex-end" : "flex-start",
    backgroundColor:
      color === "blue" ? "rgb(53, 134, 247)" : "rgb(233, 233, 235)",
    color: color === "blue" ? "#FFF" : "#000",
  };

  const messageStyle: React.CSSProperties = {
    ...styles.message,
  };

  return (
    <div style={containerStyle}>
      <div style={messageStyle}>{message}</div>
    </div>
  );
};

const styles = {
  container: {
    borderRadius: "20px",
    padding: "10px",
    marginBottom: "10px",
  },
  sender: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  message: {
    fontSize: "14px",
  },
};

export default TextBubble;
