import { useInternalUser } from "providers/InternalUserContext";
import React from "react";

const InternalUser: React.FC = () => {
  const { setIsInternalUser } = useInternalUser();
  setIsInternalUser(true);
  return <>Success!</>;
};

export default InternalUser;
