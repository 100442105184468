import { ToolTwoTone } from "@ant-design/icons";
import { Button, Col, Layout, Row, Typography } from "antd";
import { NavLink } from "react-router-dom";

export default () => {
  const { Footer } = Layout;
  const { Text } = Typography;

  return (
    <Footer style={{ background: "#fafafa" }}>
      <Row>
        <Col
          xs={24}
          md={12}
          lg={12}
        >
          <Row
            style={{ width: "100%" }}
            justify={{ xs: "center", sm: "center", md: "start" }}
          >
            <Col>
              <Text>
                © 2024, made by <Text strong>ReferPro</Text>{" "}
                <span>{<ToolTwoTone />}</span>
              </Text>
            </Col>
          </Row>
        </Col>

        <Col
          xs={24}
          md={12}
          lg={12}
        >
          <Row
            gutter={[12, 12]}
            justify={{ xs: "center", sm: "center", md: "end" }}
          >
            <Col>
              <NavLink to="/about">
                <Button type="link">About</Button>
              </NavLink>
            </Col>
            <Col>
              <NavLink to="/contact">
                <Button type="link">Contact</Button>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};
