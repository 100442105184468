import { useQuery } from "@apollo/client";
import { Form, Select } from "antd";
import { LIST_CAMPAIGN_GROUPS } from "data/queries";
import React, { useEffect, useState } from "react";
import {
  CampaignGroup as CampaignGroupGql,
  ListCampaignGroupsQuery,
} from "../__generated__/graphql";

const { Option } = Select;

const ChooseCampaignGroups: React.FC<{
  selectSize?: "small" | "middle" | "large";
  onChoose: (groupIds: string[]) => void;
  initialGroupIds?: string[];
  resetTrigger?: boolean;
}> = ({ selectSize, onChoose, resetTrigger = false, initialGroupIds = [] }) => {
  const [campaignGroupIds, setCampaignGroupIds] = useState<string[]>([]);
  const [campaignGroupData, setCampaignGroupData] =
    useState<CampaignGroupGql[]>();
  const listCampaignGroups =
    useQuery<ListCampaignGroupsQuery>(LIST_CAMPAIGN_GROUPS);
  useEffect(() => {
    if (listCampaignGroups.data) {
      setCampaignGroupData(listCampaignGroups.data.listCampaignGroups);
    }
  }, [listCampaignGroups.data]);
  useEffect(() => {
    setCampaignGroupIds([]);
  }, [resetTrigger]);

  // this restricts the group ids originally shown to the ones passed in (if any) when the data is first loaded.
  useEffect(() => {
    setCampaignGroupIds(initialGroupIds);
  }, [campaignGroupData]);

  const isCampaignGroupDataReady = !!campaignGroupData;

  return (
    <Form.Item>
      <Select
        mode="multiple"
        placeholder={
          isCampaignGroupDataReady
            ? campaignGroupData?.length > 0
              ? "Select Campaign Groups"
              : "No Campaign Groups Available"
            : "Loading Campaign Groups..."
        }
        style={{
          width: "100%",
          textAlign: "left",
        }}
        size={selectSize}
        disabled={!isCampaignGroupDataReady || campaignGroupData?.length === 0}
        onChange={(value) => {
          onChoose(value);
          setCampaignGroupIds(value);
        }}
        value={campaignGroupIds}
      >
        {isCampaignGroupDataReady && campaignGroupData?.length > 0
          ? campaignGroupData?.map((group) => (
              <Option
                key={group.id}
                value={group.id}
              >
                {group.name}
              </Option>
            ))
          : null}
      </Select>
    </Form.Item>
  );
};

export default ChooseCampaignGroups;
