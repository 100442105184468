import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Divider,
  Drawer,
  InputNumber,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  ListPaymentMethodsQuery,
  RewardType,
  SendRewardMutation,
} from "../../../__generated__/graphql";
import SearchSelect from "../../../components/searchSelect";
import { GET_FEED } from "../../../data/queries";

const { Title } = Typography;

const LIST_PAYMENT_METHODS = gql`
  query ListPaymentMethods {
    listPaymentMethods {
      id
      lastFour
      brand
      expYear
      expMonth
    }
  }
`;

const SEND_REWARD = gql`
  mutation SendReward($input: SendRewardInput!) {
    sendReward(input: $input) {
      success
      error {
        message
      }
    }
  }
`;

const SendRewardForm: React.FC<{
  referralId: string;
  referredById: string;
  onFinish: () => void;
}> = ({ referralId, referredById, onFinish }) => {
  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
  } = useQuery<ListPaymentMethodsQuery>(LIST_PAYMENT_METHODS);

  const [
    sendReward,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation<SendRewardMutation>(SEND_REWARD, {
    refetchQueries: [{ query: GET_FEED }, "GetFeedQuery"],
  });

  const [rewardAmount, setRewardAmount] = useState(50);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const readyToSubmit = paymentMethodId !== "" && rewardAmount > 0;

  if (mutationError) {
    alert("uh oh!");
  }

  const searchSelectOptions = queryLoading
    ? []
    : queryData.listPaymentMethods.map((paymentMethod) => ({
        id: paymentMethod.id,
        name: `${paymentMethod.brand} **** **** **** ${paymentMethod.lastFour}`,
      }));

  if (mutationData) {
    return (
      <Result
        status="success"
        subTitle={"Successfully sent reward!"}
        extra={[
          <Space direction="vertical">
            <Row justify="center">
              <Col flex="auto">
                <Button
                  type="primary"
                  key="done"
                  onClick={() => {
                    onFinish();
                  }}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Space>,
        ]}
      />
    );
  }

  return (
    <>
      <Space
        direction="vertical"
        size={20}
        style={{ width: "100%" }}
      >
        <Row justify="center">
          <Col span={24}>
            <Spin spinning={queryLoading}>
              <SearchSelect
                placeholder={"Select payment method"}
                options={searchSelectOptions}
                onChange={setPaymentMethodId}
              />
            </Spin>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            Reward amount:
            <InputNumber
              min={1}
              max={1000000}
              defaultValue={50}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
              onChange={(val: number) => setRewardAmount(val)}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>+ 2.9% + 30¢ Stripe Processing fee</Row>
        <Row>
          <Col span={24}>
            <Title level={5}>
              You will be charged: $
              {((Math.round(rewardAmount * 1.029 * 100) + 30) / 100).toFixed(2)}
            </Title>
          </Col>
        </Row>
        <Spin spinning={mutationLoading}>
          <Button
            size="large"
            style={{
              opacity: readyToSubmit ? 1 : 0.6,
              backgroundColor: "#15616D",
              color: "white",
              width: "100%",
            }}
            disabled={!readyToSubmit}
            onClick={() =>
              sendReward({
                variables: {
                  input: {
                    referralId: referralId,
                    customerId: referredById,
                    amount: Math.round(rewardAmount * 1.029 * 100) + 30,
                    paymentMethodId,
                    rewardType: RewardType.Tango,
                  },
                },
              })
            }
          >
            Send Reward through ReferPro
          </Button>
          <Divider>or</Divider>
          <Button
            size="large"
            style={{
              opacity: !readyToSubmit ? 1 : 0.6, // this is the opposite of the above so that the button is disabled when the other is enabled
              backgroundColor: "#15616D",
              color: "white",
              width: "100%",
            }}
            disabled={readyToSubmit}
            onClick={() =>
              sendReward({
                variables: {
                  input: {
                    referralId: referralId,
                    customerId: referredById,
                    amount: Math.round(rewardAmount * 1.029 * 100) + 30,
                    paymentMethodId,
                    rewardType: RewardType.CompanyManual,
                  },
                },
              })
            }
          >
            Mark as Rewarded Manually
          </Button>
          <Divider>or</Divider>
          <Button
            size="large"
            style={{
              opacity: !readyToSubmit ? 1 : 0.6, // this is the opposite of the above so that the button is disabled when the other is enabled
              backgroundColor: "#15616D",
              color: "white",
              width: "100%",
            }}
            disabled={readyToSubmit}
            onClick={() =>
              sendReward({
                variables: {
                  input: {
                    referralId: referralId,
                    customerId: referredById,
                    amount: Math.round(rewardAmount * 1.029 * 100) + 30,
                    paymentMethodId,
                    rewardType: RewardType.Invalid,
                  },
                },
              })
            }
          >
            Mark Customer as Invalid for a Reward
          </Button>
        </Spin>
      </Space>
    </>
  );
};

const SendRewardDrawer: React.FC<{
  referredById: string;
  referralId: string;
  visible: boolean;
  onClose: () => void;
  onFinish: () => void;
}> = ({ onClose, visible, onFinish, referralId, referredById }) => {
  return (
    <Drawer
      title="Send Reward"
      mask={true}
      width={360}
      onClose={onClose}
      open={visible}
    >
      <SendRewardForm
        referralId={referralId}
        referredById={referredById}
        onFinish={() => onFinish()}
      />
    </Drawer>
  );
};

export default SendRewardDrawer;
