import { useLazyQuery, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Col, Empty, Row, Skeleton, Space, Spin, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LIST_EMPLOYEES_PAGINATED } from "data/queries";
import React, { useEffect, useState } from "react";
import {
  Employee,
  ListEmployeesPaginatedQuery,
  ListEmployeesPaginatedQueryVariables,
} from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import SearchableTable from "../../../components/SearchableTable";
import ExpandedEmployeeRow from "./ExpandedEmployeeRow";

const { Text } = Typography;

const EmployeesTableStyle = styled.div({
  "&.employeesTableRoot": {
    ".ant-table-row:hover": {
      cursor: "pointer",
    },
  },
});

const columns: ColumnsType<Employee> = [
  {
    title: "Name",
    key: "name",
    render: (_, employee) => (
      <Space>
        <Text>
          {employee.firstName} {employee.lastName}
        </Text>
      </Space>
    ),
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    sortDirections: ["ascend", "descend"],
  },
];

const EmployeesTable: React.FC = () => {
  const [tableData, setTableDataWithKeys] = useState<any[]>([]);
  const [loadingMore, setLoadingMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [ready, setReady] = useState<boolean>(false);

  const setTableData = (data: any[]) => {
    setTableDataWithKeys(
      data.map((employee) => ({
        ...employee,
        key: employee.id,
      }))
    );
  };

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery<
    ListEmployeesPaginatedQuery,
    ListEmployeesPaginatedQueryVariables
  >(LIST_EMPLOYEES_PAGINATED, { variables: { first: 100 } });

  useEffect(() => {
    if (queryData) {
      setData(queryData);
    }
    if (queryError) {
      setError(queryError);
    }
    if (!queryLoading) {
      setLoading(false);
    }
  }, [queryData, queryError, queryLoading]);

  useEffect(() => {
    if (data && !loading && !error) {
      setReady(true);
    }
  }, [data, loading, error]);

  const [loadMoreEmployees] = useLazyQuery<
    ListEmployeesPaginatedQuery,
    ListEmployeesPaginatedQueryVariables
  >(LIST_EMPLOYEES_PAGINATED);

  useEffect(() => {
    const fetchMoreData = async (endCursor: string) => {
      let cursor = endCursor;
      let counter = 0;
      let employees: any[] = [];
      do {
        const response = await loadMoreEmployees({
          variables: { first: 500, after: cursor },
        });
        if (response.error) {
          cursor = null;
        } else if (response.data) {
          employees.push(
            ...response.data.listEmployeesWithPaginationInfo.employees
          );
          cursor = response.data.listEmployeesWithPaginationInfo.endCursor;
        }
        setTableData([
          ...data.listEmployeesWithPaginationInfo.employees,
          ...employees,
        ]);
      } while (cursor && counter < 25);
      setLoadingMore(false);
    };

    if (data?.listEmployeesWithPaginationInfo.employees) {
      setTableData(data.listEmployeesWithPaginationInfo.employees);
    }

    if (data?.listEmployeesWithPaginationInfo.endCursor) {
      fetchMoreData(data?.listEmployeesWithPaginationInfo.endCursor);
    } else {
      setLoadingMore(false);
    }
  }, [data]);

  return (
    <EmployeesTableStyle className="employeesTableRoot">
      <FloatingCard>
        {loading && <Skeleton active />}
        {error && (
          <Empty
            description="Uh oh!"
            style={{ marginTop: 20 }}
          />
        )}
        {ready && (
          <>
            <Row>
              <SearchableTable
                columns={columns}
                data={tableData}
                expandedRowRender={(record: Employee) => (
                  <ExpandedEmployeeRow employee={record} />
                )}
              />
            </Row>
            {loadingMore && (
              <Row justify="end">
                <Col>
                  <Spin
                    size="small"
                    tip={`Loaded ${tableData.length} employees `}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </FloatingCard>
    </EmployeesTableStyle>
  );
};

export default EmployeesTable;
