import axios from "axios";
import { BASE_URL } from "../constants";

interface SetUpResponse {
  accessToken: string;
}

export default class SetUp {
  static async call(
    userId: string,
    password: string
  ): Promise<SetUpResponse | null> {
    try {
      const response = await axios.post<SetUpResponse>(`${BASE_URL}/setup`, {
        userId,
        password,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }
}
