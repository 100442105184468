import { notification } from "antd";

const Toast = (
  type: "success" | "info" | "warning" | "error",
  title: string,
  description?: string
) => {
  notification[type]({
    message: title,
    description,
  });
};

export default Toast;
