import { Card, Col, Row } from "antd";
import EmployeeCreateForm from "components/EmployeeCreateForm";
import { useCompany } from "providers/CompanyContext";
import React from "react";
import Content from "../../layout/Content";
import EmployeeStyles from "./EmployeeStyles";
import { EmployeesTable } from "./components";
import EmployeeLeaderboard from "./components/EmployeeLeaderboard";
import GeneralEmployeeQRCode from "./components/GeneralEmployeeQRCodeDisplay";

interface Props {}

const Employees: React.FC<Props> = () => {
  const { company } = useCompany();
  return (
    <EmployeeStyles className="referralsStyleRoot">
      <Content>
        <Row style={{ width: "100%" }}>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 13, offset: 1 }}
            className="mb20"
            style={{ marginBottom: 10 }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col span={24}>
                <EmployeesTable />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <EmployeeLeaderboard />
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
            className="mb20"
          >
            <Row style={{ marginBottom: 10 }}>
              <Col span={24}>
                <Card
                  bordered={false}
                  className="header-solid h-full ant-list-yes"
                  style={{
                    borderRadius: 20,
                    boxShadow: "0 6px 10px rgb(0 0 0 / 12%)",
                  }}
                  title="Create New Employee"
                >
                  <EmployeeCreateForm onFinish={() => true} />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GeneralEmployeeQRCode company={company as any} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </EmployeeStyles>
  );
};

export default Employees;
