import styled from "@emotion/styled";
import { Alert, Row, Typography } from "antd";
import { Content as AntdContent } from "antd/lib/layout/layout";
import ScheduleADemo from "components/ScheduleADemo";
import React, { ReactNode } from "react";
import { useAuthorization } from "../providers/AuthorizationContext";
import { backgroundMain } from "../theme";

const ContentStyle = styled.div({
  "&.contentRoot": {
    ".top-bar": {
      height: 100,
      width: "100%",
      ...backgroundMain,
    },
    ".top-bar-overlay": {
      top: -50,
      position: "relative",
    },
    ".w100": {
      width: "100%",
    },
  },
});

interface Props {
  children: ReactNode;
}

const Home: React.FC<Props> = ({ children }) => {
  const { hasAllLinkPermissions } = useAuthorization();
  return (
    <AntdContent>
      <ContentStyle className="contentRoot">
        {!hasAllLinkPermissions && (
          <Alert
            message={
              <Typography.Text>
                You are on a free trial account. Want all the features?
                <ScheduleADemo />
              </Typography.Text>
            }
            type="info"
            showIcon
            icon={"👋"}
            closable={false}
            banner
          />
        )}
        <Row className="top-bar w100"></Row>
        <Row className="top-bar-overlay w100">{children}</Row>
      </ContentStyle>
    </AntdContent>
  );
};

export default Home;
