import { Card, Col, Row } from "antd";
import CustomerCreateForm from "components/CustomerCreateForm";
import React from "react";
import Content from "../../layout/Content";
import CustomersStyle from "./CustomersStyle";
import { CustomersTable } from "./components";

interface Props {}

const Customers: React.FC<Props> = () => {
  return (
    <CustomersStyle className="referralsStyleRoot">
      <Content>
        <Row style={{ width: "100%" }}>
          <Col
            xs={{ span: 22, offset: 1 }}
            // sm={{ span: 22, offset: 1 }}
            xl={{ span: 13, offset: 1 }}
            className="mb20"
            style={{ marginBottom: 10 }}
          >
            <CustomersTable />
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            // sm={{ span: 22, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
            className="mb20"
          >
            <Card
              bordered={false}
              className="header-solid h-full ant-list-yes"
              style={{
                borderRadius: 20,
                boxShadow: "0 6px 10px rgb(0 0 0 / 12%)",
              }}
              title="Create New Customer"
            >
              <CustomerCreateForm
                shouldCreateNewCustomer={true}
                onFinish={() => true}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </CustomersStyle>
  );
};

export default Customers;
