import { CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import ReferralFormLayout, {
  CompanyBranding,
} from "layout/referralForm/ReferralForm";
import PosthogHandler from "lib/posthog";
import React, { useState } from "react";
import { DiscountType } from "../../../__generated__/public/graphql";
import Toast from "../../../components/Toast";
import TypingText from "../../../components/TypingText";
import PhoneInput from "./PhoneInput";
const { Text } = Typography;

const ShareReferralForm: React.FC<{
  companyId: string;
  companyName: string;
  firstName: string;
  saveCustomerReferral: (name: string, phone: string) => void;
  discountAmount: number;
  discountDescription: string;
  discountType: DiscountType;
  customDiscountMessage?: string;
  companyBranding?: CompanyBranding;
}> = ({
  companyId,
  companyName,
  firstName,
  saveCustomerReferral,
  discountAmount,
  discountDescription,
  discountType,
  customDiscountMessage,
  companyBranding,
}) => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isSavingReferral, setIsSavingReferral] = useState<boolean>(false);

  const isNameValid = () => name.length > 0;
  const isPhoneValid = () => phone.length === 10;

  const isReadyToSubmit = isNameValid() && isPhoneValid();

  if (companyBranding.alternateCompanyName) {
    companyName = companyBranding.alternateCompanyName;
  }

  const onFinish = async () => {
    try {
      setIsSavingReferral(true);
      PosthogHandler.capture("clicked_submit");
      PosthogHandler.capture("submit_referral");
      PosthogHandler.capture("share_referral_form_submit", {
        linkTarget: window.location.href,
        companyId,
        name,
        phone,
      });
      await saveCustomerReferral(name, phone);
      Toast("success", "Success saving referral");
      // Pause for a second
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Redirect to thank you page
      window.location.href = `${window.location.href}/thank-you`;
    } catch (e) {
      Toast("error", "Error saving referral");
    } finally {
      setIsSavingReferral(false);
    }
  };

  const discount = customDiscountMessage ? (
    <Text style={{ fontWeight: 200, fontSize: "20px" }}>
      {customDiscountMessage}
    </Text>
  ) : (
    <Text style={{ fontWeight: 200, fontSize: "20px" }}>
      Enter your info below and receive a{" "}
      {discountType === DiscountType.Amount && (
        <>
          <Text
            style={{
              fontWeight: 400,
              fontSize: "20px",
            }}
          >
            ${discountAmount} coupon
          </Text>
        </>
      )}
      {discountType === DiscountType.Description && (
        <Text
          style={{
            fontWeight: 400,
            fontSize: "20px",
          }}
        >
          {discountDescription}
        </Text>
      )}{" "}
      from{" "}
      <Text
        style={{
          fontWeight: 400,
          fontSize: "20px",
        }}
      >
        {companyName}
      </Text>
      !
    </Text>
  );

  return (
    <ReferralFormLayout
      companyId={companyId}
      companyBranding={companyBranding}
    >
      <Row
        justify="center"
        style={{ marginBottom: 10 }}
      >
        <Col>
          <Text style={{ fontWeight: 200, fontSize: "24px" }}>
            <Row>
              <Space direction="horizontal">
                <Col>
                  <TypingText
                    text={`Hi! Thanks for using ${firstName}'s link!`}
                    duration={40}
                    style={{ fontWeight: 200, fontSize: "24px" }}
                  />
                </Col>
                <Col>
                  <div
                    style={{ fontWeight: 200, fontSize: "24px" }}
                    className="waving-hand"
                  >
                    👋
                  </div>
                </Col>
              </Space>
            </Row>
          </Text>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col>
          <Form
            name="materialForm"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              className="appear-from-top"
              name="name"
              label={discount}
            >
              <Input
                autoFocus
                size="large"
                prefix={
                  isNameValid() ? (
                    <UserOutlined style={{ color: "#008000e3" }} />
                  ) : (
                    <UserOutlined />
                  )
                }
                placeholder="Enter your name"
                style={{
                  ...(isNameValid() && {
                    borderColor: "#008000e3",
                  }),
                  background: "transparent",
                }}
                onChange={(e) => {
                  setName(e.target.value);
                  PosthogHandler.capture("share_referral_form_name_input", {
                    linkTarget: window.location.href,
                    companyId,
                    name: e.target.value,
                  });
                }}
                suffix={
                  isNameValid() ? (
                    <CheckCircleOutlined style={{ color: "#008000e3" }} />
                  ) : undefined
                }
              />
            </Form.Item>

            <Form.Item
              className="appear-from-top-2"
              name="phone"
              label={
                <Text style={{ fontWeight: 200, fontSize: "20px" }}>
                  And what is your phone number?
                </Text>
              }
            >
              <PhoneInput
                disabled={!isNameValid()}
                onPhoneNumberChange={(value) => {
                  setPhone(value);
                  PosthogHandler.capture("share_referral_form_phone_input", {
                    linkTarget: window.location.href,
                    companyId,
                    phone: value,
                  });
                }}
                placeholder="Enter your phone number"
              />
            </Form.Item>

            <Form.Item className="appear-from-top-3">
              <Button
                className={isReadyToSubmit ? "sparkle-button" : ""}
                type="default"
                size="large"
                style={{
                  boxShadow: isReadyToSubmit
                    ? "rgb(0 0 0 / 30%) 5px 7px 10px"
                    : "",
                  borderRadius: "5px",
                  width: "80%",
                  display: "block",
                  margin: "0 auto",
                }}
                onClick={onFinish}
                disabled={!isReadyToSubmit}
                loading={isSavingReferral}
              >
                <Text strong={true}>Submit</Text>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </ReferralFormLayout>
  );
};

export default ShareReferralForm;
