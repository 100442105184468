import {
  FacebookOutlined,
  InstagramOutlined,
  LinkOutlined,
  MessageOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import NextDoorOutlined from "assets/icons/NextDoorOutlined";
import { CREATE_SHAREABLE_LINK } from "data/publicQueries";
import PosthogHandler from "lib/posthog";
import React, { useEffect, useRef, useState } from "react";

const { Text } = Typography;

const ShareLinkOptions: React.FC<{
  referralLinkId: string;
  companyName: string;
}> = ({ referralLinkId, companyName }) => {
  const inputRef = useRef(null);

  const [buttonSize, setButtonSize] = useState("50px");
  useEffect(() => {
    const adjustButtonSize = () => {
      if (window.innerWidth < 420) {
        setButtonSize("40px");
      } else {
        setButtonSize("50px");
      }
    };
    adjustButtonSize();
    window.addEventListener("resize", adjustButtonSize);
  }, []);

  const [
    socialMediaInstructionModalVisible,
    setSocialMediaInstructionModalVisible,
  ] = useState(false);

  const [
    socialMediaInstructionModelTitle,
    setSocialMediaInstructionModelTitle,
  ] = useState("");

  const [
    socialMediaInstructionModalContent,
    setSocialMediaInstructionModalContent,
  ] = useState(<></>);

  const [
    socialMediaInstructionConfirmAction,
    setSocialMediaInstructionConfirmAction,
  ] = useState(() => async () => {});

  const textMessageInstructionContent = (
    <Space direction="vertical">
      <Text>
        We're about to open your messaging app with your link ready to share!
      </Text>
      <Text>
        An example message has been filled in, but feel free to edit it and make
        it your own.
      </Text>
      <Text>
        Click OK to continue to your messaging app, or Cancel to stay on this
        page.
      </Text>
    </Space>
  );
  const textMessageInstructionTitle = "Gather referrals through text message!";

  const [textMessageUrl, setTextMessageUrl] = useState<string | null>(null);

  const textMessageConfirmAction = async () => {
    if (!textMessageUrl) {
      return;
    }
    PosthogHandler.capture("text_message_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = textMessageUrl;
  };

  const facebookInstructionContent = (
    <Space direction="vertical">
      <Text>We're about to open Facebook with your link ready to share!</Text>
      <Text>
        An example post has been copied to your clipboard, but feel free to edit
        it and make it your own.
      </Text>
      <Text>
        Click OK to continue to Facebook, or Cancel to stay on this page.
      </Text>
    </Space>
  );
  const facebookInstructionTitle = "Gather referrals through Facebook!";

  const [facebookUrl, setFacebookUrl] = useState<string | null>(null);

  const facebookConfirmAction = async () => {
    if (!facebookUrl) {
      return;
    }
    PosthogHandler.capture("facebook_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = facebookUrl;
  };

  const twitterInstructionContent = (
    <Space direction="vertical">
      <Text>We're about to open Twitter with your link ready to share!</Text>
      <Text>
        An example tweet has been filled in, but feel free to edit it and make
        it your own.
      </Text>
      <Text>
        Click OK to continue to Twitter, or Cancel to stay on this page.
      </Text>
    </Space>
  );

  const twitterInstructionTitle = "Gather referrals through Twitter!";

  const [twitterUrl, setTwitterUrl] = useState<string | null>(null);

  const twitterConfirmAction = async () => {
    if (!twitterUrl) {
      return;
    }
    PosthogHandler.capture("twitter_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = twitterUrl;
  };

  const instagramInstructionContent = (
    <Space direction="vertical">
      <Text>We're about to open Instagram!</Text>
      <Text>
        We've copied an example post with your custom referral link to your
        clipboard. Paste it into an Instagram post and make it your own!
      </Text>
      <Text>
        Click OK to continue to Instagram, or Cancel to stay on this page.
      </Text>
    </Space>
  );
  const instagramInstructionTitle = "Gather referrals through Instagram!";
  const instagramUrl = "https://www.instagram.com/";
  const instagramConfirmAction = async () => {
    PosthogHandler.capture("instagram_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = instagramUrl;
  };

  const nextdoorInstructionContent = (
    <Space direction="vertical">
      <Text>We're about to open Nextdoor!</Text>
      <Text>
        We've copied an example post with your custom referral link to your
        clipboard. Paste it into a Nextdoor post and make it your own!
      </Text>
      <Text>
        Click OK to continue to Nextdoor, or Cancel to stay on this page.
      </Text>
    </Space>
  );

  const nextdoorInstructionTitle = "Gather referrals through Nextdoor!";
  const nextdoorUrl = "https://nextdoor.com/news_feed/";
  const nextdoorConfirmAction = async () => {
    PosthogHandler.capture("nextdoor_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = nextdoorUrl;
  };

  const [readyToShare, setReadyToShare] = useState(false);

  const [createShareableLink] = useMutation(CREATE_SHAREABLE_LINK);

  const shareableLink = async () => {
    const shareableLink = await createShareableLink({
      variables: {
        input: {
          referralLinkId,
        },
      },
    });
    return shareableLink.data?.createShareableLink.link;
  };

  const handleOpeniMessage = async () => {
    PosthogHandler.capture("text_message_share_clicked", {
      linkTarget: window.location.href,
    });
    setReadyToShare(false);
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(textMessageInstructionContent);
    setSocialMediaInstructionModelTitle(textMessageInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();
    const recipient = "phone_number"; // Replace with the recipient's phone number
    const message = `Hey! Here is my referral link for ${companyName}: ${linkTarget}`;
    const encodedMessage = encodeURIComponent(message);

    setTextMessageUrl(`sms:${recipient}&body=${encodedMessage}`);
  };

  const handleOpenFacebook = async () => {
    PosthogHandler.capture("facebook_share_clicked", {
      linkTarget: window.location.href,
    });
    setReadyToShare(false);
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(facebookInstructionContent);
    setSocialMediaInstructionModelTitle(facebookInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();
    setFacebookUrl(
      `https://www.facebook.com/sharer/sharer.php?u=${linkTarget}`
    );
    await navigator.clipboard.writeText(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );
  };

  const handleOpenTwitter = async () => {
    PosthogHandler.capture("twitter_share_clicked", {
      linkTarget: window.location.href,
    });
    setReadyToShare(false);
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(twitterInstructionContent);
    setSocialMediaInstructionModelTitle(twitterInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();
    const tweetText = encodeURIComponent(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );
    setTwitterUrl(`https://twitter.com/intent/tweet?text=${tweetText}`);
  };

  useEffect(() => {
    setSocialMediaInstructionConfirmAction(() => facebookConfirmAction);
    setReadyToShare(true);
  }, [facebookUrl]);

  useEffect(() => {
    setSocialMediaInstructionConfirmAction(() => twitterConfirmAction);
    setReadyToShare(true);
  }, [twitterUrl]);

  useEffect(() => {
    setSocialMediaInstructionConfirmAction(() => textMessageConfirmAction);
    setReadyToShare(true);
  }, [textMessageUrl]);

  const handleOpenInstagram = async () => {
    PosthogHandler.capture("instagram_share_clicked", {
      linkTarget: window.location.href,
    });
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(instagramInstructionContent);
    setSocialMediaInstructionModelTitle(instagramInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();

    await navigator.clipboard.writeText(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );
    setSocialMediaInstructionConfirmAction(() => instagramConfirmAction);
    setReadyToShare(true);
  };

  const handleOpenNextDoor = async () => {
    PosthogHandler.capture("nextdoor_share_clicked", {
      linkTarget: window.location.href,
    });
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(nextdoorInstructionContent);
    setSocialMediaInstructionModelTitle(nextdoorInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();

    await navigator.clipboard.writeText(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );
    setSocialMediaInstructionConfirmAction(() => nextdoorConfirmAction);
    setReadyToShare(true);
  };

  const handleLinkCopy = async () => {
    PosthogHandler.capture("shareable_link_copied", {
      linkTarget: window.location.href,
    });
    const linkTarget = await shareableLink();

    navigator.clipboard
      .writeText(linkTarget)
      .then(() => {
        message.success(`Copied link ${linkTarget} to clipboard`);
      })
      .catch(() => {
        // Try this way if the above fails
        if (inputRef.current) {
          inputRef.current.select();
          document.execCommand(`Copied link ${linkTarget} to clipboard`);
          message.success(`Copied link ${linkTarget} to clipboard`);
          return;
        }
        message.error("Failed to copy to clipboard");
      });
  };

  return (
    <Space
      direction="vertical"
      size="large"
    >
      <Modal
        open={socialMediaInstructionModalVisible}
        title={socialMediaInstructionModelTitle}
        onCancel={() => setSocialMediaInstructionModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setSocialMediaInstructionModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            disabled={!readyToShare}
            key="ok"
            type="primary"
            onClick={socialMediaInstructionConfirmAction}
          >
            OK
          </Button>,
        ]}
      >
        {socialMediaInstructionModalContent}
      </Modal>
      <Row
        justify="center"
        gutter={[8, 8]}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col>
          <Button
            size="large"
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
              height: buttonSize,
              width: buttonSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleOpeniMessage}
          >
            <Space direction="horizontal">
              <Col style={{ fontSize: 20 }}>
                <Input style={{ display: "none" }} />
                <MessageOutlined />
              </Col>
            </Space>
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
              height: buttonSize,
              width: buttonSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleOpenFacebook}
          >
            <Space direction="horizontal">
              <Col style={{ fontSize: 20 }}>
                <Input style={{ display: "none" }} />
                <FacebookOutlined />
              </Col>
            </Space>
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
              height: buttonSize,
              width: buttonSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleOpenTwitter}
          >
            <Space direction="horizontal">
              <Col style={{ fontSize: 20 }}>
                <Input style={{ display: "none" }} />
                <TwitterOutlined />
              </Col>
            </Space>
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
              height: buttonSize,
              width: buttonSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleOpenInstagram}
          >
            <Space direction="horizontal">
              <Col style={{ fontSize: 20 }}>
                <Input style={{ display: "none" }} />
                <InstagramOutlined />
              </Col>
            </Space>
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
              height: buttonSize,
              width: buttonSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleOpenNextDoor}
          >
            <Space direction="horizontal">
              <Col style={{ fontSize: 20 }}>
                <Input style={{ display: "none" }} />
                <NextDoorOutlined />
              </Col>
            </Space>
          </Button>
        </Col>
      </Row>
      <Row justify="center">
        <Button
          size="large"
          style={{
            height: "50px",
            cursor: "pointer",
            boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
            paddingLeft: 25,
            paddingRight: 25,
            width: "100%",
          }}
          onClick={handleLinkCopy}
        >
          <Space direction="horizontal">
            <Col style={{ fontSize: 20 }}>
              <Input
                style={{ display: "none" }}
                ref={inputRef}
              />
              <LinkOutlined />
            </Col>
            <Col
              style={{
                height: "30px",
                lineHeight: "30px",
                fontWeight: 200,
                fontSize: "20px",
              }}
            >
              Copy Link to Share
            </Col>
          </Space>
        </Button>
      </Row>
    </Space>
  );
};

export default ShareLinkOptions;
