import "antd/dist/reset.css";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Billing from "./pages/Billing/Billing";
import Home from "./pages/Home/Home";
import SetUp from "./pages/SetUp/SetUp";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";

import { useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import Employees from "pages/Employees/Employees";
import MetricsDashboard from "pages/Metrics/Metrics";
import {
  CoreUserFieldsFragment,
  GetLoggedInUserQuery,
} from "./__generated__/graphql";
import ErrorBoundary from "./components/ErrorBoundary";
import {
  REFERPRO_PERMISSIONS,
  REFERPRO_SIGNED_IN_USER,
  REFERPRO_TOKEN_NAME,
  REFERPRO_USER_ID,
} from "./constants";
import { GET_LOGGED_IN_USER } from "./data/queries";
import Footer from "./layout/Footer";
import { SignedInHeader, SignedOutHeader } from "./layout/Header";
import MobileSider from "./layout/MobileSider";
import Sider from "./layout/Sider";
import Campaigns from "./pages/Campaigns/Campaigns";
import Customers from "./pages/Customers/Customers";
import Landing from "./pages/Landing/Landing";
import Referrals from "./pages/Referrals/Referrals";
import CompanySettings from "./pages/Settings/CompanySettings";
import ReferralSettings from "./pages/Settings/ReferralSettings";
import { useAuthentication } from "./providers/AuthenticationContext";
import { useAuthorization } from "./providers/AuthorizationContext";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem(REFERPRO_TOKEN_NAME)
  );

  const history = useHistory();

  const [initialFetch, setInitialFetch] = useState(false);

  const [loadLoggedInUser, { data, loading }] =
    useLazyQuery<GetLoggedInUserQuery>(GET_LOGGED_IN_USER, {
      fetchPolicy: "no-cache",
    });

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const {
    getAccessTokenSilently,
    logout: auth0Logout,
    user: auth0User,
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
  } = useAuth0();

  const { signinWithAuth0Token, signedInUser } = useAuthentication();
  const { refreshPermissions } = useAuthorization();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const maybeLoggedInUser = data?.getLoggedInUser
      ? (data?.getLoggedInUser as any as CoreUserFieldsFragment)
      : null;
    if (maybeLoggedInUser) {
      localStorage.setItem(REFERPRO_USER_ID, maybeLoggedInUser.id);
    }
    if (maybeLoggedInUser?.permissions) {
      refreshPermissions(maybeLoggedInUser.permissions);
    }

    if (params.jwt) {
      setReferProToken(params.jwt);
    }

    if (!isAuth0Loading && isAuth0Authenticated && auth0User) {
      getAccessTokenSilently().then(async (token) => {
        const jwt = await signinWithAuth0Token(token);
        setIsLoggedIn(true);
        history.push("/app");
      });
    }
  }, [data, auth0User, isAuth0Authenticated, isAuth0Loading]);

  if (isLoggedIn && !initialFetch) {
    loadLoggedInUser();
    setInitialFetch(true);
  }

  const setReferProToken = (token) => {
    localStorage.setItem(REFERPRO_TOKEN_NAME, token);
    setIsLoggedIn(true);
    loadLoggedInUser();
  };

  const handleLogout = () => {
    localStorage.removeItem(REFERPRO_TOKEN_NAME);
    localStorage.removeItem(REFERPRO_USER_ID);
    localStorage.removeItem(REFERPRO_PERMISSIONS);
    localStorage.removeItem(REFERPRO_SIGNED_IN_USER);

    setIsLoggedIn(false);
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    window.location.href = "/";
  };

  const renderLoggedInPage = (content) => {
    return (
      <ErrorBoundary>
        {!isSmallScreen && <Sider handleLogout={handleLogout} />}
        <Content>
          {isSmallScreen && <MobileSider handleLogout={handleLogout} />}
          {content}
        </Content>
      </ErrorBoundary>
    );
  };

  const renderLoggedOutPage = (content) => {
    return <Content>{content}</Content>;
  };

  const LoggedOutApp = () => (
    <>
      <Route
        path="/sign-up"
        exact
        component={() =>
          renderLoggedOutPage(<SignUp setReferProToken={setReferProToken} />)
        }
      />
      <Route
        path="/sign-in"
        exact
        component={() =>
          renderLoggedOutPage(<SignIn setReferProToken={setReferProToken} />)
        }
      />
      <Route
        path="/setup"
        exact
        component={() =>
          renderLoggedOutPage(<SetUp setReferProToken={setReferProToken} />)
        }
      />
      <Route
        exact
        path="/auth0"
        component={() =>
          renderLoggedInPage(
            <Row
              justify="center"
              style={{ marginTop: 160 }}
            >
              <Spin size="large" />
            </Row>
          )
        }
      />
      <Route
        exact
        path="/"
        component={() => renderLoggedOutPage(<Landing />)}
      />
    </>
  );

  const LoggedInApp = () => {
    return (
      <ErrorBoundary>
        {!isSmallScreen && <Sider handleLogout={handleLogout} />}
        <Content>
          {isSmallScreen && <MobileSider handleLogout={handleLogout} />}
          <Route
            exact
            path="/app"
          >
            <Home />
          </Route>
          <Route
            exact
            path="/referrals"
          >
            <Referrals />
          </Route>
          <Route
            exact
            path="/customers"
          >
            <Customers />
          </Route>
          <Route
            exact
            path="/employees"
          >
            <Employees />
          </Route>
          <Route
            exact
            path="/campaigns"
          >
            <Campaigns />
          </Route>
          <Route
            exact
            path="/company-settings"
          >
            <CompanySettings />
          </Route>
          <Route
            exact
            path="/referral-settings"
          >
            <ReferralSettings />
          </Route>
          <Route
            exact
            path="/metrics"
          >
            <MetricsDashboard />
          </Route>
          <Route
            exact
            path="/billing"
          >
            <Billing />
          </Route>
          {/* <Route
            exact
            path="/upgrade"
          ></Route> */}
          <Route
            exact
            path="/auth0"
          >
            <Row justify="center">
              <Spin size="large" />
            </Row>
          </Route>
          <Redirect
            from="*"
            to="/app"
          />
        </Content>
      </ErrorBoundary>
    );
  };

  if (loading) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        {isLoggedIn ? <SignedInHeader /> : <SignedOutHeader />}
        <Layout>
          <Content style={{ marginTop: 180 }}>
            <Row justify="center">
              <Spin size="large" />
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          {isLoggedIn && (
            <>
              <SignedInHeader />
              <Layout
                className="ant-layout-has-sider"
                style={{ minHeight: "90vh" }}
              >
                <LoggedInApp />
              </Layout>
            </>
          )}
          {!isLoggedIn && <LoggedOutApp />}
          <Footer />
        </Switch>
      </Layout>
    </>
  );
}
