import {
  EditOutlined,
  FileImageOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import Api from "api/api";
import FloatingCard from "components/FloatingCard";
import Toast from "components/Toast";
import { useCompany } from "providers/CompanyContext";
import React, { useEffect } from "react";

const WIDTH = "100px";
const DEFAULT_HEADER_COLOR = "#001529";
const DEFAULT_BANNER_COLOR = "#1677ff";
const DEFAULT_LOGO_URL = "https://referpro.co/bluelogo.png";

const { Title } = Typography;
const CompanyBranding: React.FC = () => {
  const { company, loading, updateCompany } = useCompany();
  // place holders for now
  const [logoUrl, setLogoUrl] = React.useState(DEFAULT_LOGO_URL);
  const [headerBackgroundColor, setHeaderBackgroundColor] =
    React.useState(DEFAULT_HEADER_COLOR);
  const [bannerBackgroundColor, setBannerBackgroundColor] =
    React.useState(DEFAULT_BANNER_COLOR);
  const [editMode, setEditMode] = React.useState(false);
  useEffect(() => {
    if (!loading) {
      setHeaderBackgroundColor(
        company.settings?.referral.headerBackgroundColor || ""
      );
      setBannerBackgroundColor(
        company.settings?.referral.bannerBackgroundColor || ""
      );
      setLogoUrl(company.settings?.referral.logoUrl || "");
    }
  }, [company]);
  function onEditClick(company: any) {
    setEditMode(true);
    setEditedData({
      headerBackgroundColor:
        company.settings?.referral.headerBackgroundColor || "",
      bannerBackgroundColor:
        company.settings?.referral.bannerBackgroundColor || "",
    });
  }
  async function onSaveClick() {
    try {
      const companyReferralSettings = {
        headerBackgroundColor: editedData.headerBackgroundColor,
        bannerBackgroundColor: editedData.bannerBackgroundColor,
      };

      await updateCompany({
        settings: { referral: companyReferralSettings },
      });
      setEditMode(false);
      setEditedData({
        headerBackgroundColor: "",
        bannerBackgroundColor: "",
      });
      Toast("success", "Update success!");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      Toast("error", "Error saving");
    }
    setEditMode(false);
  }
  function onCancelClick() {
    setEditMode(false);
    setEditedData({
      headerBackgroundColor: "",
      bannerBackgroundColor: "",
    });
  }

  const onInputChange = (
    field: "headerBackgroundColor" | "bannerBackgroundColor",
    value: string
  ) => {
    switch (field) {
      case "headerBackgroundColor":
        setEditedData({
          ...editedData,
          headerBackgroundColor: value,
        });
        break;
      case "bannerBackgroundColor":
        setEditedData({
          ...editedData,
          bannerBackgroundColor: value,
        });
        break;
      default:
        break;
    }
  };

  const [editedData, setEditedData] = React.useState<{
    headerBackgroundColor: string;
    bannerBackgroundColor: string;
  }>({
    headerBackgroundColor: "",
    bannerBackgroundColor: "",
  });

  return (
    <FloatingCard
      title={
        <Title
          level={3}
          style={{ marginTop: 12 }}
        >
          <Row gutter={[10, 10]}>
            <Col>
              <Avatar icon={<FileImageOutlined />} />
            </Col>
            <Col style={{ paddingTop: 7 }}>Company Branding</Col>
          </Row>
        </Title>
      }
      extra={
        <>
          {editMode ? (
            <Space direction="horizontal">
              <Button
                type="primary"
                onClick={onSaveClick}
              >
                Save
              </Button>
              <Button onClick={onCancelClick}>Cancel</Button>
            </Space>
          ) : (
            <Button
              style={{
                marginLeft: "20px",
                alignItems: "center",
                display: "flex",
              }}
              type="ghost"
              onClick={() => onEditClick(company)}
            >
              <EditOutlined style={{ fontSize: "20px" }} /> Edit
            </Button>
          )}
        </>
      }
    >
      <Row
        gutter={10}
        justify="start"
      >
        <Col>
          <Row>
            <Title level={5}>Company Logo</Title>
          </Row>
          <Row>
            {editMode ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  onClick={async () => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*";
                    input.onchange = async (event) => {
                      const file = (event.target as HTMLInputElement).files[0];
                      try {
                        let response = await Api.uploadCompanyLogo(
                          file,
                          company.id
                        );
                        setEditMode(false);
                        setEditedData({
                          headerBackgroundColor: "",
                          bannerBackgroundColor: "",
                        });
                        setLogoUrl(response);
                        Toast("success", "Upload success!");
                      } catch (err) {
                        // eslint-disable-next-line no-console
                        console.log(err); // for now
                        Toast("error", "Error uploading");
                      }
                    };
                    input.click();
                  }}
                  style={{
                    width: WIDTH,
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                >
                  Upload
                </Button>
                {logoUrl && (
                  <img
                    src={logoUrl || DEFAULT_LOGO_URL}
                    alt="Company Logo"
                    style={{
                      width: WIDTH,
                      height: "68px",
                      objectFit: "contain",
                      padding: "5px",
                      backgroundColor:
                        headerBackgroundColor || DEFAULT_HEADER_COLOR,
                      borderBottomRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  width: WIDTH,
                  height: WIDTH,
                  objectFit: "contain",
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor:
                    headerBackgroundColor || DEFAULT_HEADER_COLOR,
                }}
              >
                {logoUrl && (
                  <img
                    src={logoUrl || DEFAULT_LOGO_URL}
                    alt="Company Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
            )}
          </Row>
        </Col>
        <Col>
          <Row>
            <Title level={5}>Header Color</Title>
          </Row>
          <Row>
            {editMode ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Descriptions.Item
                  style={{ textAlign: "left" }}
                  label="Hex Color Code"
                >
                  <TextArea
                    autoSize={{ maxRows: 1 }}
                    placeholder="Nothing yet"
                    value={
                      editedData.headerBackgroundColor ??
                      company.settings?.referral.customNewReferralMessage.body
                    }
                    onChange={(e) => {
                      onInputChange("headerBackgroundColor", e.target.value);
                    }}
                    style={{
                      width: WIDTH,
                      borderBottomRightRadius: "0px",
                      borderBottomLeftRadius: "0px",
                    }}
                  />
                </Descriptions.Item>
                <div
                  style={{
                    width: WIDTH,
                    height: "68px",
                    backgroundColor:
                      headerBackgroundColor || DEFAULT_HEADER_COLOR,
                    borderBottomRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                ></div>
              </div>
            ) : (
              <div
                style={{
                  width: WIDTH,
                  height: WIDTH,
                  backgroundColor:
                    headerBackgroundColor || DEFAULT_HEADER_COLOR,
                  borderRadius: "5px",
                }}
              ></div>
            )}
          </Row>
        </Col>
        <Col>
          <Row>
            <Title level={5}>Banner Color</Title>
          </Row>
          <Row>
            {editMode ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Descriptions.Item
                  style={{ textAlign: "left" }}
                  label="Hex Color Code"
                >
                  <TextArea
                    autoSize={{ maxRows: 1 }}
                    placeholder="Nothing yet"
                    value={
                      editedData.bannerBackgroundColor ??
                      company.settings?.referral.customNewReferralMessage.body
                    }
                    onChange={(e) => {
                      onInputChange("bannerBackgroundColor", e.target.value);
                    }}
                    style={{
                      width: WIDTH,
                      borderBottomRightRadius: "0px",
                      borderBottomLeftRadius: "0px",
                    }}
                  />
                </Descriptions.Item>
                <div
                  style={{
                    width: WIDTH,
                    height: "68px",
                    backgroundColor:
                      bannerBackgroundColor || DEFAULT_BANNER_COLOR,
                    borderBottomRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                ></div>
              </div>
            ) : (
              <div
                style={{
                  width: WIDTH,
                  height: WIDTH,
                  backgroundColor:
                    bannerBackgroundColor || DEFAULT_BANNER_COLOR,
                  borderRadius: "5px",
                }}
              ></div>
            )}
          </Row>
        </Col>
      </Row>
      <Divider orientation="left">
        <Typography.Text type="secondary">
          What your customers will see
        </Typography.Text>
      </Divider>
      <Row>
        <div
          style={{
            width: "100%",
            height: "64px",
            backgroundColor: headerBackgroundColor || DEFAULT_HEADER_COLOR,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <img
            src={logoUrl || DEFAULT_LOGO_URL}
            style={{ height: "40px", paddingLeft: "50px" }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: bannerBackgroundColor || DEFAULT_BANNER_COLOR,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        />
      </Row>
    </FloatingCard>
  );
};

export default CompanyBranding;
