import { Select } from "antd";
import React from "react";
const { Option } = Select;

const SearchSelect = ({
  onChange,
  options,
  placeholder = "Search to select",
}) => (
  <Select
    showSearch
    style={{
      width: "100%",
    }}
    placeholder={placeholder}
    optionFilterProp="children"
    filterOption={(input, option) => option.children.includes(input)}
    filterSort={(optionA, optionB) =>
      optionA.children
        .toLowerCase()
        .localeCompare(optionB.children.toLowerCase())
    }
    onChange={onChange}
  >
    {options.map((option) => (
      <Option
        key={option.id}
        value={option.id}
      >
        {option.name}
      </Option>
    ))}
  </Select>
);

export default SearchSelect;
