import { RiseOutlined } from "@ant-design/icons";
import React from "react";
import Summary from "./Summary";

interface Prop {
  amount: number;
  loading?: boolean;
}

const ClickSummary: React.FC<Prop> = ({ amount, loading = false }) => {
  return (
    <Summary
      loading={loading}
      title="Clicks 30d"
      amount={amount}
      prefix={<RiseOutlined />}
    />
  );
};

export default ClickSummary;
