import { Button, Col, Form, Row, Typography } from "antd";
import PosthogHandler from "lib/posthog";
import { FC, useEffect, useState } from "react";

const { Text } = Typography;

const ReferralSubmitButtons: FC<{
  submitReferral: () => unknown;
  submitSelfReferral: () => unknown;
  isReadyToSubmitReferral?: boolean;
  isReadyToSubmitSelfReferral?: boolean;
  fadeInOffset?: number;
  isEmbedded?: boolean;
}> = ({
  submitReferral,
  submitSelfReferral,
  isReadyToSubmitReferral,
  isReadyToSubmitSelfReferral,
  fadeInOffset = 0,
  isEmbedded = false,
}) => {
  const [ctaTextSize, setCtaTextSize] = useState("initial");
  useEffect(() => {
    const adjustCtaTextSize = () => {
      if (window.innerWidth < 480) {
        setCtaTextSize("12px");
      } else {
        setCtaTextSize("initial");
      }
    };
    adjustCtaTextSize();
    window.addEventListener("resize", adjustCtaTextSize);
  }, []);

  const [isSavingReferral, setIsSavingReferral] = useState(false);

  const onFinishReferral = async () => {
    PosthogHandler.capture("clicked_submit");
    PosthogHandler.capture("submit_referral");
    setIsSavingReferral(true);
    await submitReferral();
    setIsSavingReferral(false);
  };

  const onFinishSelfReferral = async () => {
    PosthogHandler.capture("clicked_submit");
    PosthogHandler.capture("submit_self_referral");
    setIsSavingReferral(true);
    await submitSelfReferral();
    setIsSavingReferral(false);
  };

  return (
    <Row>
      <Col span={12}>
        <Form.Item className={`appear-from-top-${fadeInOffset}`}>
          <Button
            className={isReadyToSubmitReferral ? "sparkle-button" : ""}
            type="default"
            size="large"
            style={{
              boxShadow: isReadyToSubmitReferral
                ? "rgb(0 0 0 / 30%) 5px 7px 10px"
                : "",
              borderRadius: "5px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
            }}
            onClick={onFinishReferral}
            disabled={isReadyToSubmitReferral === false || isSavingReferral}
            loading={isSavingReferral}
          >
            <Text
              strong={isEmbedded}
              style={{ fontSize: ctaTextSize }}
            >
              Send Referral
            </Text>
          </Button>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item className={`appear-from-top-${fadeInOffset}`}>
          <Button
            className={"sparkle-button"}
            type="default"
            size="large"
            style={{
              boxShadow:
                isReadyToSubmitSelfReferral === true
                  ? "rgb(0 0 0 / 30%) 5px 7px 10px"
                  : "",
              borderRadius: "5px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
            }}
            onClick={onFinishSelfReferral}
            disabled={isReadyToSubmitSelfReferral === false || isSavingReferral}
            loading={isSavingReferral}
          >
            <Text
              strong={isEmbedded}
              style={{ fontSize: ctaTextSize }}
            >
              Request Service
            </Text>
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ReferralSubmitButtons;
