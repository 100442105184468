import { FeedGroup, GetFeedQuery } from "__generated__/graphql";
import { Col, DatePicker, Radio, Row } from "antd";
import BarChart, { SeriesData } from "components/BarChart";
import FloatingCard from "components/FloatingCard";
import React, { useEffect, useState } from "react";

enum TimeRangeType {
  MONTH,
  DAY,
  YEAR,
}

const { RangePicker } = DatePicker;

interface ReferralsBarChartProps {
  feedGroups: FeedGroup[];
}

function createBarChartData(
  feedGroups: GetFeedQuery["getFeed"]["groups"],
  timeRangeType
) {
  function formatDate(
    date: Date,
    includeMonth = true,
    includeDay = true
  ): string {
    const month = date.getMonth() + 1; // getMonth() returns 0-11, add 1 for 1-12
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of year
    let dateString = "";
    if (includeMonth) {
      dateString += month + "/";
    }
    if (includeDay) {
      dateString += day + "/";
    }
    dateString += year;
    return dateString;
  }

  let seriesData: SeriesData[] = [];
  let xAxisLabels: string[] = [];
  let referralsInTimeRange: number[] = [];
  let currentTimeRangeReferrals = 0;
  let currentTimeRange: Date | null = null;
  // go through the feedGroups in reverse order to display the oldest data on the left and newest data on the right
  if (feedGroups.length === 0) {
    return { seriesData, xAxisLabels };
  }
  for (let i = feedGroups.length - 1; i >= 0; i--) {
    let feedGroup = feedGroups[i];
    let date = new Date(feedGroup.date);
    let referralAmount = 0;
    switch (timeRangeType) {
      case TimeRangeType.MONTH:
        // if the month has changed, add the current month's referral count to the list and reset the count
        if (
          currentTimeRange === null ||
          date.getMonth() !== currentTimeRange.getMonth()
        ) {
          if (currentTimeRange !== null) {
            xAxisLabels.push(formatDate(currentTimeRange, true, false));
            referralsInTimeRange.push(currentTimeRangeReferrals);
          }
          currentTimeRangeReferrals = 0;
          currentTimeRange = date;
        }
        referralAmount = feedGroup.entries.length;
        currentTimeRangeReferrals += referralAmount;
        break;
      case TimeRangeType.DAY:
        if (
          currentTimeRange === null ||
          date.getDate() !== currentTimeRange.getDate()
        ) {
          if (currentTimeRange !== null) {
            xAxisLabels.push(formatDate(currentTimeRange));
            referralsInTimeRange.push(currentTimeRangeReferrals);
          }
          currentTimeRangeReferrals = 0;
          currentTimeRange = date;
        }
        referralAmount = feedGroup.entries.length;
        currentTimeRangeReferrals += referralAmount;
        break;
      case TimeRangeType.YEAR:
        if (
          currentTimeRange === null ||
          date.getFullYear() !== currentTimeRange.getFullYear()
        ) {
          if (currentTimeRange !== null) {
            xAxisLabels.push(formatDate(currentTimeRange, false, false));
            referralsInTimeRange.push(currentTimeRangeReferrals);
          }
          currentTimeRangeReferrals = 0;
          currentTimeRange = date;
        }
        referralAmount = feedGroup.entries.length;
        currentTimeRangeReferrals += referralAmount;
        break;
      default:
    }
  }
  switch (timeRangeType) {
    case TimeRangeType.MONTH:
      xAxisLabels.push(formatDate(currentTimeRange as Date, true, false));
      referralsInTimeRange.push(currentTimeRangeReferrals);
      break;
    case TimeRangeType.DAY:
      xAxisLabels.push(formatDate(currentTimeRange as Date));
      referralsInTimeRange.push(currentTimeRangeReferrals);
      break;
    case TimeRangeType.YEAR:
      xAxisLabels.push(formatDate(currentTimeRange as Date, false, false));
      referralsInTimeRange.push(currentTimeRangeReferrals);
      break;
    default:
  }

  seriesData.push({
    name: "Referrals",
    data: referralsInTimeRange,
    itemStyle: {
      color: "#1890ff",
    },
  });
  return { seriesData, xAxisLabels };
}

const ReferralsBarChart: React.FC<ReferralsBarChartProps> = ({
  feedGroups,
}) => {
  const [seriesData, setSeriesData] = useState<SeriesData[]>([]);
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([]);
  const [timeRangeType, setTimeRangeType] = useState<TimeRangeType>(
    TimeRangeType.MONTH
  );
  useEffect(() => {
    const { seriesData, xAxisLabels } = createBarChartData(
      feedGroups,
      timeRangeType
    );
    setSeriesData(seriesData);
    setXAxisLabels(xAxisLabels);
  }, [feedGroups, timeRangeType]);

  if (feedGroups.length === 0) {
    return (
      <FloatingCard title="Number of Referrals over Time">
        <Row justify="center">
          <Col flex="auto">
            <p>No data available</p>
          </Col>
        </Row>
      </FloatingCard>
    );
  }
  return (
    <FloatingCard title="Number of Referrals over Time">
      <Row justify="center">
        <Col flex="auto">
          <BarChart
            id="test id"
            seriesData={seriesData}
            xAxisLabels={xAxisLabels}
          />
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
      >
        <span style={{ paddingRight: ".5em" }}>Group by: </span>
        <Radio.Group
          onChange={(e) => setTimeRangeType(e.target.value)}
          value={timeRangeType}
        >
          <Radio value={TimeRangeType.MONTH}>Month</Radio>
          <Radio value={TimeRangeType.DAY}>Day</Radio>
          <Radio value={TimeRangeType.YEAR}>Year</Radio>
        </Radio.Group>
      </Row>
    </FloatingCard>
  );
};

export default ReferralsBarChart;
