import { EditOutlined, ShareAltOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useState } from "react";
import { Company } from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import Toast from "../../../components/Toast";
import { useCompany } from "../../../providers/CompanyContext";
import TextBubble from "./TextBubble";
const { Text } = Typography;

const { Paragraph, Title } = Typography;
const { TextArea } = Input;

const NewReferralMessageStyle = styled.div({
  "&.newReferralMessageRoot": {
    width: "100%",
    ".custom-description-item-value": {
      display: "inline-flex",
    },
    ".shaded-box": {
      backgroundColor: "#ffecd1" /* Adjust the background color as needed */,
      padding: "2px 4px",
      borderRadius: "8px",
    },
  },
});

const renderTextMessage = (company: Company) => {
  // Replace all the \n
  const bodyParts = company.settings.referral.customNewReferralMessage.body
    .replace(/\$firstName/g, "John")
    .replace(/\$companyName/g, company.name)
    .replace(/\$referralFirstName/g, "John")
    .replace(/\$referredByFirstName/g, "Julie")
    .replace(/\$companyName/g, company.name)
    .replace(/\$companyPhoneNumber/g, company.phone)
    .replace(/\$link/g, "LINK GOES HERE")
    .split("\n")
    .map((part, index) => (
      <Paragraph
        key={index}
        style={{ color: "white", marginBottom: 0 }}
      >
        {part}
      </Paragraph>
    ));

  const message = (
    <>
      <Paragraph style={{ color: "white", marginBottom: 0 }}>
        {(company.settings.referral.customNewReferralMessage.greeting || "")
          .replace(/\$firstName/g, "John")
          .replace(/\$companyName/g, company.name)
          .replace(/\$referralFirstName/g, "John")
          .replace(/\$referredByFirstName/g, "Julie")
          .replace(/\$companyName/g, company.name)
          .replace(/\$companyPhoneNumber/g, company.phone)}
      </Paragraph>
      <Paragraph />
      {bodyParts}
      <Paragraph />
    </>
  );

  return (
    <div
      style={{ background: "rgb(255, 255, 255)", padding: 10, borderRadius: 5 }}
    >
      <Row>
        <TextBubble
          message={
            <Paragraph style={{ color: "black", marginBottom: 0 }}>
              This is what a newly referred person sees ...
            </Paragraph>
          }
          color="gray"
        />
      </Row>
      <Row>
        <Col offset={4}>
          <TextBubble
            message={message}
            color="blue"
          />
        </Col>
      </Row>
    </div>
  );
};

const NewReferralMessage: React.FC = () => {
  const { company, updateCompany } = useCompany();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [enableNewReferralMessage, setEnableNewReferralMessage] =
    useState<boolean>(
      !!company.settings?.referral.customNewReferralMessage.enabled
    );
  const [editedData, setEditedData] = useState<any>({});

  const onEditClick = (company: Company) => {
    setEditMode(true);
    setEditedData(company);
  };

  const getReferralSettings = () => {
    const result = {};

    if (
      editedData.messageBody !== undefined &&
      company.settings?.referral.customNewReferralMessage.body !==
        editedData.messageBody
    ) {
      result["customNewReferralMessage"] = {};
      result["customNewReferralMessage"]["body"] = editedData.messageBody;
    }

    if (
      editedData.enableMessage !== undefined &&
      company.settings?.referral.customNewReferralMessage.enabled !==
        editedData.enableMessage
    ) {
      if (!("customNewReferralMessage" in result)) {
        result["customNewReferralMessage"] = {};
      }
      result["customNewReferralMessage"]["enabled"] = editedData.enableMessage;
    }

    if (
      editedData.messageGreeting !== undefined &&
      company.settings?.referral.customNewReferralMessage.greeting !==
        editedData.messageGreeting
    ) {
      if (!("customNewReferralMessage" in result)) {
        result["customNewReferralMessage"] = {};
      }
      result["customNewReferralMessage"]["greeting"] =
        editedData.messageGreeting;
    }

    return result;
  };

  const onSaveClick = async () => {
    try {
      const referralSettings = getReferralSettings();
      const settings = {
        ...(Object.keys(referralSettings).length > 0 && {
          referral: referralSettings,
        }),
      };

      await updateCompany({
        settings,
      });
      setEditMode(false);
      setEditedData({});
      Toast("success", "Update success!");
    } catch (e) {
      Toast("error", "Error saving");
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setEditedData({});
  };

  const onInputChange = (
    field: "messageGreeting" | "messageBody" | "enableMessage",
    value: string | boolean
  ) => {
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const highlightKeywords = (text: string): string => {
    const highlightedText = (text || "").replace(
      /\$companyPhoneNumber|\$companyName|\$referralFirstName|\$referredByFirstName/g,
      (match) => {
        return `<span class="shaded-box">${match}</span>`;
      }
    );

    return `<span>${highlightedText}</span>`;
  };

  return (
    <NewReferralMessageStyle className="newReferralMessageRoot">
      <FloatingCard
        title={
          <Title
            level={3}
            style={{ marginTop: 12 }}
          >
            <Row gutter={[10, 10]}>
              <Col>
                <Avatar icon={<ShareAltOutlined />} />
              </Col>
              <Col style={{ paddingTop: 7 }}>New Referral Message</Col>
            </Row>
          </Title>
        }
        extra={
          <>
            {editMode ? (
              <Space direction="horizontal">
                <Button
                  type="primary"
                  onClick={onSaveClick}
                >
                  Save
                </Button>
                <Button onClick={onCancelClick}>Cancel</Button>
              </Space>
            ) : (
              <Button
                style={{
                  marginLeft: "20px",
                  alignItems: "center",
                  display: "flex",
                }}
                type="ghost"
                onClick={() => onEditClick(company)}
              >
                <EditOutlined style={{ fontSize: "20px" }} /> Edit
              </Button>
            )}
          </>
        }
      >
        <Row
          gutter={10}
          justify="start"
        >
          <Col>
            <Switch
              disabled={!editMode}
              checked={enableNewReferralMessage}
              onChange={(enabled) => {
                setEnableNewReferralMessage(enabled);
                onInputChange("enableMessage", enabled);
              }}
            />{" "}
            Send New Referral Message?
          </Col>
        </Row>
        {enableNewReferralMessage && (
          <>
            <Divider />
            <Row gutter={10}>
              <Col>
                <Descriptions
                  layout="vertical"
                  colon={false}
                  size="small"
                >
                  <Descriptions.Item
                    style={{ textAlign: "left" }}
                    label="Greeting"
                  >
                    {editMode ? (
                      <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        placeholder="Nothing yet"
                        value={
                          editedData.messageGreeting ??
                          company.settings?.referral.customNewReferralMessage
                            .greeting
                        }
                        onChange={(e) =>
                          onInputChange("messageGreeting", e.target.value)
                        }
                      />
                    ) : (
                      <div
                        className="custom-description-item-value"
                        dangerouslySetInnerHTML={{
                          __html: highlightKeywords(
                            company.settings?.referral.customNewReferralMessage
                              .greeting
                          ),
                        }}
                      />
                    )}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  layout="vertical"
                  colon={false}
                  size="small"
                >
                  <Descriptions.Item
                    style={{ textAlign: "left" }}
                    label="Body"
                  >
                    {editMode ? (
                      <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        placeholder="Nothing yet"
                        value={
                          editedData.messageBody ??
                          company.settings?.referral.customNewReferralMessage
                            .body
                        }
                        onChange={(e) =>
                          onInputChange("messageBody", e.target.value)
                        }
                      />
                    ) : (
                      <div
                        className="custom-description-item-value"
                        dangerouslySetInnerHTML={{
                          __html: highlightKeywords(
                            company.settings?.referral.customNewReferralMessage
                              .body
                          ),
                        }}
                      />
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Divider orientation="left">
              <Typography.Text type="secondary">
                Example Message to New Referral
              </Typography.Text>
            </Divider>
            <Row gutter={10}>
              <Col>
                <Typography style={{ textAlign: "left" }}>
                  {renderTextMessage(company)}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      </FloatingCard>
    </NewReferralMessageStyle>
  );
};

export default NewReferralMessage;
