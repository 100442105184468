import { Col, Divider, Row, Spin, Typography } from "antd";
import FloatingCard from "components/FloatingCard";
import { ReferralProvider, useReferral } from "providers/ReferralContext";
import { useEffect, useState } from "react";
import Content from "../../layout/Content";
import MetricsBarChart, {
  ChooseBarChartTimeRange,
} from "./components/BarChart";
import {
  BarChartTimeRangeType,
  SeriesData,
  createTotalReferralBarChartData,
  createTotalSuccessfulReferralBarChartData,
} from "./components/createData";

const { Title } = Typography;

function Metrics() {
  const { referralFeed } = useReferral();
  const { data, isLoading, error } = referralFeed;
  const [totalReferralSeriesData, setTotalReferralSeriesData] = useState<
    SeriesData[]
  >([]);
  const [
    totalSuccessfulReferralSeriesData,
    setTotalSuccessfulReferralSeriesData,
  ] = useState<SeriesData[]>([]);
  const [conversionRatioSeriesData, setConversionRatioSeriesData] = useState<
    SeriesData[]
  >([]);
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([]);
  const [timeRangeType, setTimeRangeType] = useState<BarChartTimeRangeType>(
    BarChartTimeRangeType.MONTH
  );
  const [referralAmountYMaximum, setReferralAmountYMaximum] =
    useState<number>(10);

  useEffect(() => {
    if (data) {
      const { groups: feedGroups } = data;
      /*
      Run calculations to determine the following
      1. Total Referrals
      2. Total Successful Referrals
      3. Referral Conversion Rate
      */
      let seriesData = []; // these are reused for storing data in the hooks for all 3 charts
      let xAxisLabels = [];
      ({ seriesData, xAxisLabels } = createTotalReferralBarChartData(
        feedGroups,
        timeRangeType
      ));
      // determine referral amount y maximum. It will always be the highest value of the total referral series data
      let maxReferrals = 0;
      for (let i = 0; i < seriesData.length; i++) {
        const currentMax = Math.max(...seriesData[i].data);
        if (currentMax > maxReferrals) {
          maxReferrals = currentMax;
        }
      }
      setReferralAmountYMaximum(Math.ceil(maxReferrals * 1.1));
      setTotalReferralSeriesData(seriesData);
      setXAxisLabels(xAxisLabels); // this ist he same for all 3 charts
      ({ seriesData, xAxisLabels } = createTotalSuccessfulReferralBarChartData(
        feedGroups,
        timeRangeType
      ));
      setTotalSuccessfulReferralSeriesData(seriesData);
    }
  }, [data, timeRangeType]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoading && data) {
    return (
      <Row
        justify={"center"}
        style={{ width: "100%" }}
      >
        <Col span={22}>
          <FloatingCard>
            <Title>Referral Metrics</Title>
            <Divider />
            <Row>
              <Col span={12}>
                <h2>Total Referrals</h2>
                <MetricsBarChart
                  seriesData={totalReferralSeriesData}
                  xAxisLabels={xAxisLabels}
                  id_appender="total-referral"
                  yAxisRange={{ max: referralAmountYMaximum, min: 0 }}
                />
              </Col>
              <Col span={12}>
                <h2>Total Successful Referrals</h2>
                <MetricsBarChart
                  seriesData={totalSuccessfulReferralSeriesData}
                  xAxisLabels={xAxisLabels}
                  id_appender="total-successful-referral"
                  yAxisRange={{ max: referralAmountYMaximum, min: 0 }}
                />
              </Col>
            </Row>

            <ChooseBarChartTimeRange
              chooseTimeRangeType={(timeRangeType) =>
                setTimeRangeType(timeRangeType)
              }
            />
          </FloatingCard>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row
        justify="center"
        style={{ marginTop: 180, width: "100%" }}
      >
        <Spin size="large" />
      </Row>
    );
  }
}

export default function MetricsDashboard() {
  return (
    <ReferralProvider>
      <Content>
        <Metrics />
      </Content>
    </ReferralProvider>
  );
}
