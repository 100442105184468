import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import Api from "../../api/api";
import FloatingCard from "../../components/FloatingCard";
import Content from "../../layout/Content";
import ReferralsStyle from "./ReferralsStyle";
import { ReferralsTable } from "./components";

import { useQuery } from "@apollo/client";
import { GetFeedQuery } from "__generated__/graphql";
import { GET_FEED } from "data/queries";
import ReferralsBarChart from "./components/ReferralsBarChart";

interface Props {}

const Referrals: React.FC<Props> = () => {
  const { data } = useQuery<GetFeedQuery>(GET_FEED);
  let feedGroups = data?.getFeed.groups || [];

  return (
    <ReferralsStyle className="referralsStyleRoot">
      <Content>
        <Row
          style={{ width: "100%" }}
          justify="space-evenly"
        >
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 13, offset: 1 }}
            className="mb20"
          >
            <ReferralsTable />
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
            className="mb20"
          >
            <Row>
              <Col
                xs={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
                className="mb20"
              >
                <ReferralsBarChart feedGroups={feedGroups} />
              </Col>
            </Row>
            <Row>
              <Col
                xs={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
                className="mb20"
              >
                <FloatingCard title="Actions">
                  <Row justify="center">
                    <Col flex="auto">
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={() => Api.exportReferrals()}
                      >
                        Download Referral Data
                      </Button>
                    </Col>
                  </Row>
                </FloatingCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </ReferralsStyle>
  );
};

export default Referrals;
