import {
  CalendarOutlined,
  IdcardOutlined,
  LikeOutlined,
  MessageOutlined,
  NotificationOutlined,
  ReadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
} from "antd";
import ChooseCampaignGroups from "components/ChooseCampaignGroup";
import ScheduleADemo from "components/ScheduleADemo";
import React, { useState } from "react";
import {
  CreateCampaignMutation,
  Timezone,
} from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import { CREATE_CAMPAIGN } from "../../../data/mutations";
import { LIST_CAMPAIGNS } from "../../../data/queries";
import { useAuthorization } from "../../../providers/AuthorizationContext";
import { prettyHour } from "../utils";
import CampaignDatePicker from "./CampaignDatePicker";

const { Option } = Select;

interface TextMessageCampaignConfig {
  campaignName: string;
  iterations: number;
  frequency: number;
  batchSize: number;
  sendAtHour: number;
  sendAtTimezone: Timezone;
  startDate?: number;
  sendSMS: boolean;
  sendEmails: boolean;
  campaignSendgridId?: string;
  campaignTextGreeting?: string;
  campaignTextBody?: string;
}

const renderMissingPermissionAlert = () => {
  return (
    <Alert
      message={
        <>
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            <Row justify="center">
              <Col>
                <Typography.Text>
                  Free accounts do not include Campaign creation. Want all the
                  features?
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <ScheduleADemo />
              </Col>
            </Row>
          </Space>
        </>
      }
      type="info"
      showIcon={false}
      closable={false}
      banner
      style={{ marginBottom: 10 }}
    />
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1285px) {
    flex-direction: column;
  }
`;

const CreateNewCampaignForm: React.FC = () => {
  const [campaignGroupIds, setCampaignGroupIds] = useState<string[]>();
  const [resetCampaignGroups, setResetCampaignGroups] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [createCampaign, createCampaignMetadata] =
    useMutation<CreateCampaignMutation>(CREATE_CAMPAIGN, {
      refetchQueries: [{ query: LIST_CAMPAIGNS }, "ListCampaignsQuery"],
    });

  const { canCreateCampaigns } = useAuthorization();

  function resetFormFields() {
    setResetCampaignGroups((prev) => !prev);
    form.resetFields();
  }

  const onFinish = async (values: TextMessageCampaignConfig) => {
    await createCampaign({
      variables: {
        input: {
          name: values.campaignName,
          iterations: values.iterations,
          frequency: values.frequency,
          batchSize: values.batchSize,
          sendAtHour: values.sendAtHour,
          sendAtTimezone: values.sendAtTimezone,
          startDate: values.startDate,
          sendSMS: !!values.sendSMS,
          sendEmails: !!values.sendEmails,
          campaignGroupIds: campaignGroupIds,
          customSendgridTemplateId: values.campaignSendgridId,
          customTextReferralMessage: {
            greeting: values.campaignTextGreeting,
            body: values.campaignTextBody,
          },
        },
      },
    });
    resetFormFields();
  };

  return (
    <FloatingCard title="Create New Campaign">
      {!canCreateCampaigns && renderMissingPermissionAlert()}
      <Form
        initialValues={{ sendSMS: false, sendEmails: false }}
        form={form}
        onFinish={onFinish}
        size="middle"
        disabled={!canCreateCampaigns}
      >
        <Form.Item
          name="campaignName"
          rules={[
            {
              required: true,
              message: "Please enter a campaign name!",
            },
          ]}
        >
          <Input
            placeholder="Campaign Name"
            style={{
              width: "100%",
            }}
            prefix={<NotificationOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="iterations"
          rules={[
            {
              required: true,
              message: "Please select the number of iterations!",
            },
          ]}
        >
          <InputNumber
            min={1}
            max={52}
            placeholder="Number of Iterations"
            style={{
              width: "100%",
            }}
            prefix={<SyncOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="frequency"
          rules={[
            {
              required: true,
              message: "Please enter the frequency in days!",
            },
            {
              type: "number",
              min: 1,
              max: 365,
              message: `Frequency must be between 1 and 365 days!`,
            },
          ]}
        >
          <InputNumber
            placeholder="Frequency (Send every X days)"
            style={{
              width: "100%",
            }}
            prefix={<CalendarOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="batchSize"
          rules={[
            {
              required: true,
              message: "Please enter the batch size!",
            },
            {
              type: "number",
              min: 1,
              max: 10000,
              message: `Batch size must be between 1 and 10,000!`,
            },
          ]}
        >
          <InputNumber
            placeholder="Batch Size (How many to send each day)"
            style={{
              width: "100%",
            }}
            prefix={<LikeOutlined />}
            size="large"
          />
        </Form.Item>
        {/* make it only available to admins for now */}
        {localStorage.getItem("referpro_admin_token") ? (
          <>
            <Form.Item name="campaignTextGreeting">
              <Input
                placeholder="Text Greeting"
                style={{
                  width: "100%",
                }}
                prefix={<ReadOutlined />}
                size="large"
              />
            </Form.Item>
            <Form.Item name="campaignTextBody">
              <Input
                placeholder="Text Body"
                style={{
                  width: "100%",
                }}
                prefix={<MessageOutlined />}
                size="large"
              />
            </Form.Item>
            <Form.Item name="campaignSendgridId">
              <Input
                placeholder="Sendgrid Template ID"
                style={{
                  width: "100%",
                }}
                prefix={<IdcardOutlined />}
                size="large"
              />
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="startDate">
          <CampaignDatePicker
            onDateChange={(unixTimestamp: number) => {
              form.setFieldValue("startDate", unixTimestamp);
            }}
          />
        </Form.Item>
        <Form.Item name="sendAtHour">
          <Select
            placeholder="Send At (Hour of day)"
            style={{
              width: "100%",
              textAlign: "left",
            }}
            size="large"
          >
            {[...Array(24).keys()].map((hour) => (
              <Option
                key={hour}
                value={hour}
              >
                {prettyHour(hour)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sendAtTimezone">
          <Select
            placeholder="Send At Timezone"
            style={{
              width: "100%",
              textAlign: "left",
            }}
            size="large"
          >
            <Option value={Timezone.Eastern}>Eastern</Option>
            <Option value={Timezone.Central}>Central</Option>
            <Option value={Timezone.Mountain}>Mountain</Option>
            <Option value={Timezone.Pacific}>Pacific</Option>
          </Select>
        </Form.Item>

        <ChooseCampaignGroups
          selectSize="large"
          onChoose={setCampaignGroupIds}
          resetTrigger={resetCampaignGroups}
        />
        <Form.Item
          name="sendSMS"
          label="Send SMS"
          valuePropName="checked"
          style={{ textAlign: "left" }}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="sendEmails"
          label="Send Emails"
          valuePropName="checked"
          style={{ textAlign: "left" }}
        >
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Space align="center">
            <ButtonWrapper>
              <Spin spinning={createCampaignMetadata.loading}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ minWidth: 200 }}
                  disabled={createCampaignMetadata.loading}
                >
                  Create
                </Button>
              </Spin>
              <Button
                onClick={() => resetFormFields()}
                size="large"
                style={{ minWidth: 200 }}
              >
                Reset
              </Button>
            </ButtonWrapper>
          </Space>
        </Form.Item>
      </Form>
    </FloatingCard>
  );
};

export default CreateNewCampaignForm;
