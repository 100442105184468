import { Col, Row, Space, Typography } from "antd";
import TypingText from "components/TypingText";
import React from "react";

const { Text } = Typography;

const ReferralFormTitle: React.FC<{
  title: string;
  wavingHand: boolean;
  emoji?: string;
}> = ({ title, wavingHand = true, emoji = "👋" }) => {
  return (
    <Row
      justify="center"
      style={{ marginBottom: 10 }}
    >
      <Col>
        <Text style={{ fontWeight: 200, fontSize: "24px" }}>
          <Row>
            <Space direction="horizontal">
              <Col>
                <TypingText
                  text={title}
                  duration={40}
                  style={{
                    fontWeight: 200,
                    fontSize: "24px",
                  }}
                />
              </Col>
              {wavingHand ? (
                <Col>
                  <div
                    style={{
                      fontWeight: 200,
                      fontSize: "24px",
                    }}
                    className="waving-hand"
                  >
                    {emoji}
                  </div>
                </Col>
              ) : null}
            </Space>
          </Row>
        </Text>
      </Col>
    </Row>
  );
};

export default ReferralFormTitle;
