import type { MenuProps } from "antd";
import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React from "react";
import { useHistory } from "react-router-dom";

import "./sider.css";

import {
  BarChartOutlined,
  CreditCardOutlined,
  FlagOutlined,
  HomeOutlined,
  LikeOutlined,
  LogoutOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
} from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: any,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    label,
    onClick,
    children,
  } as MenuItem;
}

export default ({ handleLogout }) => {
  const history = useHistory();

  const items: MenuItem[] = [
    getItem("Home", "1", <HomeOutlined />, () => history.push("/app")),
    getItem("Referrals", "2", <LikeOutlined />, () =>
      history.push("/referrals")
    ),
    getItem("Customers", "3", <TeamOutlined />, () =>
      history.push("/customers")
    ),
    getItem("Employees", "4", <ShopOutlined />, () =>
      history.push("/employees")
    ),
    getItem("Campaigns", "5", <FlagOutlined />, () =>
      history.push("/campaigns")
    ),
    getItem("Metrics", "6", <BarChartOutlined />, () =>
      history.push("/metrics")
    ),
    getItem("Billing", "7", <CreditCardOutlined />, () =>
      history.push("/billing")
    ),
    getItem("Settings", "sub1", <SettingOutlined />, undefined, [
      getItem("Company", "8", <SettingOutlined />, () =>
        history.push("/company-settings")
      ),
      getItem("Referral", "9", <SettingOutlined />, () =>
        history.push("/referral-settings")
      ),
    ]),
    getItem("Logout", "10", <LogoutOutlined />, () => handleLogout()),
  ];

  return (
    <Sider theme="light">
      <div className="logo" />
      <Menu
        theme="light"
        defaultSelectedKeys={["1"]}
        // defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
        inlineCollapsed={false}
      />
    </Sider>
  );
};
