import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";

import {
  CheckOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

import SearchSelect from "./searchSelect";

import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { ListCustomersQuery } from "../__generated__/graphql";
import { LIST_CUSTOMERS } from "../data/queries";
import { useAuthorization } from "../providers/AuthorizationContext";
import ChooseCampaignGroups from "./ChooseCampaignGroup";
import ScheduleADemo from "./ScheduleADemo";
import "./components.css";

type Customer = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  campaignGroupIds: string[];
};

const { Text } = Typography;

const buildDeliveryMethods = (sendType: string) => {
  if (sendType === "both") {
    return ["EMAIL", "TEXT"];
  }
  return [sendType.toUpperCase()];
};

const CREATE_REFERRAL_LINK = gql`
  mutation CreateReferralLink($input: CreateReferralLinkInput!) {
    createReferralLink(input: $input) {
      referralLink {
        id
        target
        createdAt
      }
    }
  }
`;

const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        id
        firstName
        lastName
      }
    }
  }
`;

const SelectCustomer = ({ createNew, onCustomerChange }) => {
  return (
    <>
      {createNew && <CreateNewCustomer onCustomerChange={onCustomerChange} />}
      {!createNew && (
        <SelectExistingCustomer onCustomerChange={onCustomerChange} />
      )}
    </>
  );
};

const CreateLink = ({
  enabled,
  sendType,
  handleChange,
  hasAllLinkPermissions,
}) => {
  return (
    <>
      <Space direction="vertical">
        <Row>
          <Col flex="auto">
            <Text
              strong
              style={{ color: enabled ? "" : "rgba(0, 0, 0, 0.25)" }}
            >
              Send referral link via
            </Text>
          </Col>
        </Row>
        <Row>
          <Col
            span={8}
            style={{ paddingRight: 5 }}
          >
            <Button
              type={sendType === "email" ? "primary" : "default"}
              onClick={() => {
                handleChange("email");
              }}
              block
              disabled={!enabled || !hasAllLinkPermissions}
            >
              EMAIL
            </Button>
          </Col>
          <Col
            span={8}
            style={{ paddingRight: 5 }}
          >
            <Button
              type={sendType === "text" ? "primary" : "default"}
              onClick={() => {
                handleChange("text");
              }}
              block
              disabled={!enabled || !hasAllLinkPermissions}
            >
              TEXT
            </Button>
          </Col>
          {!hasAllLinkPermissions && (
            <Col span={8}>
              <Button
                type={sendType === "none" ? "primary" : "default"}
                onClick={() => {
                  handleChange("none");
                }}
                block
                disabled={!enabled}
              >
                NONE
              </Button>
            </Col>
          )}
          {hasAllLinkPermissions && (
            <Col span={8}>
              <Button
                type={sendType === "both" ? "primary" : "default"}
                onClick={() => {
                  handleChange("both");
                }}
                block
                disabled={!enabled}
              >
                BOTH
              </Button>
            </Col>
          )}
        </Row>
      </Space>
    </>
  );
};

const SelectExistingCustomer = ({ onCustomerChange }) => {
  const { loading, data } = useQuery<ListCustomersQuery>(LIST_CUSTOMERS);

  return (
    <div>
      <Spin spinning={loading}>
        <SearchSelect
          options={
            data?.listCustomers
              ? data.listCustomers.customers.map((customer) => ({
                  id: customer.id,
                  name: `${customer.firstName} ${customer.lastName}`,
                }))
              : []
          }
          onChange={(id) => {
            onCustomerChange(
              data.listCustomers.customers.find(
                (customer) => customer.id === id
              )
            );
          }}
        />
      </Spin>
    </div>
  );
};

const CustomerFormWrapper = styled.div`
  @media screen and (max-width: 576px) {
    .responsive-input {
      font-size: 16px;
      padding: 7px 10px;
    }
  }
`;

const CreateNewCustomer = ({ onCustomerChange }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [campaignGroupIds, setCampaignGroupIds] = useState<string[]>();

  useEffect(() => {
    const customer = { firstName, lastName, email, phone, campaignGroupIds };
    onCustomerChange(customer);
  }, [firstName, lastName, email, phone, campaignGroupIds]);

  return (
    <div>
      <CustomerFormWrapper>
        <Form
          name="createNewCustomer"
          onFinish={(values) => alert("Finished!")}
          initialValues={{}}
          size="middle"
          scrollToFirstError
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input a first name!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="responsive-input"
              placeholder="First name"
              prefix={<UserOutlined />}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input a last name!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="responsive-input"
              placeholder="Last name"
              prefix={<UserOutlined />}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                message: "Please input an email!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="responsive-input"
              placeholder="Email"
              prefix={<MailOutlined />}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                message: "Please input a phone number!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Phone number"
              className="responsive-input"
              prefix={<PhoneOutlined />}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
          <ChooseCampaignGroups onChoose={setCampaignGroupIds} />
        </Form>
      </CustomerFormWrapper>
    </div>
  );
};

const isValidCustomer = (customer: any) => {
  if (customer.id) {
    return true;
  }

  const hasRequiredAttrs = ["firstName", "lastName"].every(
    (attr) => customer[attr] !== undefined && customer[attr] != ""
  );

  return hasRequiredAttrs && (customer.email || customer.phone);
};

const isValidSendType = (sendType: string) => {
  return ["email", "text", "both", "none"].some(
    (validSendType) => sendType === validSendType
  );
};

interface Props {
  shouldCreateNewCustomer: boolean;
  onFinish: () => void;
}

const INITIAL_STATE = {
  sendType: "",
  customer: {} as any,
  isSubmitting: false,
  showSuccess: false,
  showFailure: false,
  stepOneFinished: false,
  stepTwoFinished: false,
  successMessage: "",
};

const CustomerCreateForm: React.FC<Props> = ({
  shouldCreateNewCustomer,
  onFinish,
}) => {
  const [sendType, setSendType] = useState(INITIAL_STATE.sendType);
  const [customer, setCustomer] = useState<Customer>(INITIAL_STATE.customer);
  const [isSubmitting, setIsSubmitting] = useState(INITIAL_STATE.isSubmitting);
  const [showSuccess, setShowSuccess] = useState(INITIAL_STATE.showSuccess);
  const [showFailure, setShowFailure] = useState(INITIAL_STATE.showFailure);
  const [successMessage, setSuccessMessage] = useState(
    INITIAL_STATE.successMessage
  );

  const showForm = !(showSuccess || showFailure);

  const [stepOneFinished, setStepOneFinished] = useState(
    INITIAL_STATE.stepOneFinished
  );
  const [stepTwoFinished, setStepTwoFinished] = useState(
    INITIAL_STATE.stepTwoFinished
  );

  const [createCustomer, createCustomerMeta] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [{ query: LIST_CUSTOMERS }, "ListCustomersQuery"],
  });

  const [createReferralLink, createReferralLinkMeta] = useMutation(
    CREATE_REFERRAL_LINK,
    {
      refetchQueries: [{ query: LIST_CUSTOMERS }, "ListCustomersQuery"],
    }
  );

  const { hasAllLinkPermissions } = useAuthorization();

  const readyToSubmit =
    stepOneFinished && (stepTwoFinished || !hasAllLinkPermissions);

  useEffect(() => {
    setStepOneFinished(isValidCustomer(customer));
    setStepTwoFinished(
      isValidSendType(sendType) || (stepOneFinished && !hasAllLinkPermissions)
    );
    if (stepOneFinished && !hasAllLinkPermissions) {
      setSendType("none");
    }
  }, [customer, sendType]);

  const resetState = () => {
    setSendType(INITIAL_STATE.sendType);
    setCustomer(INITIAL_STATE.customer);
    setIsSubmitting(INITIAL_STATE.isSubmitting);
    setShowSuccess(INITIAL_STATE.showSuccess);
    setShowFailure(INITIAL_STATE.showFailure);
    setStepOneFinished(INITIAL_STATE.stepOneFinished);
    setStepTwoFinished(INITIAL_STATE.stepTwoFinished);
    setSuccessMessage(INITIAL_STATE.successMessage);
  };

  return (
    <div>
      <div>
        {showSuccess && (
          <Result
            status="success"
            subTitle={successMessage}
            extra={[
              <Space direction="vertical">
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      type="primary"
                      key="done"
                      onClick={() => {
                        resetState();
                        onFinish();
                      }}
                    >
                      Done
                    </Button>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      key="again"
                      onClick={resetState}
                    >
                      Create Another
                    </Button>
                  </Col>
                </Row>
              </Space>,
            ]}
          />
        )}
        {showFailure && (
          <Result
            status="error"
            extra={[
              <Space direction="vertical">
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      type="primary"
                      key="done"
                      onClick={resetState}
                    >
                      Try Again
                    </Button>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      key="again"
                      onClick={onFinish}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </Space>,
            ]}
          />
        )}
        {showForm && (
          <Space
            direction="vertical"
            style={{ width: "100%" }}
            size={30}
          >
            <Row>
              <Col flex="auto">
                {!hasAllLinkPermissions && (
                  <Alert
                    message={
                      <>
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                        >
                          <Row justify="center">
                            <Col>
                              <Typography.Text>
                                Free accounts do not include Referral Link
                                creation. Want all the features?
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col>
                              <ScheduleADemo />
                            </Col>
                          </Row>
                        </Space>
                      </>
                    }
                    type="info"
                    showIcon={false}
                    closable={false}
                    banner
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col
                span={3}
                style={{ paddingTop: 5 }}
              >
                <div>
                  <div
                    className="ant-steps ant-steps-item-tail"
                    style={{
                      width: 1,
                      height: "100%",
                      left: 12,
                      paddingLeft: 0,
                      paddingRight: 0,
                      borderLeft: "1px solid rgba(0, 0, 0, 0.20)",
                      top: 30,
                    }}
                  ></div>
                  <Button
                    shape="circle"
                    size="small"
                    type="primary"
                    style={{
                      backgroundColor: stepOneFinished ? "transparent" : "",
                    }}
                  >
                    {!stepOneFinished && <>1</>}
                    {stepOneFinished && (
                      <CheckOutlined style={{ color: "rgb(62, 145, 247)" }} />
                    )}
                  </Button>
                </div>
              </Col>
              <Col flex="auto">
                <SelectCustomer
                  createNew={shouldCreateNewCustomer}
                  onCustomerChange={setCustomer}
                />
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <div>
                  <div
                    className="ant-steps ant-steps-item-tail"
                    style={{
                      width: 1,
                      height: "100%",
                      left: 12,
                      paddingLeft: 0,
                      paddingRight: 0,
                      borderLeft: "1px solid rgba(0, 0, 0, 0.20)",
                      top: 30,
                    }}
                  ></div>
                  <Button
                    shape="circle"
                    size="small"
                    type={stepOneFinished ? "primary" : "default"}
                    disabled={!stepOneFinished}
                    style={{
                      backgroundColor: stepTwoFinished ? "transparent" : "",
                    }}
                  >
                    {!stepTwoFinished && <>2</>}
                    {stepTwoFinished && (
                      <CheckOutlined style={{ color: "rgb(62, 145, 247)" }} />
                    )}
                  </Button>
                </div>
              </Col>
              <Col flex="auto">
                <CreateLink
                  enabled={stepOneFinished}
                  sendType={sendType}
                  handleChange={setSendType}
                  hasAllLinkPermissions={hasAllLinkPermissions}
                />
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <div>
                  <Button
                    shape="circle"
                    size="small"
                    type={readyToSubmit ? "primary" : "default"}
                    disabled={!readyToSubmit}
                  >
                    3
                  </Button>
                </div>
              </Col>
              <Col flex="auto">
                <Spin spinning={isSubmitting}>
                  <Button
                    onClick={async () => {
                      try {
                        setIsSubmitting(true);

                        if (customer.id && hasAllLinkPermissions) {
                          // existing customer, all we need to do is create a link
                          await createReferralLink({
                            variables: {
                              input: {
                                customerId: customer.id,
                                deliveryMethods: buildDeliveryMethods(sendType),
                              },
                            },
                          });
                        } else {
                          const newCustomer = await createCustomer({
                            variables: {
                              input: {
                                firstName: customer.firstName,
                                lastName: customer.lastName,
                                ...(customer.email && {
                                  email: customer.email,
                                }),
                                ...(customer.phone && {
                                  phone: customer.phone,
                                }),
                                campaignGroupIds: customer.campaignGroupIds,
                              },
                            },
                          });
                          const customerId =
                            newCustomer.data?.createCustomer.customer.id;
                          if (hasAllLinkPermissions) {
                            await createReferralLink({
                              variables: {
                                input: {
                                  customerId,
                                  deliveryMethods:
                                    buildDeliveryMethods(sendType),
                                },
                              },
                            });
                          }
                        }

                        setSuccessMessage(
                          shouldCreateNewCustomer
                            ? `Created new customer: ${customer.firstName} ${customer.lastName}`
                            : `Created new link for: ${customer.firstName} ${customer.lastName}`
                        );
                        setShowSuccess(true);
                      } finally {
                        setIsSubmitting(false);
                      }
                    }}
                    block
                    disabled={!readyToSubmit}
                    type={readyToSubmit ? "primary" : "default"}
                  >
                    CREATE
                  </Button>
                </Spin>
              </Col>
            </Row>
          </Space>
        )}
      </div>
    </div>
  );
};

export default CustomerCreateForm;
