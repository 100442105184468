import { DownloadOutlined, QrcodeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Avatar, Button, Col, Row, Space, Typography } from "antd";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { Company } from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";

const { Title } = Typography;

const GeneralEmployeeQRCodeStyle = styled.div({
  "&.rewardSettingsRoot": {
    ".custom-description-item-value": {
      display: "inline-flex",
    },
    ".shaded-box": {
      backgroundColor: "#ffecd1" /* Adjust the background color as needed */,
      padding: "2px 4px",
      borderRadius: "8px",
    },
  },
});

interface GeneralEmployeeQRCodeProps {
  company: Company;
}

const GeneralEmployeeQRCode: React.FC<GeneralEmployeeQRCodeProps> = ({
  company,
}) => {
  const [qrCodeData, setQRCodeData] = useState<string>("");

  useEffect(() => {
    // Generate the QR code data
    const generateQRCodeData = () => {
      if (!company?.urlEncodedName) setQRCodeData("");
      const data = `https://referpro.co/r/${company.urlEncodedName}/employ`;
      setQRCodeData(data);
    };

    generateQRCodeData();
  }, []);

  const downloadQRCode = () => {
    const canvas = document.getElementById(
      "generalEmployeeQRCode"
    ) as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${company.name}-general-employee-qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <GeneralEmployeeQRCodeStyle className="rewardSettingsRoot">
      <FloatingCard
        title={
          <Title
            level={3}
            style={{ marginTop: 12 }}
          >
            <Row gutter={[10, 10]}>
              <Col>
                <Avatar icon={<QrcodeOutlined />} />
              </Col>
              <Col style={{ paddingTop: 7 }}>General Employee QR Code</Col>
            </Row>
          </Title>
        }
        extra={
          <Button
            type="primary"
            onClick={downloadQRCode}
            style={{
              marginLeft: "20px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <DownloadOutlined style={{ fontSize: "20px" }} /> Download
          </Button>
        }
      >
        <FloatingCard>
          <Space
            direction="vertical"
            align="center"
          >
            <QRCode
              id="generalEmployeeQRCode"
              value={qrCodeData}
            />{" "}
            {/* Render the QR code */}
          </Space>
        </FloatingCard>
      </FloatingCard>
    </GeneralEmployeeQRCodeStyle>
  );
};

export default GeneralEmployeeQRCode;
