import { useLazyQuery, useMutation } from "@apollo/client";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Company,
  CreateUserMutation,
  CreateUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  User,
} from "../__generated__/graphql";
import ErrorBoundary from "../components/ErrorBoundary";
import { REFERPRO_TOKEN_NAME, REFERPRO_USER_ID } from "../constants";
import { CREATE_USER, UPDATE_COMPANY, UPDATE_USER } from "../data/mutations";
import { GET_USER } from "../data/queries";

// Company Context
const CompanyContext = createContext({
  loading: false,
  company: null,
  user: null,
  updateCompany: (arg: any) => {}, // Mock function, does nothing
  updateUser: (arg: any) => {}, // Mock function, does nothing
  createUser: (
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ) => {},
});

// Provider component
export const CompanyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const [queryUser] = useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GET_USER,
    { fetchPolicy: "no-cache" }
  );
  const [updateCompanyGQL, { data: updateCompanyResponse }] = useMutation(
    UPDATE_COMPANY,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [updateUserGQL] = useMutation(UPDATE_USER);
  const [createUserGQL] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let userId = null;
      const msDelay = 100;
      const maxIterations = 120; // wait up to 3 seconds
      let counter = 0;
      do {
        await new Promise((resolve) => setTimeout(resolve, msDelay));
        if (localStorage.getItem(REFERPRO_USER_ID)) {
          userId = localStorage.getItem(REFERPRO_USER_ID);
        }
        // eslint-disable-next-line no-console
        console.log({
          userId,
          token: localStorage.getItem(REFERPRO_TOKEN_NAME),
          counter,
        });
      } while (
        (userId === null ||
          localStorage.getItem(REFERPRO_TOKEN_NAME) === null) &&
        counter++ < maxIterations
      );

      const { data, error } = await queryUser({
        variables: {
          id: userId,
        },
      });

      if (data) {
        setUser(data.getUser);
        setCompany(data.getUser.company);
        setLoading(false);
      }
    };

    if (user === null && company === null && !loading) {
      fetchData();
    }

    if (updateCompanyResponse) {
      setCompany(updateCompanyResponse.updateCompany.company);
    }
  }, [updateCompanyResponse]);

  async function updateCompany(updateValues: Partial<Company>) {
    try {
      await updateCompanyGQL({
        variables: {
          input: {
            updateAttributes: {
              ...(updateValues.name && { name: updateValues.name }),
              ...(updateValues.domain && { domain: updateValues.domain }),
              ...(updateValues.phone && { phone: updateValues.phone }),
              ...(updateValues.settings && { settings: updateValues.settings }),
            },
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error updating company", e);
    }
  }
  async function updateUser(updateValues: Partial<User>) {
    const { data, errors } = await updateUserGQL({
      variables: {
        input: {
          updateAttributes: {
            ...(updateValues.firstName && {
              firstName: updateValues.firstName,
            }),
            ...(updateValues.lastName && { lastName: updateValues.lastName }),
            ...(updateValues.email && { email: updateValues.email }),
            ...(updateValues.phone && { phone: updateValues.phone }),
          },
        },
      },
    });

    if (errors) {
      // eslint-disable-next-line no-console
      console.error("Error updating user", errors);
    }
    if (data) {
      setUser(data.updateUser.user);
    }
  }

  async function createUser(
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ) {
    await createUserGQL({
      variables: {
        input: {
          firstName,
          lastName,
          email,
          phone,
        },
      },
    });
  }

  // Expose the context data
  const value = {
    loading,
    company,
    user,
    updateCompany,
    updateUser,
    createUser,
  };
  return (
    <ErrorBoundary>
      <CompanyContext.Provider value={value}>
        {children}
      </CompanyContext.Provider>
    </ErrorBoundary>
  );
};

// Custom hook to use the context
export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};
