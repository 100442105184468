import { Col, Row } from "antd";
import React from "react";
import { REFERRAL_PAGE_IMAGES } from "../../../constants";

const CompanyImage: React.FC<{ companyId: string }> = ({ companyId }) => {
  if (companyId && companyId in REFERRAL_PAGE_IMAGES) {
    return (
      <Row justify="center">
        <Col
          span={24}
          style={{ padding: "20px" }}
        >
          <img
            src={REFERRAL_PAGE_IMAGES[companyId]}
            style={{
              width: "100%",
              borderRadius: "10px",
            }}
          />
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};
export default CompanyImage;
