import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { Permission as GqlPermission } from "../__generated__/graphql";
import { REFERPRO_PERMISSIONS } from "../constants";

enum PermissionType {
  CREATE_LINK,
  EMAIL_LINK,
  SMS_LINK,
}

type Permissions =
  | {
      [permission in PermissionType]: boolean;
    }
  | {};

// Create the AuthorizationContext
interface AuthorizationContextType {
  refreshPermissions: (permissions: Permissions[]) => Promise<Permissions>;
  hasAllLinkPermissions: boolean;
  canCreateCampaigns: boolean;
  permissions: Permissions;
}

const translateGqlPermission = (permission: GqlPermission) => {
  if (permission === GqlPermission.CreateLink) {
    return PermissionType.CREATE_LINK;
  }
  if (permission === GqlPermission.EmailLink) {
    return PermissionType.EMAIL_LINK;
  }
  if (permission === GqlPermission.SmsLink) {
    return PermissionType.SMS_LINK;
  }
};

const AuthorizationContext = createContext<AuthorizationContextType>({
  refreshPermissions: async (permissions: Permissions[]) => Promise.resolve({}),
  hasAllLinkPermissions: false,
  canCreateCampaigns: false,
  permissions: {},
});

// Define the AuthorizationProvider component
const AuthorizationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [permissions, setPermissions] = useState<Permissions | null>(null);

  useEffect(() => {
    const getPermissionsFromLocalStorage = () => {
      if (permissions === null) {
        const permissionsFromStorage =
          localStorage.getItem(REFERPRO_PERMISSIONS);
        if (permissionsFromStorage) {
          setPermissions(JSON.parse(permissionsFromStorage));
        }
      }
    };

    if (permissions !== null) {
      localStorage.setItem(REFERPRO_PERMISSIONS, JSON.stringify(permissions));
    }

    getPermissionsFromLocalStorage();
  }, [permissions]);

  const refreshPermissions = async (permissions: GqlPermission[]) => {
    const newPermissions: Permissions = {};
    permissions.forEach((permission) => {
      const permissionType = translateGqlPermission(permission);
      newPermissions[permissionType] = true;
    });
    setPermissions(newPermissions);
    return newPermissions;
  };

  const hasAllLinkPermissions =
    permissions &&
    permissions[PermissionType.CREATE_LINK] &&
    permissions[PermissionType.EMAIL_LINK] &&
    permissions[PermissionType.SMS_LINK];

  return (
    <AuthorizationContext.Provider
      value={{
        refreshPermissions,
        permissions: permissions || {},
        hasAllLinkPermissions,
        canCreateCampaigns: hasAllLinkPermissions,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

// Custom hook to use the AuthorizationContext
export const useAuthorization = () => {
  const context = useContext(AuthorizationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthorization must be used within an AuthorizationProvider"
    );
  }
  return context;
};

export { AuthorizationContext, AuthorizationProvider };
