/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Company = {
  __typename?: "Company";
  domain: Scalars["String"];
  employeeList?: Maybe<Array<Maybe<Employee>>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  settings: CompanySettings;
  urlEncodedName: Scalars["String"];
};

export type CompanyInput = {
  domain: Scalars["String"];
  name: Scalars["String"];
};

export type CompanySettings = {
  __typename?: "CompanySettings";
  referral: ReferralSettings;
  reward: RewardSettings;
};

export type CreateCompanyReferralInput = {
  companyId: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
  referralFirstName: Scalars["String"];
  referralLastName?: InputMaybe<Scalars["String"]>;
  referralPhone: Scalars["String"];
  referredByEmail: Scalars["String"];
  referredByFirstName: Scalars["String"];
  referredByLastName?: InputMaybe<Scalars["String"]>;
  referredByPhone: Scalars["String"];
  type?: InputMaybe<ReferralType>;
};

export type CreateCompanyReferralOutput = {
  __typename?: "CreateCompanyReferralOutput";
  error: Scalars["Boolean"];
  referralLink?: Maybe<ReferralLink>;
};

export type CreateEmployeeReferralInput = {
  message?: InputMaybe<Scalars["String"]>;
  referralFirstName: Scalars["String"];
  referralLastName?: InputMaybe<Scalars["String"]>;
  referralLinkId: Scalars["ID"];
  referralPhone: Scalars["String"];
  referredByEmail: Scalars["String"];
  referredByFirstName: Scalars["String"];
  referredByLastName?: InputMaybe<Scalars["String"]>;
  referredByPhone: Scalars["String"];
  type?: InputMaybe<ReferralType>;
};

export type CreateGeneralEmployeeReferralInput = {
  message?: InputMaybe<Scalars["String"]>;
  referralFirstName: Scalars["String"];
  referralLastName?: InputMaybe<Scalars["String"]>;
  referralLinkId: Scalars["ID"];
  referralPhone: Scalars["String"];
  referredByEmail: Scalars["String"];
  referredByFirstName: Scalars["String"];
  referredByLastName?: InputMaybe<Scalars["String"]>;
  referredByPhone: Scalars["String"];
  selectedEmployeeId: Scalars["String"];
  type?: InputMaybe<ReferralType>;
};

export type CreateGeneralEmployeeReferralOutput = {
  __typename?: "CreateGeneralEmployeeReferralOutput";
  error: Scalars["Boolean"];
};

export type CreateReferralInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
  referralLinkId: Scalars["ID"];
  type?: InputMaybe<ReferralType>;
};

export type CreateShareableLinkInput = {
  referralLinkId: Scalars["ID"];
};

export type CreateShareableLinkOutput = {
  __typename?: "CreateShareableLinkOutput";
  link: Scalars["String"];
};

export type CustomDiscountMessage = {
  __typename?: "CustomDiscountMessage";
  body?: Maybe<Scalars["String"]>;
};

export type CustomReferralMessage = {
  __typename?: "CustomReferralMessage";
  banner?: Maybe<Scalars["String"]>;
};

export type Customer = {
  __typename?: "Customer";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export enum DiscountType {
  Amount = "AMOUNT",
  Description = "DESCRIPTION",
  None = "NONE",
}

export type Employee = {
  __typename?: "Employee";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export enum LinkType {
  Company = "COMPANY",
  Employee = "EMPLOYEE",
  GeneralEmployee = "GENERAL_EMPLOYEE",
  Self = "SELF",
  Shareable = "SHAREABLE",
}

export type Mutation = {
  __typename?: "Mutation";
  createCompanyReferral: CreateCompanyReferralOutput;
  createEmployeeReferral: Scalars["Boolean"];
  createGeneralEmployeeReferral: CreateGeneralEmployeeReferralOutput;
  createReferral?: Maybe<Scalars["Boolean"]>;
  createShareableLink: CreateShareableLinkOutput;
  signup: SignupOutput;
};

export type MutationCreateCompanyReferralArgs = {
  input: CreateCompanyReferralInput;
};

export type MutationCreateEmployeeReferralArgs = {
  input: CreateEmployeeReferralInput;
};

export type MutationCreateGeneralEmployeeReferralArgs = {
  input: CreateGeneralEmployeeReferralInput;
};

export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};

export type MutationCreateShareableLinkArgs = {
  input: CreateShareableLinkInput;
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type Query = {
  __typename?: "Query";
  getLink: ReferralLink;
};

export type QueryGetLinkArgs = {
  target: Scalars["String"];
};

export type ReferralLink = {
  __typename?: "ReferralLink";
  company: Company;
  customer?: Maybe<Customer>;
  employee?: Maybe<Employee>;
  id: Scalars["ID"];
  target: Scalars["String"];
  type: LinkType;
};

export type ReferralSettings = {
  __typename?: "ReferralSettings";
  bannerBackgroundColor?: Maybe<Scalars["String"]>;
  customDiscountMessage?: Maybe<CustomDiscountMessage>;
  customMessage?: Maybe<CustomReferralMessage>;
  defaultMessage?: Maybe<Scalars["String"]>;
  discountAmount?: Maybe<Scalars["Int"]>;
  discountDescription?: Maybe<Scalars["String"]>;
  discountType?: Maybe<DiscountType>;
  headerBackgroundColor?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  youtubeVideoId?: Maybe<Scalars["String"]>;
};

export enum ReferralType {
  Self = "SELF",
  Standard = "STANDARD",
}

export type RewardSettings = {
  __typename?: "RewardSettings";
  defaultAmount?: Maybe<Scalars["Int"]>;
};

export type SignupInput = {
  company: CompanyInput;
  user: UserInput;
};

export type SignupOutput = {
  __typename?: "SignupOutput";
  accessToken: Scalars["String"];
};

export type UserInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
};

export type CreateShareableLinkMutationVariables = Exact<{
  input: CreateShareableLinkInput;
}>;

export type CreateShareableLinkMutation = {
  __typename?: "Mutation";
  createShareableLink: {
    __typename?: "CreateShareableLinkOutput";
    link: string;
  };
};

export type GetLinkQueryVariables = Exact<{
  target: Scalars["String"];
}>;

export type GetLinkQuery = {
  __typename?: "Query";
  getLink: {
    __typename?: "ReferralLink";
    id: string;
    type: LinkType;
    company: {
      __typename?: "Company";
      id: string;
      name: string;
      settings: {
        __typename?: "CompanySettings";
        referral: {
          __typename?: "ReferralSettings";
          logoUrl?: string | null;
          headerBackgroundColor?: string | null;
          bannerBackgroundColor?: string | null;
          youtubeVideoId?: string | null;
          discountAmount?: number | null;
          discountDescription?: string | null;
          discountType?: DiscountType | null;
          customMessage?: {
            __typename?: "CustomReferralMessage";
            banner?: string | null;
          } | null;
          customDiscountMessage?: {
            __typename?: "CustomDiscountMessage";
            body?: string | null;
          } | null;
        };
        reward: {
          __typename?: "RewardSettings";
          defaultAmount?: number | null;
        };
      };
      employeeList?: Array<{
        __typename?: "Employee";
        id: string;
        firstName: string;
        lastName: string;
      } | null> | null;
    };
    customer?: { __typename?: "Customer"; firstName: string } | null;
    employee?: { __typename?: "Employee"; firstName: string } | null;
  };
};

export type CreateReferralMutationVariables = Exact<{
  input: CreateReferralInput;
}>;

export type CreateReferralMutation = {
  __typename?: "Mutation";
  createReferral?: boolean | null;
};

export type CreateCompanyReferralMutationVariables = Exact<{
  input: CreateCompanyReferralInput;
}>;

export type CreateCompanyReferralMutation = {
  __typename?: "Mutation";
  createCompanyReferral: {
    __typename?: "CreateCompanyReferralOutput";
    error: boolean;
    referralLink?: {
      __typename?: "ReferralLink";
      id: string;
      target: string;
      customer?: {
        __typename?: "Customer";
        id: string;
        firstName: string;
      } | null;
    } | null;
  };
};

export type CreateEmployeeReferralMutationVariables = Exact<{
  input: CreateEmployeeReferralInput;
}>;

export type CreateEmployeeReferralMutation = {
  __typename?: "Mutation";
  createEmployeeReferral: boolean;
};

export type CreateGeneralEmployeeReferralMutationVariables = Exact<{
  input: CreateGeneralEmployeeReferralInput;
}>;

export type CreateGeneralEmployeeReferralMutation = {
  __typename?: "Mutation";
  createGeneralEmployeeReferral: {
    __typename?: "CreateGeneralEmployeeReferralOutput";
    error: boolean;
  };
};

export const CreateShareableLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateShareableLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateShareableLinkInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createShareableLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "link" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateShareableLinkMutation,
  CreateShareableLinkMutationVariables
>;
export const GetLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "target" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "target" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "target" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "referral" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logoUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "headerBackgroundColor",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "bannerBackgroundColor",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "youtubeVideoId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "discountAmount",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "discountDescription",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "discountType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "customMessage",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "banner",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "customDiscountMessage",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "body" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reward" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "defaultAmount",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "employeeList" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLinkQuery, GetLinkQueryVariables>;
export const CreateReferralDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateReferral" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateReferralInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createReferral" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReferralMutation,
  CreateReferralMutationVariables
>;
export const CreateCompanyReferralDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCompanyReferral" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateCompanyReferralInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCompanyReferral" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "error" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "referralLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "target" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCompanyReferralMutation,
  CreateCompanyReferralMutationVariables
>;
export const CreateEmployeeReferralDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateEmployeeReferral" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateEmployeeReferralInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createEmployeeReferral" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEmployeeReferralMutation,
  CreateEmployeeReferralMutationVariables
>;
export const CreateGeneralEmployeeReferralDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateGeneralEmployeeReferral" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "CreateGeneralEmployeeReferralInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createGeneralEmployeeReferral" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "error" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGeneralEmployeeReferralMutation,
  CreateGeneralEmployeeReferralMutationVariables
>;
