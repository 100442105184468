import { LinkOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Typography } from "antd";
import { Header } from "antd/lib/layout/layout";
import CustomerCreateForm from "components/CustomerCreateForm";
import { useInternalUser } from "providers/InternalUserContext";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./header.css";

const { Text } = Typography;

const InternalUserTag: React.FC = () => {
  const { isInternalUser } = useInternalUser();
  if (isInternalUser) {
    return (
      <Col>
        <Text
          style={{
            backgroundColor: "#f5f5f5",
            color: "#222",
            padding: "5px 10px",
            border: "1px solid #222",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: "bold",
            margin: "0px 100px",
          }}
        >
          Internal ReferPro User
        </Text>
      </Col>
    );
  } else {
    return null;
  }
};

const LoggedInHeaderButtons = () => {
  const [visible, setVisible] = useState(false);
  const [createNewCustomer, setCreateNewCustomer] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showCreateCustomerDrawer = () => {
    setCreateNewCustomer(true);
    setVisible(true);
  };
  const showExistingCustomerDrawer = () => {
    setCreateNewCustomer(false);
    setVisible(true);
  };
  const hideDrawer = () => setVisible(false);

  return (
    <>
      <Col flex="auto">
        <Row
          justify="end"
          gutter={[24, 0]}
        >
          <Col className="">
            <Button
              type="default"
              size="large"
              shape="circle"
              onClick={showCreateCustomerDrawer}
            >
              <UserAddOutlined style={{ color: "#15616D" }} />
            </Button>
          </Col>
          <Col className="">
            <Button
              type="primary"
              size="large"
              shape="circle"
              onClick={showExistingCustomerDrawer}
            >
              <LinkOutlined />
            </Button>
          </Col>
        </Row>
      </Col>
      <Row gutter={[24, 0]}>
        <Col
          span={24}
          md={18}
          className="header-control"
        >
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            open={visible}
          >
            <CustomerCreateForm
              shouldCreateNewCustomer={createNewCustomer}
              onFinish={() => setVisible(false)}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

type HeaderStyleOverride = {
  backgroundColor?: string;
  backgroundImage?: string;
};

export const SignedOutHeader: React.FC<{
  allowSignIn?: boolean;
  headerStyleOverride?: HeaderStyleOverride;
}> = ({ allowSignIn = false, headerStyleOverride = {} }) => {
  const signedOutButtons = (
    <Col
      className="gutter-row"
      flex="auto"
    >
      <Row
        justify="end"
        gutter={[{ xs: 12, xl: 24 }, 0]}
      >
        <Col className="">
          <NavLink to="/sign-in">
            <Button
              type="default"
              size="large"
              shape="round"
            >
              Sign In
            </Button>
          </NavLink>
        </Col>
      </Row>
    </Col>
  );

  return (
    <Header
      style={{
        backgroundColor: headerStyleOverride?.backgroundColor || "#001524",
        borderRadius: "0",
      }}
      className="padding-mobile padding-desktop"
    >
      <Row>
        <Col>
          <img
            onClick={() => window.location.replace("https://getrefer.pro")}
            src={headerStyleOverride?.backgroundImage || "/bluelogo.png"}
            style={{ height: "40px", cursor: "pointer" }}
          />
        </Col>
        <InternalUserTag />
        {allowSignIn && signedOutButtons}
      </Row>
    </Header>
  );
};

export const SignedInHeader: React.FC<{}> = () => {
  return (
    <Header
      style={{
        backgroundColor: "#001524",
        borderRadius: "0",
      }}
      className="padding-mobile padding-desktop"
    >
      <Row>
        <Col>
          <NavLink to="/app">
            <img
              src="/bluelogo.png"
              style={{ height: "40px" }}
            />
          </NavLink>
        </Col>
        <InternalUserTag />
        <LoggedInHeaderButtons />
      </Row>
    </Header>
  );
};
