import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Descriptions, Empty, Skeleton, Space, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import {
  CoreCompanyFieldsFragment,
  ListReferralsQuery,
  Referral,
  User,
} from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import SearchableTable from "../../../components/SearchableTable";
import SearchSelect from "../../../components/searchSelect";
import { GET_COMPANY, LIST_REFERRALS } from "../../../data/queries";
import { formatDateTime } from "../../../lib/date";

const { Text } = Typography;

const ReferralsTableStyle = styled.div({
  "&.referralsTableRoot": {
    ".ant-table-row:hover": {
      cursor: "pointer",
    },
  },
});

const UPDATE_REFERRAL = gql`
  mutation UpdateReferral($input: UpdateReferralInput!) {
    updateReferral(input: $input) {
      referral {
        id
        assignedTo {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const columns: ColumnsType<Referral> = [
  {
    title: "Name",
    key: "name",
    render: (_, referral) => (
      <Text>
        {referral.firstName} {referral.lastName}
      </Text>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
];

const ReferralsTable: React.FC = () => {
  const [tableData, setTableDataWithKeys] = useState<any[]>();
  const [companyUsers, setCompanyUsers] = useState<
    Pick<User, "id" | "firstName" | "lastName">[]
  >([]);
  const { loading, error, data } = useQuery<ListReferralsQuery>(LIST_REFERRALS);
  const {
    loading: loadingCompanyQuery,
    error: errorCompanyQuery,
    data: dataCompanyQuery,
  } = useQuery<{ getCompany: CoreCompanyFieldsFragment }>(GET_COMPANY);

  const [updateReferral, updateReferralMeta] = useMutation(UPDATE_REFERRAL, {
    refetchQueries: [{ query: GET_COMPANY }, "GetCompanyQuery"],
  });

  useEffect(() => {
    if (ready && data.listReferrals) {
      setTableData(data.listReferrals);
    }
    if (!loadingCompanyQuery && !errorCompanyQuery) {
      setCompanyUsers(dataCompanyQuery.getCompany.users);
    }
  }, [data, dataCompanyQuery]);

  const setTableData = (data: any[]) => {
    setTableDataWithKeys(
      data.map((referral) => ({
        ...referral,
        key: referral.id,
      }))
    );
  };

  const ready = !loading && !error;

  const expandedRowRender = ({
    id,
    message,
    phone,
    email,
    referredBy,
    createdAt,
    assignedTo,
  }: Referral) => (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%" }}
    >
      <Descriptions
        title={"Info"}
        layout="vertical"
        colon={false}
        size="small"
        column={{ xs: 1, lg: 1 }}
      >
        <Descriptions.Item label="Referred By (Name)">
          <Text>
            {referredBy.firstName} {referredBy.lastName}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Referred By (Phone)">
          {referredBy.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{email}</Descriptions.Item>
        <Descriptions.Item label="Message">{message}</Descriptions.Item>
        {assignedTo && (
          <Descriptions.Item label="Assigned To">
            <Text>
              {assignedTo.firstName} {assignedTo.lastName}
            </Text>
          </Descriptions.Item>
        )}
      </Descriptions>
      {!assignedTo && (
        <div style={{ maxWidth: 400 }}>
          <SearchSelect
            options={companyUsers.map(({ id, firstName, lastName }) => {
              return { id, name: `${firstName} ${lastName}` };
            })}
            onChange={(userId) => {
              updateReferral({
                variables: {
                  input: {
                    referralId: id,
                    updateAttributes: {
                      assignedToUserId: userId,
                    },
                  },
                },
              });
            }}
            placeholder="Select someone to assign"
          />
        </div>
      )}
    </Space>
  );

  return (
    <ReferralsTableStyle className="referralsTableRoot">
      <FloatingCard>
        {loading && <Skeleton active />}
        {error && (
          <Empty
            description="Uh oh!"
            style={{ marginTop: 20 }}
          />
        )}
        {ready && (
          <SearchableTable
            columns={columns}
            data={tableData}
            expandedRowRender={expandedRowRender}
          />
        )}
      </FloatingCard>
    </ReferralsTableStyle>
  );
};

export default ReferralsTable;
