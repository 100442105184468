import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  GiftOutlined,
  InboxOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  List,
  Row,
  Skeleton,
  Typography,
} from "antd";
import CrossedCalendarOutlined from "assets/icons/CrossedCalendarOutlined";
import HungUpPhoneOutlined from "assets/icons/HungUpPhoneOutlined";
import { isEmailValid, isFormattedPhoneNumberValid } from "lib/utils";
import { useCompany } from "providers/CompanyContext";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FeedEntry,
  FeedGroup,
  Referral,
  ReferralType,
  Reward,
  RewardType,
} from "../../../__generated__/graphql"; // these are all types
import { COMPANY_IDS, RIGHT_BEFORE_EPOCH } from "../../../constants";
import { useReferral } from "../../../providers/ReferralContext";
import SendRewardDrawer from "./SendRewardDrawer";

const { Text } = Typography;

const getDescription = (
  time,
  description,
  reward?: Reward,
  phoneNumber?: string,
  email?: string,
  description2?: string
) => {
  let contactString = "";
  // verify phoneNumber and email are valid, if not, redeclare them as empty strings, this will prevent the incorrect setup of the contactString
  if (!isFormattedPhoneNumberValid(phoneNumber)) {
    phoneNumber = "";
  }
  if (!isEmailValid(email)) {
    email = "";
  }
  if (phoneNumber && email) {
    contactString = `Contact them at ${phoneNumber} or ${email}`;
  } else if (phoneNumber) {
    contactString = `Contact them at ${phoneNumber}`;
  } else if (email) {
    contactString = `Contact them at ${email}`;
  }
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Text>{description} </Text>
          </Row>
          <Row>
            {description2 && (
              <Text
                strong
                style={{ paddingTop: 2 }}
              >
                {description2}
              </Text>
            )}
          </Row>
          {contactString !== "" ? (
            <Col style={{ paddingTop: 2 }}>
              <Text italic>{contactString}</Text>
            </Col>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>{time}</Col>
      </Row>
      {reward && (
        <div>
          <Row style={{ height: 10 }}></Row>
          <Row>
            <Col style={{ marginLeft: 5, paddingTop: 2 }}>
              <Text italic={true}>
                {(() => {
                  switch (reward.type) {
                    case RewardType.CompanyManual:
                      return "Rewarded By Company";
                    case RewardType.Invalid:
                      return "Not Valid for Reward";
                    default:
                      `${(reward.amount / 100).toFixed(2)} payment is COMPLETE`;
                  }
                })()}
              </Text>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

const Group: React.FC<{
  data: FeedGroup;
  onSendRewardClick: (referralId: string, referredById: string) => void;
}> = ({ data, onSendRewardClick }) => {
  const formattedDate = new Date(data.date).toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const formattedTime = new Date(data.date)
    .toLocaleDateString("en-us", { hour: "numeric", minute: "numeric" })
    .split(",")[1];

  const isEmpty = (str: any) => !str || 0 === str.length;

  const FeedListItem = (feedEntry: FeedEntry) => {
    const [isSuccessfulClicked, setIsSuccessfulClicked] = useState(false);
    const [isContactedClicked, setIsContactedClicked] = useState(false);
    const [isBookedClicked, setIsBookedClicked] = useState(false);
    const [isCompletedClicked, setIsCompletedClicked] = useState(false);
    const [isArchivedClicked, setIsArchivedClicked] = useState(false);

    const [backgroundColorValue, setBackgroundColorValue] = useState("initial");
    const [avatarColorValue, setAvatarColorValue] = useState("initial");

    useEffect(() => {
      if (isCompletedClicked) {
        setBackgroundColorValue("#fef6e8");
        setAvatarColorValue("#dbd1b2");
      } else if (isSuccessfulClicked || (feedEntry as Referral).reward) {
        setBackgroundColorValue("#EEFEE8");
        setAvatarColorValue("#C9DBB2");
      } else if (isBookedClicked) {
        // use a blue
        setBackgroundColorValue("#e8f6ff");
        setAvatarColorValue("#b2cedb");
      } else if (isContactedClicked) {
        // use a yellow
        setBackgroundColorValue("#fdffe8");
        setAvatarColorValue("#dbdbb2");
      } else {
        setAvatarColorValue("initial");
        setBackgroundColorValue("initial");
      }
    }, [
      isSuccessfulClicked,
      isContactedClicked,
      isBookedClicked,
      isCompletedClicked,
    ]);

    const isReferral = (feedEntry: unknown): feedEntry is Referral =>
      (feedEntry as any).__typename === "Referral";
    const {
      markAsSuccessful,
      markAsUnsuccessful,
      markAsContacted,
      markAsNotContacted,
      markAsBooked,
      markAsNotBooked,
      markAsCompleted,
      markAsNotCompleted,
      markAsArchived,
      markAsNotArchived,
    } = useReferral();

    const { company } = useCompany();

    const handleMarkAsSuccessfulClick = async (entryId: string) => {
      if (!isSuccessfulClicked) {
        await markAsSuccessful(entryId);
        setIsSuccessfulClicked(true);
      } else {
        await markAsUnsuccessful(entryId);
        setIsSuccessfulClicked(false);
      }
    };

    const handleMarkAsContactedClick = async (entryId: string) => {
      if (!isContactedClicked) {
        await markAsContacted(entryId);
        setIsContactedClicked(true);
      } else {
        await markAsNotContacted(entryId);
        setIsContactedClicked(false);
      }
    };

    const handleMarkAsBookedClick = async (entryId: string) => {
      if (!isBookedClicked) {
        await markAsBooked(entryId);
        setIsBookedClicked(true);
      } else {
        await markAsNotBooked(entryId);
        setIsBookedClicked(false);
      }
    };

    const handleMarkAsCompletedClick = async (entryId: string) => {
      if (!isCompletedClicked) {
        await markAsCompleted(entryId);
        setIsCompletedClicked(true);
      } else {
        await markAsNotCompleted(entryId);
        setIsCompletedClicked(false);
      }
    };

    const handleMarkAsArchivedClick = async (entryId: string) => {
      if (!isArchivedClicked) {
        await markAsArchived(entryId);
        setIsArchivedClicked(true);
      } else {
        await markAsNotArchived(entryId);
        setIsArchivedClicked(false);
      }
    };

    useEffect(() => {
      if (isReferral(feedEntry)) {
        setIsArchivedClicked(
          feedEntry.markedAsArchivedDate !== null &&
            (feedEntry.markedAsArchivedDate as string) !== RIGHT_BEFORE_EPOCH
        );

        setIsCompletedClicked(
          feedEntry.markedAsCompletedDate !== null &&
            (feedEntry.markedAsCompletedDate as string) !== RIGHT_BEFORE_EPOCH
        );
        setIsSuccessfulClicked(
          feedEntry.markedAsSuccessfulDate !== null &&
            (feedEntry.markedAsSuccessfulDate as string) !== RIGHT_BEFORE_EPOCH // 1 millisecond before the unix epoch. This is equivalent, but faster than the below code. We are dealing with the unix epoch
        );
        setIsBookedClicked(
          feedEntry.markedAsBookedDate !== null &&
            (feedEntry.markedAsBookedDate as string) !== RIGHT_BEFORE_EPOCH
        );
        setIsContactedClicked(
          feedEntry.markedAsContactedDate !== null &&
            (feedEntry.markedAsContactedDate as string) !== RIGHT_BEFORE_EPOCH
        );
      }
    }, []);

    if (!isReferral(feedEntry)) {
      return;
    }

    const avatar = isReferral(feedEntry) ? (
      <CommentOutlined style={{ fontSize: 14 }} />
    ) : (
      <GiftOutlined style={{ fontSize: 14 }} />
    );

    return isArchivedClicked ? null : (
      <List.Item
        key={feedEntry.id}
        style={{
          backgroundColor: backgroundColorValue,
          marginTop: "10px",
          borderRadius: "10px",
        }}
      >
        <Row
          justify="start"
          style={{ width: "100%" }}
          align="middle"
        >
          <Col
            xs={24}
            xl={16}
            style={{ marginBottom: 10 }}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  size="default"
                  className={`text-fill backgroundMain`}
                  style={{ backgroundColor: avatarColorValue }}
                >
                  {avatar}
                </Avatar>
              }
              title={
                !feedEntry.type || feedEntry.type === ReferralType.Standard
                  ? `New Referral: ${feedEntry.firstName} ${feedEntry.lastName}`
                  : `New Lead: ${feedEntry.firstName} ${feedEntry.lastName}`
              }
              description={getDescription(
                formattedTime,
                !feedEntry.type || feedEntry.type === ReferralType.Standard
                  ? `Referred by ${feedEntry.referredBy.firstName} ${
                      isEmpty(feedEntry.referredBy.lastName)
                        ? ""
                        : feedEntry.referredBy.lastName
                    } through ReferPro`
                  : "Requested Service through ReferPro",
                feedEntry.reward,
                feedEntry.phone,
                feedEntry.email,
                feedEntry.referredByEmployee
                  ? `Gathered by ${feedEntry.referredByEmployee.firstName} ${feedEntry.referredByEmployee.lastName}`
                  : undefined
              )}
            />
          </Col>
          <Col
            xs={{ span: 21, offset: 3 }}
            sm={{ span: 22, offset: 2 }}
            xl={{ span: 22, offset: 0 }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <span className="text-success">
                {!feedEntry.reward && (
                  <Button
                    type="default"
                    shape="round"
                    onClick={() =>
                      onSendRewardClick(feedEntry.id, feedEntry.referredBy.id)
                    }
                    style={{
                      width: "220px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <GiftOutlined />
                    <span style={{ textAlign: "center", width: "100%" }}>
                      Send Reward{" "}
                      {feedEntry.type === ReferralType.Self
                        ? "to Lead"
                        : "for Referral"}
                    </span>
                  </Button>
                )}
              </span>
              <span className="text-success">
                {!feedEntry.reward && (
                  <Button
                    type="default"
                    shape="round"
                    onClick={() => handleMarkAsSuccessfulClick(feedEntry.id)}
                    style={{
                      width: "220px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* This double use of isSuccessfulClicked is logically redundant, but in place to preserve the styling */}
                    {isSuccessfulClicked ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )}
                    <span style={{ textAlign: "center", width: "100%" }}>
                      {isSuccessfulClicked
                        ? "Mark As Unsuccessful"
                        : "Mark as Successful"}
                    </span>
                  </Button>
                )}
              </span>
              <span className="text-success">
                {!feedEntry.reward && (
                  <Button
                    type="default"
                    shape="round"
                    onClick={() => handleMarkAsContactedClick(feedEntry.id)}
                    style={{
                      width: "220px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* This double use of isContactedClicked is logically redundant, but in place to preserve the styling */}
                    {isContactedClicked ? (
                      <HungUpPhoneOutlined />
                    ) : (
                      <PhoneOutlined />
                    )}
                    <span style={{ textAlign: "center", width: "100%" }}>
                      {isContactedClicked
                        ? "Mark As Not Contacted"
                        : "Mark as Contacted"}
                    </span>
                  </Button>
                )}
              </span>
              <span className="text-success">
                {!feedEntry.reward && (
                  <Button
                    type="default"
                    shape="round"
                    onClick={() => handleMarkAsBookedClick(feedEntry.id)}
                    style={{
                      width: "220px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* This double use of isBookedClicked is logically redundant, but in place to preserve the styling */}
                    {isBookedClicked ? (
                      <CrossedCalendarOutlined />
                    ) : (
                      <CalendarOutlined />
                    )}
                    <span style={{ textAlign: "center", width: "100%" }}>
                      {isBookedClicked
                        ? "Mark As Not Booked"
                        : "Mark as Booked"}
                    </span>
                  </Button>
                )}
              </span>
              {company &&
              company.id &&
              (company.id === COMPANY_IDS.TIMOTHYS_COOLEST_COMPANY ||
                company.id === COMPANY_IDS.BARE_NECESSITY.GILBERT ||
                company.id === COMPANY_IDS.BARE_NECESSITY.SCOTTSDALE ||
                company.id === COMPANY_IDS.BARE_NECESSITY.TUSCON) ? (
                <>
                  <span className="text-success">
                    {!feedEntry.reward && (
                      <Button
                        type="default"
                        shape="round"
                        onClick={() => handleMarkAsCompletedClick(feedEntry.id)}
                        style={{
                          width: "220px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* This double use of isCompletedClicked is logically redundant, but in place to preserve the styling */}
                        {isCompletedClicked ? (
                          <CloseCircleOutlined />
                        ) : (
                          <CheckCircleOutlined />
                        )}
                        <span style={{ textAlign: "center", width: "100%" }}>
                          {isCompletedClicked
                            ? "Mark As Incomplete"
                            : "Mark as Completed"}
                        </span>
                      </Button>
                    )}
                  </span>
                  <span className="text-success">
                    <Button
                      type="default"
                      shape="round"
                      onClick={() => handleMarkAsArchivedClick(feedEntry.id)}
                      style={{
                        width: "220px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <InboxOutlined />
                      <span style={{ textAlign: "center", width: "100%" }}>
                        Archive
                      </span>
                    </Button>
                  </span>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      </List.Item>
    );
  };
  return (
    <List
      header={<h5>{formattedDate}</h5>}
      itemLayout="horizontal"
      dataSource={data.entries}
      renderItem={FeedListItem}
    />
  );
};

const Feed: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [referralId, setReferralId] = useState("");
  const [referredById, setReferredById] = useState("");
  let history = useHistory();
  const hideDrawer = () => setDrawerVisible(false);

  const { referralFeed } = useReferral();
  const ready = useMemo(() => {
    return (
      !referralFeed.error &&
      referralFeed.data !== null &&
      referralFeed.data !== undefined && // Add null check for referralFeed.data
      !referralFeed.isLoading
    );
  }, [referralFeed.isLoading, referralFeed.data, referralFeed.error]);
  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
        className="header-solid h-full  ant-list-yes"
        style={{ borderRadius: 20, boxShadow: "0 6px 10px rgb(0 0 0 / 12%)" }}
      >
        {referralFeed.isLoading && (
          <Skeleton
            active
            style={{ marginTop: 20 }}
          />
        )}
        {referralFeed.error && (
          <Empty
            description="Nothing yet!"
            style={{ marginTop: 20 }}
          />
        )}
        {ready && referralFeed.data.groups.length === 0 && (
          <Empty
            description={
              <>
                <Row
                  justify="center"
                  style={{ width: "100%" }}
                  align="middle"
                >
                  <Col>Let's get started!</Col>
                </Row>
                <Row
                  justify="center"
                  style={{ width: "100%", marginTop: 10 }}
                  align="middle"
                >
                  <Col>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => history.push("/customers")}
                    >
                      Create New Customer
                    </Button>
                  </Col>
                </Row>
                <Row
                  justify="center"
                  style={{ width: "100%", marginTop: 10 }}
                  align="middle"
                >
                  <Col>
                    <Button
                      type="default"
                      shape="round"
                    >
                      Create Link for Existing Customer
                    </Button>
                  </Col>
                </Row>
              </>
            }
            style={{ marginTop: 20 }}
          />
        )}
        {ready &&
          referralFeed.data.groups.length > 0 &&
          referralFeed.data.groups.map((group, index) => (
            <Group
              key={index}
              data={group}
              onSendRewardClick={(referralId: string, referredById: string) => {
                setReferralId(referralId);
                setReferredById(referredById);
                setDrawerVisible(true);
              }}
            />
          ))}
      </Card>
      <SendRewardDrawer
        referralId={referralId}
        referredById={referredById}
        visible={drawerVisible}
        onClose={hideDrawer}
        onFinish={hideDrawer}
      />
    </>
  );
};

export default Feed;
