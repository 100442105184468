import { useQuery } from "@apollo/client";
import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  CoreCompanyFieldsFragment,
  ListReferralsQuery,
} from "../../__generated__/graphql";
import { GET_COMPANY, LIST_REFERRALS } from "../../data/queries";
import Content from "../../layout/Content";
import {
  ClickSummary,
  Feed,
  ReferralRewardSummary,
  ReferralsReceivedSummary,
} from "./components";

import { ReferralProvider } from "providers/ReferralContext";
import HomeStyle from "./HomeStyle";

interface Props {}

const Home: React.FC<Props> = () => {
  const listReferrals = useQuery<ListReferralsQuery>(LIST_REFERRALS);
  const getCompany = useQuery<{ getCompany: CoreCompanyFieldsFragment }>(
    GET_COMPANY,
    { fetchPolicy: "no-cache" }
  );

  const [referralsReceived, setReferralsReceived] = useState<number>();
  const [referralReward, setReferralReward] = useState<number>();
  const [click, setClick] = useState<number>();

  useEffect;
  useEffect(() => {
    const fetchData = async () => {
      setReferralReward(0);
    };

    if (listReferrals.data) {
      setReferralsReceived(listReferrals.data.listReferrals.length);
    }

    if (getCompany.data) {
      setClick(getCompany.data.getCompany.stats.clicksLast30Days);
    }

    fetchData();
  }, [getCompany, listReferrals]);

  return (
    <ReferralProvider>
      <HomeStyle className="homeStyleRoot">
        <Content>
          <Row style={{ width: "100%" }}>
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 14, offset: 1 }}
              lg={{ span: 14, offset: 1 }}
              xl={{ span: 14, offset: 1 }}
              className="mb20"
            >
              <Feed />
            </Col>
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 8, offset: 0 }}
            >
              <Space
                direction="vertical"
                size={20}
                className="w100"
              >
                <Row justify="center">
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    xl={{ span: 14 }}
                  >
                    <ReferralsReceivedSummary
                      loading={listReferrals.loading}
                      amount={referralsReceived}
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    xl={{ span: 14 }}
                  >
                    <ReferralRewardSummary amount={referralReward} />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    xl={{ span: 14 }}
                  >
                    <ClickSummary
                      loading={getCompany.loading}
                      amount={click}
                    />
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        </Content>
      </HomeStyle>
    </ReferralProvider>
  );
};

export default Home;
