import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export const INTERNAL_USER_FLAG_NAME = "internal_user_flag";

interface InternalUserContextType {
  isInternalUser: boolean;
  setIsInternalUser: (isInternalUser: boolean) => void;
}

const InternalUserContext = createContext<InternalUserContextType>({
  isInternalUser: false,
  setIsInternalUser: (isInternalUser: boolean) => {},
});

export const InternalUserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isInternalUser, setIsInternalUser] = useState(false);

  useEffect(() => {
    // Initialize state based on localStorage when the component mounts
    const flagValue = localStorage.getItem("internal_user_flag") === "true";
    setIsInternalUser(flagValue);
  }, []);

  function setInternalUserFlag(isInternalUser: boolean) {
    if (isInternalUser) {
      localStorage.setItem(INTERNAL_USER_FLAG_NAME, "true");
    } else {
      localStorage.removeItem(INTERNAL_USER_FLAG_NAME);
    }
    setIsInternalUser(isInternalUser);
  }

  return (
    <InternalUserContext.Provider
      value={{ isInternalUser, setIsInternalUser: setInternalUserFlag }}
    >
      {children}
    </InternalUserContext.Provider>
  );
};

export const useInternalUser = () => {
  const context = useContext(InternalUserContext);
  if (context === undefined) {
    throw new Error(
      "useInternalUser must be used within a InternalUserProvider"
    );
  }
  return context;
};
