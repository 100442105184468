import styled from "@emotion/styled";
import { Employee } from "__generated__/graphql";
import { Col, Layout, Row, Space, notification } from "antd";
import ReferralFormFooter from "pages/Referral/components/ReferralFormFooter";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import FloatingCard from "../../components/FloatingCard";
import { COMPANY_IDS } from "../../constants";
import { backgroundMain } from "../../theme";
import { SignedOutHeader } from "../Header";
const { Content } = Layout;

export interface CompanyBranding {
  logoUrl?: string;
  headerBackgroundColor?: string;
  bannerBackgroundColor?: string;
  youtubeVideoId?: string;
  alternateCompanyName?: string; // this is if the company wants to display a different name than the one in the database, not currently controlled by the backend
}

interface ComponentProps {
  className?: string;
  children?: ReactNode;
}

const Component: React.FC<ComponentProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

interface StyledComponentProps {
  companyId: string;
}

const ReferralFormLayoutStyle = styled(Component)<StyledComponentProps>((
  props
) => {
  const styles = {
    "&.ReferralFormLayoutRoot": {
      ".top-bar": {
        height: 100,
        width: "100%",
        ...backgroundMain,
        ...(props.companyId === COMPANY_IDS.LGCY && {
          backgroundColor: "rgb(254, 195, 85)",
        }),
      },
      ".top-bar-overlay": {
        top: -50,
        position: "relative" as "relative",
      },
      ".w100": {
        width: "100%",
      },
      "@keyframes bounce": {
        "0%": {
          transform: "scale(1)",
        },
        "50%": {
          transform: "scale(1.1)",
        },
        "100%": {
          transform: "scale(1)",
        },
      },
      "@keyframes wave": {
        "0%": {
          opacity: 0,
        },
        "30%": {
          opacity: 0,
        },
        "50%": {
          transform: "rotate(0)",
        },
        "55%": {
          transform: "rotate(-12deg)",
        },
        "60%": {
          transform: "rotate(-25deg)",
        },
        "65%": {
          transform: "rotate(-12deg)",
        },
        "70%": {
          transform: "rotate(5deg)",
        },
        "75%": {
          transform: "rotate(-12deg)",
        },
        "80%": {
          transform: "rotate(-25deg)",
        },
        "85%": {
          transform: "rotate(-12deg)",
        },
        "90%": {
          transform: "rotate(0deg)",
        },
        "95%": {
          transform: "rotate(3deg)",
        },
        "100%": {
          transform: "rotate(0)",
        },
      },
      ".waving-hand": {
        animation: "wave 1.6s ease-in-out",
        "transform-origin": "bottom",
      },
      ".sparkle-button": {
        animation: "bounce 0.3s ease-in-out",
      },
      "@keyframes slideIn": {
        "0%": {
          opacity: 0,
        },
        "50%": {
          opacity: 0,
          transform: "translateY(-5%)",
        },
        "100%": {
          opacity: "1",
          transform: "translateY(0)",
        },
      },
    },
  };

  // for performance this could be unrolled
  for (let i = 0; i < 8; i++) {
    styles["&.ReferralFormLayoutRoot"][`.appear-from-top-${i + 1}`] = {
      opacity: 0 /* Set initial opacity to hide the element */,
      animation: "slideIn 1.5s ease-out",
      "animation-delay": `${i * 0.5}s`,
      "animation-fill-mode":
        "forwards" /* Keep the final state after the animation ends */,
    };
  }

  return styles;
});

const Context = React.createContext({ name: "Default" });

export type PublicEmployee = Pick<Employee, "id" | "firstName" | "lastName">; // this is the employee fields that are accessible via the public GraphQL Queries

const ReferralFormLayout: React.FC<{
  companyId: string;
  referralBannerMessage?: string;
  isEmbedded?: boolean;
  companyBranding?: CompanyBranding;
  children: ReactNode;
}> = ({
  companyId,
  referralBannerMessage,
  isEmbedded = false,
  companyBranding = {},
  children,
}) => {
  // decompose the companyBranding object
  const { logoUrl, headerBackgroundColor, bannerBackgroundColor } =
    companyBranding;

  const [api, contextHolder] = notification.useNotification();

  const [shouldShowBanner, setShouldShowBanner] = useState<boolean>(
    !!referralBannerMessage
  );

  const specialCases = {
    "7c5d5c05-3fbb-40f3-87a9-3656a7a38982":
      "Earn a $30 restaurant gift card or 10% off your next service. (Labor Only - $200 Limit)", // Bravo Professional Contractors
    "e92fbdae-5a75-4bdf-a093-9da840407a9b":
      "Earn a FREE month's warranty extension for each successful referral!", // FormRx
    // Accounting
    "319e2e4b-0694-4d87-906e-c5008fc965f7":
      "Earn a 10% discount off your next tax or accounting service for each successful referral!",
    "21d1dfb5-07bd-4d71-951a-81638599cb78":
      "Earn $20 for a service call, $40 for a new door installation, $100 bonus for 5 referrals! Paid upon job completion.",
    "6bc51916-5e88-42aa-9ab1-8f521c3fbb4a":
      "Earn $25 for successful referrals!",
    "0e9832e1-e7b4-4e5a-884e-6f579b186360":
      "Earn 1 month free for successful referrals!",
    "e9d4e787-f83c-4b4a-9eaa-5ada52125f1e":
      "We're rewarding our customers with $25 gift cards that help us book out any projects, landscaping, or any lawn maintenance work!", // Beau's Landscaping
    "15965156-8f3a-4328-a7a3-e86e8126fb4f":
      "We are offering $25 for residential and $100 for commercial referrals!", // Bye Bye Cockroach
    "d2a8bc08-0f9f-4dba-a82e-cb1872930c31":
      "We are offering $10 for a referral that leads to a repair and $50 for a replacement AC!", // Service king
    "cd76fa6d-34bf-4e39-9760-70ff36dfe50d":
      "We are offering $25 toward future cleanings for successful referrals!", // Triangle Green Maids
    "30d8ab48-b52f-476b-bab2-d336d422c044":
      "We are offering $25 for every successful referral!", // The Window Guy
    "eed3473d-a6aa-4fa4-8b57-206f6de37cbd":
      "We're offering $25 off your next service for every successful referral you send our way!", // Best Cincinnati Chimney
    "485595a2-af9e-4cb1-a4dc-3470aad1938c":
      "We're offering $25 off your next service for every successful referral you send our way!", // Purpose Plumbing
  };

  const earn =
    companyId in specialCases ? specialCases[companyId] : referralBannerMessage;

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  useEffect(() => {
    const showBanner = () => {
      if (shouldShowBanner && !isEmbedded) {
        api.info({
          icon: <div style={{ paddingTop: 10 }}>💸</div>,
          message: <></>,
          description: (
            <div style={{ marginRight: 20, marginLeft: 0, marginTop: 0 }}>
              {earn}
            </div>
          ),
          placement: "topRight",
          duration: 30,
          onClose: () => setShouldShowBanner(false),
        });
      }
    };

    showBanner();
  }, []);

  return (
    <ReferralFormLayoutStyle
      companyId={companyId}
      className={isEmbedded ? "" : "ReferralFormLayoutRoot"}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Context.Provider value={contextValue}>
          {contextHolder}
          {isEmbedded ? (
            <></>
          ) : (
            <SignedOutHeader
              headerStyleOverride={{
                ...(headerBackgroundColor && {
                  backgroundColor: headerBackgroundColor,
                }),
                ...(logoUrl && { backgroundImage: logoUrl }),
              }}
            />
          )}
          <Content>
            <Layout
              style={{
                minHeight: "92vh",
                backgroundPosition: "center top",
                backgroundSize: "cover",
              }}
            >
              <Row
                className="top-bar w100"
                style={{
                  ...(bannerBackgroundColor && {
                    backgroundColor: bannerBackgroundColor,
                  }),
                }}
              ></Row>
              <Row
                className="top-bar-overlay"
                justify="center"
              >
                <Col
                  xs={{ span: 22 }}
                  style={{ maxWidth: 600 }}
                >
                  <FloatingCard>
                    <Space direction="vertical">{children}</Space>
                  </FloatingCard>
                </Col>
              </Row>
            </Layout>
            {isEmbedded ? "" : <ReferralFormFooter />}
          </Content>
        </Context.Provider>
      </Layout>
    </ReferralFormLayoutStyle>
  );
};

export default ReferralFormLayout;
