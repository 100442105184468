/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Campaign = {
  __typename?: "Campaign";
  campaignGroups?: Maybe<Array<CampaignGroup>>;
  config: CampaignConfig;
  createdAt: Scalars["DateTime"];
  deactivatedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  executions: Array<CampaignExecution>;
  finishedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type CampaignBatch = {
  __typename?: "CampaignBatch";
  createdAt: Scalars["DateTime"];
  finishedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  number: Scalars["Int"];
  offset: Scalars["Int"];
  size: Scalars["Int"];
  success?: Maybe<Scalars["Boolean"]>;
};

export type CampaignConfig = {
  __typename?: "CampaignConfig";
  customSendgridTemplateId?: Maybe<Scalars["String"]>;
  customTextReferralMessage?: Maybe<CustomTextMessage>;
  frequency: Scalars["Int"];
  iterations: Scalars["Int"];
  sendAtHour?: Maybe<Scalars["Int"]>;
  sendAtTimezone?: Maybe<Timezone>;
  startDate?: Maybe<Scalars["DateTime"]>;
};

export type CampaignExecution = {
  __typename?: "CampaignExecution";
  batches: Array<CampaignBatch>;
  createdAt: Scalars["DateTime"];
  finishedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  iteration: Scalars["Int"];
  nextBatchAt?: Maybe<Scalars["DateTime"]>;
  startsAt?: Maybe<Scalars["DateTime"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CampaignGroup = {
  __typename?: "CampaignGroup";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export enum CardBrand {
  Amex = "AMEX",
  Discover = "DISCOVER",
  Mastercard = "MASTERCARD",
  Unknown = "UNKNOWN",
  Visa = "VISA",
}

export type Click = FeedEntry & {
  __typename?: "Click";
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type Company = {
  __typename?: "Company";
  createdAt: Scalars["DateTime"];
  creationStatus: CompanyCreationStatus;
  customers: Array<Customer>;
  domain: Scalars["String"];
  id: Scalars["ID"];
  isSubscriptionActive: Scalars["Boolean"];
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  settings: CompanySettings;
  stats: CompanyStats;
  urlEncodedName: Scalars["String"];
  users: Array<User>;
  zapierApiToken: Scalars["String"];
};

export enum CompanyCreationStatus {
  Done = "DONE",
  Initializing = "INITIALIZING",
}

export type CompanySettings = {
  __typename?: "CompanySettings";
  referral: ReferralSettings;
  reward: RewardSettings;
};

export type CompanySettingsUpdateAttributes = {
  referral?: InputMaybe<ReferralSettingsUpdateAttributes>;
  reward?: InputMaybe<RewardSettingsUpdateAttributes>;
};

export type CompanyStats = {
  __typename?: "CompanyStats";
  clicksLast30Days: Scalars["Int"];
  messagesLast30Days: Scalars["Int"];
};

export type CompanyUpdateAttributes = {
  domain?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<CompanySettingsUpdateAttributes>;
};

export type CreateCampaignInput = {
  batchSize: Scalars["Int"];
  campaignGroupIds?: InputMaybe<Array<Scalars["ID"]>>;
  customSendgridTemplateId?: InputMaybe<Scalars["String"]>;
  customTextReferralMessage?: InputMaybe<CustomTextReferralMessageInput>;
  description?: InputMaybe<Scalars["String"]>;
  frequency: Scalars["Int"];
  iterations: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  sendAtHour: Scalars["Int"];
  sendAtTimezone: Timezone;
  sendEmails?: InputMaybe<Scalars["Boolean"]>;
  sendSMS?: InputMaybe<Scalars["Boolean"]>;
  startDate: Scalars["Int"];
};

export type CreateCampaignOutput = {
  __typename?: "CreateCampaignOutput";
  campaign: Campaign;
};

export type CreateCustomerInput = {
  campaignGroupIds?: InputMaybe<Array<Scalars["ID"]>>;
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerOutput = {
  __typename?: "CreateCustomerOutput";
  customer: Customer;
};

export type CreateEmployeeInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type CreateEmployeeOutput = {
  __typename?: "CreateEmployeeOutput";
  employee: Employee;
};

export type CreatePaymentMethodError = {
  __typename?: "CreatePaymentMethodError";
  message: Scalars["String"];
};

export type CreatePaymentMethodInput = {
  cardNumber: Scalars["String"];
  cvc: Scalars["Int"];
  expMonth: Scalars["Int"];
  expYear: Scalars["Int"];
  postalCode: Scalars["Int"];
};

export type CreatePaymentMethodOutput = {
  __typename?: "CreatePaymentMethodOutput";
  error?: Maybe<CreatePaymentMethodError>;
  paymentMethod?: Maybe<PaymentMethod>;
  success: Scalars["Boolean"];
};

export type CreateReferralInput = {
  linkId: Scalars["String"];
  referralInformation: ReferralInformation;
};

export type CreateReferralLinkError = {
  __typename?: "CreateReferralLinkError";
  message: Scalars["String"];
};

export type CreateReferralLinkInput = {
  customerId: Scalars["String"];
  deliveryMethods: Array<DeliveryMethod>;
};

export type CreateReferralLinkOutput = {
  __typename?: "CreateReferralLinkOutput";
  referralLink: ReferralLink;
};

export type CreateReferralOutput = {
  __typename?: "CreateReferralOutput";
  referral: Referral;
};

export type CreateSetupIntentError = {
  __typename?: "CreateSetupIntentError";
  message: Scalars["String"];
};

export type CreateSetupIntentOutput = {
  __typename?: "CreateSetupIntentOutput";
  clientSecret?: Maybe<Scalars["String"]>;
  error?: Maybe<CreateSetupIntentError>;
  success: Scalars["Boolean"];
};

export type CreateUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
};

export type CreateUserOutput = {
  __typename?: "CreateUserOutput";
  user: User;
};

export type CustomDiscountMessageInput = {
  body?: InputMaybe<Scalars["String"]>;
};

export type CustomMessageInput = {
  banner?: InputMaybe<Scalars["String"]>;
  body?: InputMaybe<Scalars["String"]>;
  greeting?: InputMaybe<Scalars["String"]>;
};

export type CustomNewReferralMessageInput = {
  body?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  greeting?: InputMaybe<Scalars["String"]>;
};

export type CustomTextMessage = {
  __typename?: "CustomTextMessage";
  body?: Maybe<Scalars["String"]>;
  greeting?: Maybe<Scalars["String"]>;
};

export type CustomTextReferralMessageInput = {
  body?: InputMaybe<Scalars["String"]>;
  greeting?: InputMaybe<Scalars["String"]>;
};

export type Customer = {
  __typename?: "Customer";
  campaignGroups?: Maybe<Array<CampaignGroup>>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  payoutMethod?: Maybe<PayoutMethod>;
  phone?: Maybe<Scalars["String"]>;
  referralLinks: Array<ReferralLink>;
  rewards: Array<Reward>;
};

export type CustomerSearchInfo = {
  firstName?: InputMaybe<StringSearchInput>;
};

export type CustomerUpdateAttributes = {
  campaignGroupIds?: InputMaybe<Array<Scalars["String"]>>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  payoutMethod?: InputMaybe<PayoutMethodUpdateAttributes>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type DeactivateCampaignInput = {
  campaignId: Scalars["ID"];
};

export type DeactivateCampaignOutput = {
  __typename?: "DeactivateCampaignOutput";
  campaign: Campaign;
};

export type DeleteCustomerInput = {
  customerId: Scalars["ID"];
};

export type DeleteCustomerOutput = {
  __typename?: "DeleteCustomerOutput";
  success: Scalars["Boolean"];
};

export type DeleteEmployeeInput = {
  employeeId: Scalars["ID"];
};

export type DeleteEmployeeOutput = {
  __typename?: "DeleteEmployeeOutput";
  success: Scalars["Boolean"];
};

export type DeletePaymentMethodInput = {
  paymentMethodId: Scalars["ID"];
};

export type DeletePaymentMethodOutput = {
  __typename?: "DeletePaymentMethodOutput";
  success: Scalars["Boolean"];
};

export enum DeliveryMethod {
  Email = "EMAIL",
  Text = "TEXT",
}

export enum DiscountType {
  Amount = "AMOUNT",
  Description = "DESCRIPTION",
  None = "NONE",
}

export type Employee = {
  __typename?: "Employee";
  companyId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  personalLink?: Maybe<ReferralLink>;
  phone?: Maybe<Scalars["String"]>;
};

export type EmployeeSearchInfo = {
  firstName?: InputMaybe<StringSearchInput>;
};

export type EmployeeUpdateAttributes = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type Feed = {
  __typename?: "Feed";
  groups: Array<FeedGroup>;
};

export type FeedEntry = {
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
};

export type FeedGroup = {
  __typename?: "FeedGroup";
  date: Scalars["DateTime"];
  entries: Array<FeedEntry>;
};

export type ListCustomersOutput = {
  __typename?: "ListCustomersOutput";
  customers: Array<Customer>;
  endCursor?: Maybe<Scalars["ID"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createCampaign: CreateCampaignOutput;
  createCustomer: CreateCustomerOutput;
  createEmployee: CreateEmployeeOutput;
  createPaymentMethod: CreatePaymentMethodOutput;
  createReferral: CreateReferralOutput;
  createReferralLink: CreateReferralLinkOutput;
  createSetupIntent: CreateSetupIntentOutput;
  createUser: CreateUserOutput;
  deactivateCampaign: DeactivateCampaignOutput;
  deleteCustomer: DeleteCustomerOutput;
  deleteEmployee: DeleteEmployeeOutput;
  deletePaymentMethod: DeletePaymentMethodOutput;
  sendReferralLink: SendReferralLinkOutput;
  sendReward: SendRewardOutput;
  updateCompany: UpdateCompanyOutput;
  updateCustomer: UpdateCustomerOutput;
  updateEmployee: UpdateEmployeeOutput;
  updateReferral: UpdateReferralOutput;
  updateUser: UpdateUserOutput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};

export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};

export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};

export type MutationCreateReferralLinkArgs = {
  input: CreateReferralLinkInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeactivateCampaignArgs = {
  input: DeactivateCampaignInput;
};

export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};

export type MutationDeleteEmployeeArgs = {
  input: DeleteEmployeeInput;
};

export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type MutationSendReferralLinkArgs = {
  input: SendReferralLinkInput;
};

export type MutationSendRewardArgs = {
  input: SendRewardInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};

export type MutationUpdateReferralArgs = {
  input: UpdateReferralInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type PaginationInfo = {
  after?: InputMaybe<Scalars["ID"]>;
  first: Scalars["Int"];
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  brand: CardBrand;
  expMonth: Scalars["Int"];
  expYear: Scalars["Int"];
  id: Scalars["ID"];
  lastFour: Scalars["String"];
};

export type PayoutMethod = {
  id: Scalars["ID"];
};

export type PayoutMethodUpdateAttributes = {
  venmoUsername: Scalars["String"];
};

export enum Permission {
  CreateLink = "CREATE_LINK",
  EmailLink = "EMAIL_LINK",
  SmsLink = "SMS_LINK",
}

export type Query = {
  __typename?: "Query";
  getCompany: Company;
  getEmployeeLeaderboard: GetEmployeeLeaderboardOutput;
  getFeed: Feed;
  getLoggedInUser: User;
  getUser: User;
  listCampaignGroups: Array<CampaignGroup>;
  listCampaigns: Array<Campaign>;
  listCustomers: ListCustomersOutput;
  listEmployeesWithPaginationInfo: ListEmployeesWithPaginationInfoOutput;
  listPaymentMethods: Array<PaymentMethod>;
  listReferrals: Array<Referral>;
  listTransactions: Array<Transaction>;
  searchCustomers: SearchCustomersOutput;
};

export type QueryGetUserArgs = {
  id: Scalars["ID"];
};

export type QueryListCustomersArgs = {
  paginationInfo?: InputMaybe<PaginationInfo>;
  searchInfo?: InputMaybe<CustomerSearchInfo>;
};

export type QueryListEmployeesWithPaginationInfoArgs = {
  paginationInfo?: InputMaybe<PaginationInfo>;
  searchInfo?: InputMaybe<EmployeeSearchInfo>;
};

export type QuerySearchCustomersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  searchText: Scalars["String"];
};

export type Referral = FeedEntry & {
  __typename?: "Referral";
  assignedTo?: Maybe<User>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  markedAsArchivedDate?: Maybe<Scalars["DateTime"]>;
  markedAsBookedDate?: Maybe<Scalars["DateTime"]>;
  markedAsCompletedDate?: Maybe<Scalars["DateTime"]>;
  markedAsContactedDate?: Maybe<Scalars["DateTime"]>;
  markedAsSuccessfulDate?: Maybe<Scalars["DateTime"]>;
  message?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  referredBy: Customer;
  referredByEmployee?: Maybe<Employee>;
  referredByLink?: Maybe<ReferralLink>;
  reward?: Maybe<Reward>;
  type: ReferralType;
};

export type ReferralInformation = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type ReferralLink = {
  __typename?: "ReferralLink";
  clicks: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  deliveries: Array<ReferralLinkDelivery>;
  id: Scalars["ID"];
  target: Scalars["String"];
};

export type ReferralLinkDelivery = {
  __typename?: "ReferralLinkDelivery";
  id: Scalars["ID"];
  method: DeliveryMethod;
  sentAt: Scalars["DateTime"];
  success: Scalars["Boolean"];
};

export type ReferralSettings = {
  __typename?: "ReferralSettings";
  bannerBackgroundColor?: Maybe<Scalars["String"]>;
  customDiscountMessage?: Maybe<ReferralSettingsCustomDiscountMessage>;
  customMessage?: Maybe<ReferralSettingsCustomMessage>;
  customNewReferralMessage?: Maybe<ReferralSettingsCustomNewReferralMessage>;
  defaultMessage?: Maybe<Scalars["String"]>;
  discountAmount?: Maybe<Scalars["Int"]>;
  discountDescription?: Maybe<Scalars["String"]>;
  discountType?: Maybe<DiscountType>;
  headerBackgroundColor?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  sendgridTemplateId?: Maybe<Scalars["String"]>;
  youtubeVideoId?: Maybe<Scalars["String"]>;
};

export type ReferralSettingsCustomDiscountMessage = {
  __typename?: "ReferralSettingsCustomDiscountMessage";
  body?: Maybe<Scalars["String"]>;
};

export type ReferralSettingsCustomMessage = {
  __typename?: "ReferralSettingsCustomMessage";
  banner?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  greeting?: Maybe<Scalars["String"]>;
};

export type ReferralSettingsCustomNewReferralMessage = {
  __typename?: "ReferralSettingsCustomNewReferralMessage";
  body?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  greeting?: Maybe<Scalars["String"]>;
};

export type ReferralSettingsUpdateAttributes = {
  bannerBackgroundColor?: InputMaybe<Scalars["String"]>;
  customDiscountMessage?: InputMaybe<CustomDiscountMessageInput>;
  customMessage?: InputMaybe<CustomMessageInput>;
  customNewReferralMessage?: InputMaybe<CustomNewReferralMessageInput>;
  defaultMessage?: InputMaybe<Scalars["String"]>;
  discountAmount?: InputMaybe<Scalars["Int"]>;
  discountDescription?: InputMaybe<Scalars["String"]>;
  discountType?: InputMaybe<DiscountType>;
  headerBackgroundColor?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
};

export enum ReferralType {
  Self = "SELF",
  Standard = "STANDARD",
}

export type ReferralUpdateAttributes = {
  assignedToUserId?: InputMaybe<Scalars["String"]>;
};

export type Reward = {
  __typename?: "Reward";
  amount: Scalars["Int"];
  id: Scalars["ID"];
  paidAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<RewardStatus>;
  type: RewardType;
};

export type RewardSettings = {
  __typename?: "RewardSettings";
  defaultAmount?: Maybe<Scalars["Int"]>;
};

export type RewardSettingsUpdateAttributes = {
  defaultAmount?: InputMaybe<Scalars["Int"]>;
};

export enum RewardStatus {
  Finalized = "FINALIZED",
  Processing = "PROCESSING",
  Unknown = "UNKNOWN",
}

export enum RewardType {
  CompanyManual = "COMPANY_MANUAL",
  Invalid = "INVALID",
  Tango = "TANGO",
  Unknown = "UNKNOWN",
  Venmo = "VENMO",
}

export type SearchCustomersOutput = {
  __typename?: "SearchCustomersOutput";
  customers: Array<Customer>;
};

export type SendReferralLinkInput = {
  deliveryMethods: Array<DeliveryMethod>;
  referralLinkId: Scalars["ID"];
};

export type SendReferralLinkOutput = {
  __typename?: "SendReferralLinkOutput";
  success: Scalars["Boolean"];
};

export type SendRewardError = {
  __typename?: "SendRewardError";
  message: Scalars["String"];
};

export type SendRewardInput = {
  amount: Scalars["Int"];
  customerId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
  referralId: Scalars["ID"];
  rewardType?: InputMaybe<RewardType>;
};

export type SendRewardOutput = {
  __typename?: "SendRewardOutput";
  error?: Maybe<SendRewardError>;
  success: Scalars["Boolean"];
};

export type StringSearchInput = {
  contains?: InputMaybe<Scalars["String"]>;
};

export enum Timezone {
  Central = "CENTRAL",
  Eastern = "EASTERN",
  Mountain = "MOUNTAIN",
  Pacific = "PACIFIC",
}

export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  paymentMethod: PaymentMethod;
};

export type UpdateCompanyInput = {
  updateAttributes: CompanyUpdateAttributes;
};

export type UpdateCompanyOutput = {
  __typename?: "UpdateCompanyOutput";
  company: Company;
};

export type UpdateCustomerInput = {
  customerId: Scalars["ID"];
  updateAttributes: CustomerUpdateAttributes;
};

export type UpdateCustomerOutput = {
  __typename?: "UpdateCustomerOutput";
  customer: Customer;
};

export type UpdateEmployeeInput = {
  employeeId: Scalars["ID"];
  updateAttributes: EmployeeUpdateAttributes;
};

export type UpdateEmployeeOutput = {
  __typename?: "UpdateEmployeeOutput";
  employee: Employee;
};

export type UpdateReferralInput = {
  referralId: Scalars["String"];
  updateAttributes: ReferralUpdateAttributes;
};

export type UpdateReferralOutput = {
  __typename?: "UpdateReferralOutput";
  referral: Referral;
};

export type UpdateUserInput = {
  updateAttributes: UserUpdateAttributes;
};

export type UpdateUserOutput = {
  __typename?: "UpdateUserOutput";
  user: User;
};

export type User = {
  __typename?: "User";
  company: Company;
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  permissions: Array<Permission>;
  phone?: Maybe<Scalars["String"]>;
};

export type UserUpdateAttributes = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type VenmoPayoutMethod = PayoutMethod & {
  __typename?: "VenmoPayoutMethod";
  id: Scalars["ID"];
  username: Scalars["String"];
};

export type EmployeeLeaderboardEntry = {
  __typename?: "employeeLeaderboardEntry";
  employee: Employee;
  totalReferrals: Scalars["Int"];
  totalRewardedReferrals: Scalars["Int"];
  totalSuccessfulReferrals: Scalars["Int"];
};

export type GetEmployeeLeaderboardOutput = {
  __typename?: "getEmployeeLeaderboardOutput";
  leaderboard: Array<EmployeeLeaderboardEntry>;
};

export type ListEmployeesWithPaginationInfoOutput = {
  __typename?: "listEmployeesWithPaginationInfoOutput";
  employees: Array<Employee>;
  endCursor?: Maybe<Scalars["ID"]>;
};

export type CreateReferralLinkMutationVariables = Exact<{
  input: CreateReferralLinkInput;
}>;

export type CreateReferralLinkMutation = {
  __typename?: "Mutation";
  createReferralLink: {
    __typename?: "CreateReferralLinkOutput";
    referralLink: {
      __typename?: "ReferralLink";
      id: string;
      target: string;
      createdAt: any;
    };
  };
};

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  createCustomer: {
    __typename?: "CreateCustomerOutput";
    customer: {
      __typename?: "Customer";
      id: string;
      firstName: string;
      lastName?: string | null;
    };
  };
};

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeInput;
}>;

export type CreateEmployeeMutation = {
  __typename?: "Mutation";
  createEmployee: {
    __typename?: "CreateEmployeeOutput";
    employee: {
      __typename?: "Employee";
      id: string;
      firstName: string;
      lastName?: string | null;
    };
  };
};

export type CoreCompanyFieldsFragment = {
  __typename?: "Company";
  id: string;
  name: string;
  domain: string;
  phone?: string | null;
  isSubscriptionActive: boolean;
  zapierApiToken: string;
  urlEncodedName: string;
  stats: {
    __typename?: "CompanyStats";
    clicksLast30Days: number;
    messagesLast30Days: number;
  };
  users: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phone?: string | null;
  }>;
  settings: {
    __typename?: "CompanySettings";
    referral: {
      __typename?: "ReferralSettings";
      logoUrl?: string | null;
      headerBackgroundColor?: string | null;
      bannerBackgroundColor?: string | null;
      defaultMessage?: string | null;
      discountAmount?: number | null;
      discountDescription?: string | null;
      discountType?: DiscountType | null;
      customMessage?: {
        __typename?: "ReferralSettingsCustomMessage";
        greeting?: string | null;
        body?: string | null;
        banner?: string | null;
      } | null;
      customDiscountMessage?: {
        __typename?: "ReferralSettingsCustomDiscountMessage";
        body?: string | null;
      } | null;
      customNewReferralMessage?: {
        __typename?: "ReferralSettingsCustomNewReferralMessage";
        greeting?: string | null;
        body?: string | null;
        enabled?: boolean | null;
      } | null;
    };
    reward: { __typename?: "RewardSettings"; defaultAmount?: number | null };
  };
} & { " $fragmentName"?: "CoreCompanyFieldsFragment" };

export type CoreUserFieldsFragment = {
  __typename?: "User";
  id: string;
  createdAt: any;
  firstName: string;
  lastName: string;
  phone?: string | null;
  email?: string | null;
  permissions: Array<Permission>;
} & { " $fragmentName"?: "CoreUserFieldsFragment" };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "CreateUserOutput";
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email?: string | null;
      phone?: string | null;
    };
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: "Mutation";
  updateCompany: {
    __typename?: "UpdateCompanyOutput";
    company: { __typename?: "Company" } & {
      " $fragmentRefs"?: {
        CoreCompanyFieldsFragment: CoreCompanyFieldsFragment;
      };
    };
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "UpdateUserOutput";
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email?: string | null;
      phone?: string | null;
      createdAt: any;
    };
  };
};

export type DeactivateCampaignMutationVariables = Exact<{
  input: DeactivateCampaignInput;
}>;

export type DeactivateCampaignMutation = {
  __typename?: "Mutation";
  deactivateCampaign: {
    __typename?: "DeactivateCampaignOutput";
    campaign: { __typename?: "Campaign"; id: string };
  };
};

export type DeleteCustomerMutationVariables = Exact<{
  input: DeleteCustomerInput;
}>;

export type DeleteCustomerMutation = {
  __typename?: "Mutation";
  deleteCustomer: { __typename?: "DeleteCustomerOutput"; success: boolean };
};

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer: {
    __typename?: "UpdateCustomerOutput";
    customer: { __typename?: "Customer"; id: string };
  };
};

export type DeleteEmployeeMutationVariables = Exact<{
  input: DeleteEmployeeInput;
}>;

export type DeleteEmployeeMutation = {
  __typename?: "Mutation";
  deleteEmployee: { __typename?: "DeleteEmployeeOutput"; success: boolean };
};

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;

export type UpdateEmployeeMutation = {
  __typename?: "Mutation";
  updateEmployee: {
    __typename?: "UpdateEmployeeOutput";
    employee: { __typename?: "Employee"; id: string };
  };
};

export type SendReferralLinkMutationVariables = Exact<{
  input: SendReferralLinkInput;
}>;

export type SendReferralLinkMutation = {
  __typename?: "Mutation";
  sendReferralLink: { __typename?: "SendReferralLinkOutput"; success: boolean };
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename?: "Mutation";
  createCampaign: {
    __typename?: "CreateCampaignOutput";
    campaign: { __typename?: "Campaign"; id: string; createdAt: any };
  };
};

export type GetCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyQuery = {
  __typename?: "Query";
  getCompany: { __typename?: "Company" } & {
    " $fragmentRefs"?: { CoreCompanyFieldsFragment: CoreCompanyFieldsFragment };
  };
};

export type GetEmployeeLeaderboardQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmployeeLeaderboardQuery = {
  __typename?: "Query";
  getEmployeeLeaderboard: {
    __typename?: "getEmployeeLeaderboardOutput";
    leaderboard: Array<{
      __typename?: "employeeLeaderboardEntry";
      totalReferrals: number;
      totalSuccessfulReferrals: number;
      totalRewardedReferrals: number;
      employee: {
        __typename?: "Employee";
        firstName: string;
        lastName?: string | null;
      };
    }>;
  };
};

export type GetFeedQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeedQuery = {
  __typename?: "Query";
  getFeed: {
    __typename?: "Feed";
    groups: Array<{
      __typename?: "FeedGroup";
      date: any;
      entries: Array<
        | { __typename?: "Click"; id: string; createdAt: any }
        | {
            __typename?: "Referral";
            firstName: string;
            lastName?: string | null;
            email?: string | null;
            message?: string | null;
            phone?: string | null;
            markedAsSuccessfulDate?: any | null;
            markedAsContactedDate?: any | null;
            markedAsBookedDate?: any | null;
            markedAsCompletedDate?: any | null;
            markedAsArchivedDate?: any | null;
            type: ReferralType;
            id: string;
            createdAt: any;
            referredBy: {
              __typename?: "Customer";
              id: string;
              firstName: string;
              lastName?: string | null;
            };
            referredByEmployee?: {
              __typename?: "Employee";
              id: string;
              firstName: string;
              lastName?: string | null;
            } | null;
            reward?: {
              __typename?: "Reward";
              id: string;
              amount: number;
              type: RewardType;
              status?: RewardStatus | null;
            } | null;
          }
      >;
    }>;
  };
};

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInUserQuery = {
  __typename?: "Query";
  getLoggedInUser: {
    __typename?: "User";
    company: { __typename?: "Company" } & {
      " $fragmentRefs"?: {
        CoreCompanyFieldsFragment: CoreCompanyFieldsFragment;
      };
    };
  } & { " $fragmentRefs"?: { CoreUserFieldsFragment: CoreUserFieldsFragment } };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "User";
    company: { __typename?: "Company" } & {
      " $fragmentRefs"?: {
        CoreCompanyFieldsFragment: CoreCompanyFieldsFragment;
      };
    };
  } & { " $fragmentRefs"?: { CoreUserFieldsFragment: CoreUserFieldsFragment } };
};

export type ListCustomersQueryVariables = Exact<{ [key: string]: never }>;

export type ListCustomersQuery = {
  __typename?: "Query";
  listCustomers: {
    __typename?: "ListCustomersOutput";
    customers: Array<{
      __typename?: "Customer";
      id: string;
      firstName: string;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      referralLinks: Array<{
        __typename?: "ReferralLink";
        id: string;
        target: string;
        createdAt: any;
        clicks: number;
        deliveries: Array<{
          __typename?: "ReferralLinkDelivery";
          sentAt: any;
          success: boolean;
          method: DeliveryMethod;
        }>;
      }>;
      campaignGroups?: Array<{
        __typename?: "CampaignGroup";
        id: string;
        name?: string | null;
      }> | null;
    }>;
  };
};

export type SearchCustomersQueryVariables = Exact<{
  searchText: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchCustomersQuery = {
  __typename?: "Query";
  searchCustomers: {
    __typename?: "SearchCustomersOutput";
    customers: Array<{
      __typename?: "Customer";
      id: string;
      firstName: string;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      referralLinks: Array<{
        __typename?: "ReferralLink";
        id: string;
        target: string;
        createdAt: any;
        clicks: number;
        deliveries: Array<{
          __typename?: "ReferralLinkDelivery";
          sentAt: any;
          success: boolean;
          method: DeliveryMethod;
        }>;
      }>;
      campaignGroups?: Array<{
        __typename?: "CampaignGroup";
        id: string;
        name?: string | null;
      }> | null;
    }>;
  };
};

export type ListCustomersPaginatedQueryVariables = Exact<{
  first: Scalars["Int"];
  after?: InputMaybe<Scalars["ID"]>;
}>;

export type ListCustomersPaginatedQuery = {
  __typename?: "Query";
  listCustomers: {
    __typename?: "ListCustomersOutput";
    endCursor?: string | null;
    customers: Array<{
      __typename?: "Customer";
      id: string;
      firstName: string;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      referralLinks: Array<{
        __typename?: "ReferralLink";
        id: string;
        target: string;
        createdAt: any;
        clicks: number;
        deliveries: Array<{
          __typename?: "ReferralLinkDelivery";
          sentAt: any;
          success: boolean;
          method: DeliveryMethod;
        }>;
      }>;
      campaignGroups?: Array<{
        __typename?: "CampaignGroup";
        id: string;
        name?: string | null;
      }> | null;
    }>;
  };
};

export type ListEmployeesPaginatedQueryVariables = Exact<{
  first: Scalars["Int"];
  after?: InputMaybe<Scalars["ID"]>;
}>;

export type ListEmployeesPaginatedQuery = {
  __typename?: "Query";
  listEmployeesWithPaginationInfo: {
    __typename?: "listEmployeesWithPaginationInfoOutput";
    endCursor?: string | null;
    employees: Array<{
      __typename?: "Employee";
      id: string;
      firstName: string;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      personalLink?: { __typename?: "ReferralLink"; target: string } | null;
    }>;
  };
};

export type ListReferralsQueryVariables = Exact<{ [key: string]: never }>;

export type ListReferralsQuery = {
  __typename?: "Query";
  listReferrals: Array<{
    __typename?: "Referral";
    id: string;
    firstName: string;
    lastName?: string | null;
    createdAt: any;
    email?: string | null;
    message?: string | null;
    phone?: string | null;
    referredBy: {
      __typename?: "Customer";
      id: string;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    };
    assignedTo?: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type ListCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCampaignsQuery = {
  __typename?: "Query";
  listCampaigns: Array<{
    __typename?: "Campaign";
    id: string;
    name?: string | null;
    createdAt: any;
    finishedAt?: any | null;
    deactivatedAt?: any | null;
    config: {
      __typename?: "CampaignConfig";
      iterations: number;
      frequency: number;
      sendAtHour?: number | null;
      sendAtTimezone?: Timezone | null;
    };
    executions: Array<{
      __typename?: "CampaignExecution";
      id: string;
      iteration: number;
      createdAt: any;
      finishedAt?: any | null;
      success?: boolean | null;
      startsAt?: any | null;
      nextBatchAt?: any | null;
      batches: Array<{
        __typename?: "CampaignBatch";
        id: string;
        number: number;
        finishedAt?: any | null;
        offset: number;
        size: number;
      }>;
    }>;
    campaignGroups?: Array<{
      __typename?: "CampaignGroup";
      id: string;
      name?: string | null;
      description?: string | null;
    }> | null;
  }>;
};

export type ListCampaignGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCampaignGroupsQuery = {
  __typename?: "Query";
  listCampaignGroups: Array<{
    __typename?: "CampaignGroup";
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type CreateSetupIntentMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateSetupIntentMutation = {
  __typename?: "Mutation";
  createSetupIntent: {
    __typename?: "CreateSetupIntentOutput";
    success: boolean;
    clientSecret?: string | null;
    error?: { __typename?: "CreateSetupIntentError"; message: string } | null;
  };
};

export type ListPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type ListPaymentMethodsQuery = {
  __typename?: "Query";
  listPaymentMethods: Array<{
    __typename?: "PaymentMethod";
    id: string;
    lastFour: string;
    brand: CardBrand;
    expYear: number;
    expMonth: number;
  }>;
};

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: "Mutation";
  createPaymentMethod: {
    __typename?: "CreatePaymentMethodOutput";
    success: boolean;
  };
};

export type ListTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListTransactionsQuery = {
  __typename?: "Query";
  listTransactions: Array<{
    __typename?: "Transaction";
    id: string;
    amount: number;
    createdAt: any;
  }>;
};

export type SendRewardMutationVariables = Exact<{
  input: SendRewardInput;
}>;

export type SendRewardMutation = {
  __typename?: "Mutation";
  sendReward: {
    __typename?: "SendRewardOutput";
    success: boolean;
    error?: { __typename?: "SendRewardError"; message: string } | null;
  };
};

export type UpdateReferralMutationVariables = Exact<{
  input: UpdateReferralInput;
}>;

export type UpdateReferralMutation = {
  __typename?: "Mutation";
  updateReferral: {
    __typename?: "UpdateReferralOutput";
    referral: {
      __typename?: "Referral";
      id: string;
      assignedTo?: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    };
  };
};

export const CoreCompanyFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreCompanyFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Company" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "domain" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isSubscriptionActive" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clicksLast30Days" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "messagesLast30Days" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "zapierApiToken" } },
          { kind: "Field", name: { kind: "Name", value: "urlEncodedName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "referral" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logoUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "headerBackgroundColor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bannerBackgroundColor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "defaultMessage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discountAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discountDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discountType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customMessage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "greeting" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "body" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "banner" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customDiscountMessage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "body" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "customNewReferralMessage",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "greeting" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "body" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "enabled" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reward" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "defaultAmount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreCompanyFieldsFragment, unknown>;
export const CoreUserFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreUserFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "permissions" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreUserFieldsFragment, unknown>;
export const CreateReferralLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateReferralLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateReferralLinkInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createReferralLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "referralLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "target" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReferralLinkMutation,
  CreateReferralLinkMutationVariables
>;
export const CreateCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateCustomerInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const CreateEmployeeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateEmployee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateEmployeeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createEmployee" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables
>;
export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateCompanyInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreCompanyFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CoreCompanyFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeactivateCampaignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeactivateCampaign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeactivateCampaignInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deactivateCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaign" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeactivateCampaignMutation,
  DeactivateCampaignMutationVariables
>;
export const DeleteCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteCustomerInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;
export const UpdateCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateCustomerInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const DeleteEmployeeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteEmployee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteEmployeeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteEmployee" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEmployeeMutation,
  DeleteEmployeeMutationVariables
>;
export const UpdateEmployeeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateEmployee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateEmployeeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateEmployee" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
>;
export const SendReferralLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendReferralLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SendReferralLinkInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendReferralLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendReferralLinkMutation,
  SendReferralLinkMutationVariables
>;
export const CreateCampaignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCampaign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateCampaignInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaign" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const GetCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompany" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getCompany" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreCompanyFields" },
                },
              ],
            },
          },
        ],
      },
    },
    ...CoreCompanyFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetEmployeeLeaderboardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetEmployeeLeaderboard" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getEmployeeLeaderboard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leaderboard" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "employee" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalReferrals" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "totalSuccessfulReferrals",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalRewardedReferrals" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmployeeLeaderboardQuery,
  GetEmployeeLeaderboardQueryVariables
>;
export const GetFeedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFeed" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFeed" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "groups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entries" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "Referral" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "referredBy" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "referredByEmployee",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reward" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "markedAsSuccessfulDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "markedAsContactedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "markedAsBookedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "markedAsCompletedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "markedAsArchivedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeedQuery, GetFeedQueryVariables>;
export const GetLoggedInUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLoggedInUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getLoggedInUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreUserFields" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreCompanyFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CoreUserFieldsFragmentDoc.definitions,
    ...CoreCompanyFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables
>;
export const GetUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CoreUserFields" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CoreCompanyFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CoreUserFieldsFragmentDoc.definitions,
    ...CoreCompanyFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const ListCustomersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListCustomers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listCustomers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "referralLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deliveries" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sentAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "success" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "method" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "clicks" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "campaignGroups" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListCustomersQuery, ListCustomersQueryVariables>;
export const SearchCustomersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCustomers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchText" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchCustomers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchText" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchText" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "referralLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deliveries" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sentAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "success" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "method" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "clicks" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "campaignGroups" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchCustomersQuery,
  SearchCustomersQueryVariables
>;
export const ListCustomersPaginatedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListCustomersPaginated" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listCustomers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationInfo" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "referralLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deliveries" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sentAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "success" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "method" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "clicks" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "campaignGroups" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "endCursor" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListCustomersPaginatedQuery,
  ListCustomersPaginatedQueryVariables
>;
export const ListEmployeesPaginatedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListEmployeesPaginated" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listEmployeesWithPaginationInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationInfo" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "after" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "personalLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "endCursor" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListEmployeesPaginatedQuery,
  ListEmployeesPaginatedQueryVariables
>;
export const ListReferralsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListReferrals" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listReferrals" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "referredBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignedTo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListReferralsQuery, ListReferralsQueryVariables>;
export const ListCampaignsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListCampaigns" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listCampaigns" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "finishedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deactivatedAt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "iterations" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "frequency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sendAtHour" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sendAtTimezone" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "executions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "iteration" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finishedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startsAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nextBatchAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "batches" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "number" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "finishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offset" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaignGroups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListCampaignsQuery, ListCampaignsQueryVariables>;
export const ListCampaignGroupsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListCampaignGroups" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listCampaignGroups" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListCampaignGroupsQuery,
  ListCampaignGroupsQueryVariables
>;
export const CreateSetupIntentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSetupIntent" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSetupIntent" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "error" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientSecret" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSetupIntentMutation,
  CreateSetupIntentMutationVariables
>;
export const ListPaymentMethodsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListPaymentMethods" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listPaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastFour" } },
                { kind: "Field", name: { kind: "Name", value: "brand" } },
                { kind: "Field", name: { kind: "Name", value: "expYear" } },
                { kind: "Field", name: { kind: "Name", value: "expMonth" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListPaymentMethodsQuery,
  ListPaymentMethodsQueryVariables
>;
export const CreatePaymentMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePaymentMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreatePaymentMethodInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPaymentMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const ListTransactionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListTransactions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListTransactionsQuery,
  ListTransactionsQueryVariables
>;
export const SendRewardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendReward" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SendRewardInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendReward" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "error" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendRewardMutation, SendRewardMutationVariables>;
export const UpdateReferralDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateReferral" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateReferralInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateReferral" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "referral" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignedTo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReferralMutation,
  UpdateReferralMutationVariables
>;
