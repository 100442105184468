import { Col, Layout, Row } from "antd";
import React from "react";
import SetUpForm from "./components/SetUpForm";

const { Content } = Layout;

const SetUp: React.FC<{ setReferProToken: (token: string) => void }> = ({
  setReferProToken,
}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const userId = params.link;

  if (!userId) {
    // throw new Error("No user id found");
  }

  return (
    <Content className="p-0">
      <div className="sign-up-header" />
      <Row
        gutter={[24, 0]}
        justify="center"
        style={{ padding: 15 }}
      >
        <Col
          flex="auto"
          xs={{ span: 22 }}
          sm={{ span: 18 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Row
            justify="center"
            style={{ width: "100%" }}
          >
            <Col span={22}>
              <SetUpForm
                userId={userId}
                setReferProToken={setReferProToken}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default SetUp;
