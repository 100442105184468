import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Skeleton } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LIST_CAMPAIGN_GROUPS } from "data/queries";
import React, { useEffect, useState } from "react";
import {
  CampaignGroup as CampaignGroupGql,
  ListCampaignGroupsQuery,
} from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import SearchableTable from "../../../components/SearchableTable";

const CampaignGroupsStyle = styled.div({
  "&.CampaignGroupsRoot": {
    ".ant-table-row:hover": {
      cursor: "pointer",
    },
  },
});

interface TableProps {
  style?: React.CSSProperties;
}

const columns: ColumnsType<CampaignGroupGql> = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
  },
];

const CampaignGroupsTable: React.FC<TableProps> = ({ style }) => {
  const [tableData, setTableData] = useState<CampaignGroupGql[]>();

  const listCampaignGroups =
    useQuery<ListCampaignGroupsQuery>(LIST_CAMPAIGN_GROUPS);
  useEffect(() => {
    if (listCampaignGroups.data) {
      setTableData(listCampaignGroups.data.listCampaignGroups);
    }
  }, [listCampaignGroups.data]);

  const isTableDataReady = !!tableData;

  return (
    <CampaignGroupsStyle className="CampaignGroupsRoot">
      <FloatingCard
        title={"Campaign Groups"}
        style={style || {}}
      >
        {!isTableDataReady && <Skeleton active />}
        {isTableDataReady && (
          <SearchableTable
            columns={columns}
            data={tableData}
            expandedRowRender={null}
          />
        )}
      </FloatingCard>
    </CampaignGroupsStyle>
  );
};

export default CampaignGroupsTable;
