export const prettyHour = (hour: number) => {
  if (hour === 0) {
    return "Midnight";
  }

  if (hour === 12) {
    return "Noon";
  }

  return `${hour % 12}:00 ${hour > 12 ? " pm" : " am"}`;
};
