import { gql, useQuery } from "@apollo/client";
import { Card, Empty, Skeleton, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import {
  ListTransactionsQuery,
  Transaction,
} from "../../../__generated__/graphql";

const { Text } = Typography;

const LIST_TRANSACTIONS = gql`
  query ListTransactions {
    listTransactions {
      id
      amount
      createdAt
    }
  }
`;

const columns: ColumnsType<Transaction> = [
  {
    title: "Date",
    key: "date",
    render: (_, transaction) => {
      const parts = new Date(transaction.createdAt).toString().split(" ");
      const month = parts[1];
      const day = parts[2];
      const year = parts[3];
      const time = parts[4];
      return <Text>{`${month} ${day}, ${year} ${time}`}</Text>;
    },
  },
  {
    title: "Amount",
    key: "referredBy",
    render: (_, transaction) => (
      <Text>${(transaction.amount / 100).toFixed(2)}</Text>
    ),
  },
];

const Transactions: React.FC = () => {
  const { data, error, loading } =
    useQuery<ListTransactionsQuery>(LIST_TRANSACTIONS);

  const ready = !error && !loading;

  return (
    <Card
      bordered={false}
      className="header-solid h-full  ant-list-yes"
      style={{ borderRadius: 20, boxShadow: "0 6px 10px rgb(0 0 0 / 12%)" }}
      title="Transactions"
    >
      {loading && <Skeleton active />}
      {error && (
        <Empty
          description="Uh oh!"
          style={{ marginTop: 20 }}
        />
      )}
      {ready && (
        <Table
          columns={columns}
          dataSource={data.listTransactions}
        />
      )}
    </Card>
  );
};

export default Transactions;
