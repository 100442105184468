import { EditOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { User } from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import Toast from "../../../components/Toast";
import { useCompany } from "../../../providers/CompanyContext";

const { Title } = Typography;

const UserProfile: React.FC = () => {
  const { user, updateUser } = useCompany();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedData, setEditedData] = useState<any>({});

  const onEditClick = (user: User) => {
    setEditMode(true);
    setEditedData(user);
  };

  const onSaveClick = async () => {
    try {
      await updateUser({
        ...(editedData && { email: editedData.email }),
        ...(editedData && { phone: editedData.phone }),
      });
      setEditMode(false);
      setEditedData({});
      Toast("success", "Update user success!");
    } catch (e) {
      Toast("error", "Error saving user");
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setEditedData({});
  };

  const onInputChange = (field: keyof User, value: string) => {
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const renderDescriptionsItem = (
    label: string,
    value: string,
    field: keyof User
  ) => {
    if (editMode) {
      return (
        <Descriptions.Item label={label}>
          <Input
            value={editedData[field] ?? value}
            onChange={(e) => onInputChange(field, e.target.value)}
          />
        </Descriptions.Item>
      );
    } else {
      return <Descriptions.Item label={label}>{value}</Descriptions.Item>;
    }
  };

  return (
    <FloatingCard
      title={
        <Title
          level={3}
          style={{ marginTop: 12 }}
        >
          <Row gutter={[10, 10]}>
            <Col>
              <Avatar icon={<UserOutlined />} />
            </Col>
            <Col style={{ paddingTop: 7 }}>
              Profile ({user.firstName} {user.lastName})
            </Col>
          </Row>
        </Title>
      }
      extra={
        <>
          {editMode ? (
            <Space direction="horizontal">
              <Button
                type="primary"
                onClick={onSaveClick}
              >
                Save
              </Button>
              <Button onClick={onCancelClick}>Cancel</Button>
            </Space>
          ) : (
            <Button
              onClick={() => onEditClick(user)}
              style={{ border: "0", fontSize: "0.9rem" }}
            >
              <EditOutlined style={{ fontSize: "1.1rem" }} /> Edit
            </Button>
          )}
        </>
      }
    >
      <Row
        gutter={10}
        style={{ width: "100%" }}
      >
        <Descriptions
          layout="vertical"
          colon={false}
          size="small"
        >
          {renderDescriptionsItem("Email", user.email, "email")}
          {renderDescriptionsItem("Phone", user.phone, "phone")}
        </Descriptions>
      </Row>
    </FloatingCard>
  );
};

export default UserProfile;
