import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  GetLinkQuery,
  GetLinkQueryVariables,
} from "__generated__/public/graphql";
import {
  Button,
  Col,
  Divider,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import TypingText from "components/TypingText";
import ReferralFormLayout, {
  CompanyBranding,
} from "layout/referralForm/ReferralForm";
import { getAlternateCompanyName } from "lib/utils";
import React, { useEffect, useState } from "react";
import { GET_LINK } from "./CollectReferralData";
const { Text } = Typography;

interface Props {
  target: string;
}

const ThankYouForReferral: React.FC<Props> = ({ target }) => {
  const [companyId, setCompanyId] = useState<string>("");
  let [companyBranding, setCompanyBranding] = useState<CompanyBranding>({});
  target = target.replace("http://localhost:3000", "https://referpro.co");
  const returnLink = target.replace("/thank-you", "");
  // currently only used for Dazzle, so it is commented out. When we have other overrides, we can use this.
  const { loading, data, error } = useQuery<
    GetLinkQuery,
    GetLinkQueryVariables
  >(GET_LINK, { variables: { target } });
  useEffect(() => {
    if (!error && !loading && data) {
      const companyId = data?.getLink.company.id;
      setCompanyId(companyId);
      setCompanyBranding({
        logoUrl: data?.getLink.company.settings.referral.logoUrl,
        headerBackgroundColor:
          data?.getLink.company.settings.referral.headerBackgroundColor,
        bannerBackgroundColor:
          data?.getLink.company.settings.referral.bannerBackgroundColor,
        alternateCompanyName: getAlternateCompanyName(companyId),
      });
    }
  }, [error, data, loading]);
  if (loading) {
    return (
      <Result
        style={{ marginTop: 100 }}
        icon={
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24 }}
                spin
              />
            }
            size="large"
          />
        }
        title="One moment . . ."
      />
    );
  }

  return (
    <ReferralFormLayout
      companyId={companyId}
      companyBranding={companyBranding}
    >
      <Row
        justify="center"
        style={{ marginBottom: 10 }}
      >
        <Col>
          <Text style={{ fontWeight: 200, fontSize: "24px" }}>
            <Row>
              <Space direction="horizontal">
                <Col>
                  <TypingText
                    text={"Thanks for the referral!"}
                    duration={40}
                    style={{
                      fontWeight: 200,
                      fontSize: "24px",
                    }}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      fontWeight: 200,
                      fontSize: "24px",
                    }}
                    className="thank-you-hands"
                  >
                    🙌
                  </div>
                </Col>
              </Space>
            </Row>
          </Text>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Text
            className="appear-from-top"
            style={{ fontWeight: 200, fontSize: "16px" }}
          >
            <Row>
              <Space direction="horizontal">
                <Col>
                  <TypingText
                    text={
                      "We've received your referral and will reach out to them soon."
                    }
                    duration={40}
                    style={{
                      fontWeight: 200,
                      fontSize: "20px",
                    }}
                  />
                </Col>
              </Space>
            </Row>
          </Text>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col>
          <Text style={{ fontWeight: 200, fontSize: "16px" }}>
            <Row>
              <Space direction="horizontal">
                <Col>
                  <Button
                    type="primary"
                    className="appear-from-top-2"
                    style={{ color: "white" }}
                    href={`${returnLink}`}
                  >
                    <Space direction="horizontal">
                      Submit Another Referral
                    </Space>
                  </Button>
                </Col>
              </Space>
            </Row>
          </Text>
        </Col>
      </Row>
    </ReferralFormLayout>
  );
};

export default ThankYouForReferral;
