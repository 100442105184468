import { CheckCircleOutlined, PhoneOutlined } from "@ant-design/icons";
import { Input, Layout, Typography } from "antd";
import React, { useState } from "react";
const { Content, Footer } = Layout;
const { Text } = Typography;

const PhoneInput: React.FC<{
  disabled?: boolean;
  onPhoneNumberChange: (phoneNumber: string) => void;
  placeholder?: string;
}> = ({
  disabled,
  onPhoneNumberChange,
  placeholder = "Enter their phone number",
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const isPhoneValid = () => phoneNumber.length === 14;

  const formatPhoneNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    onPhoneNumberChange(numericValue);

    let formattedValue = "";

    if (numericValue.length < 4) {
      formattedValue = numericValue;
    } else if (numericValue.length < 7) {
      formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else {
      formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(
        3,
        6
      )}-${numericValue.slice(6, 10)}`;
    }

    setPhoneNumber(formattedValue);
  };

  return (
    <Input
      disabled={disabled !== undefined ? disabled : false}
      size="large"
      value={phoneNumber}
      placeholder={placeholder}
      onChange={(e) => formatPhoneNumber(e.target.value)}
      style={{
        ...(isPhoneValid() && {
          borderColor: "#008000e3",
        }),
        background: "transparent",
      }}
      prefix={
        <PhoneOutlined
          style={{ ...(isPhoneValid() && { color: "#008000e3" }) }}
        />
      }
      suffix={
        isPhoneValid() ? (
          <CheckCircleOutlined style={{ color: "#008000e3" }} />
        ) : undefined
      }
    />
  );
};

export default PhoneInput;
