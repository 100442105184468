import {
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Spin,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { User } from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import SearchableTable from "../../../components/SearchableTable";
import { useCompany } from "../../../providers/CompanyContext";

const { Title } = Typography;

const EditUserDrawer: React.FC<{
  user: User;
  visible: boolean;
  onClose: () => void;
}> = ({ user, visible, onClose }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { updateUser } = useCompany();

  const submitForm = async () => {
    setIsEditing(true);
    // await updateUser({
    //   // this checks if the user's first name is different from the new first name
    //   ...(user.firstName !== firstName && {
    //     firstName,
    //   }),
    //   ...(user.lastName !== lastName && {
    //     lastName,
    //   }),
    //   ...(user.email !== email && {
    //     email,
    //   }),
    //   ...(user.phone !== phone && {
    //     phone,
    //   }),
    // });
    setIsEditing(false);
    onClose();
  };
  if (!user) return null;
  return (
    <>
      <Drawer
        open={visible}
        onClose={onClose}
        title="Edit User Info"
      >
        <Form
          onFinish={submitForm}
          size="middle"
          scrollToFirstError
        >
          <Form.Item
            name="firstName"
            initialValue={user.firstName}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="First name"
              prefix={<UserOutlined />}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            initialValue={user.lastName}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Last name"
              prefix={<UserOutlined />}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            initialValue={user.email}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Email"
              prefix={<MailOutlined />}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            initialValue={user.phone}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Phone number"
              prefix={<PhoneOutlined />}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
          <Spin spinning={isEditing}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              size="large"
              onClick={submitForm}
            >
              Update User
            </Button>
          </Spin>
        </Form>
      </Drawer>
    </>
  );
};

const CreateUserDrawer: React.FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { createUser } = useCompany();

  const readyToSubmit = firstName && lastName && email && phone;

  const submitForm = async () => {
    setIsCreating(true);
    await createUser(firstName, lastName, email, phone);
    setIsCreating(false);
    onClose();
  };

  return (
    <>
      <Drawer
        open={visible}
        onClose={onClose}
        title="Create New User"
      >
        <Form
          onFinish={submitForm}
          size="middle"
          scrollToFirstError
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input a first name!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="First name"
              prefix={<UserOutlined />}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input a last name!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Last name"
              prefix={<UserOutlined />}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input an email!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Email"
              prefix={<MailOutlined />}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input a phone number!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Phone number"
              prefix={<PhoneOutlined />}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
          <Spin spinning={isCreating}>
            <Button
              type={readyToSubmit ? "primary" : "default"}
              disabled={!readyToSubmit}
              style={{ width: "100%" }}
              size="large"
              onClick={submitForm}
            >
              Create User
            </Button>
          </Spin>
        </Form>
      </Drawer>
    </>
  );
};

const Users: React.FC = () => {
  const columns: ColumnsType<User> = [
    {
      title: "Name",
      key: "name",
      render: (_, user) => (
        <Typography.Text>
          {user.firstName} {user.lastName}
        </Typography.Text>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (_, user) => <Typography.Text>{user.email}</Typography.Text>,
    },
    {
      title: "Phone",
      key: "phone",
      render: (_, user) => <Typography.Text>{user.phone}</Typography.Text>,
    },
    // {
    //   title: "",
    //   key: "edit",
    //   render: (_, user) => (
    //     <Button
    //       type="ghost"
    //       style={{ width: "100%" }}
    //       size="large"
    //       onClick={() => {
    //         setUserToEdit(user);
    //         setEditVisible(true);
    //       }}
    //     >
    //       <EditOutlined />
    //     </Button>
    //   ),
    // },
  ];

  const [users, setUsers] = useState<
    Pick<User, "id" | "firstName" | "lastName">[]
  >([]);
  const [createVisible, setCreateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const { company } = useCompany();

  useEffect(() => {
    if (company) {
      setUsers(company.users);
    }
  }, [company]);

  return (
    <FloatingCard
      style={{ width: "100%" }}
      title={
        <Title
          level={3}
          style={{ marginTop: 12 }}
        >
          <Row gutter={[10, 10]}>
            <Col>
              <Avatar icon={<TeamOutlined />} />
            </Col>
            <Col style={{ paddingTop: 7 }}>Users</Col>
          </Row>
        </Title>
      }
      extra={
        <Button
          style={{ marginLeft: "20px", alignItems: "center", display: "flex" }}
          type="ghost"
          onClick={() => setCreateVisible(true)}
        >
          <UserAddOutlined style={{ fontSize: "20px" }} /> Create
        </Button>
      }
    >
      <Row
        gutter={10}
        style={{ width: "100%" }}
      >
        <CreateUserDrawer
          visible={createVisible}
          onClose={() => setCreateVisible(false)}
        />
        <EditUserDrawer
          user={userToEdit}
          visible={editVisible}
          onClose={() => {
            setEditVisible(false);
            setUserToEdit(null);
          }}
        />
        <SearchableTable
          columns={columns}
          data={(company?.users || []).map((user) => ({
            ...user,
            key: user.id,
          }))}
          expandedRowRender={null}
        />
      </Row>
    </FloatingCard>
  );
};

export default Users;
