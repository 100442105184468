import {
  CheckCircleOutlined,
  LinkOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Col, Empty, Row, Skeleton, Space, Spin, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import {
  Customer as GQLCustomer,
  ListCustomersPaginatedQuery,
  ListCustomersPaginatedQueryVariables,
  SearchCustomersQuery,
  SearchCustomersQueryVariables,
} from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import SearchableTable from "../../../components/SearchableTable";
import {
  LIST_CUSTOMERS_PAGINATED,
  SEARCH_CUSTOMERS,
} from "../../../data/queries";
import ExpandedCustomerRow from "./ExpandedCustomerRow";

const { Text } = Typography;

const CustomersTableStyle = styled.div({
  "&.customersTableRoot": {
    ".ant-table-row:hover": {
      cursor: "pointer",
    },
  },
});

const columns: ColumnsType<GQLCustomer> = [
  {
    title: "Name",
    key: "name",
    render: (_, customer) => (
      <Space>
        <Text>
          {customer.firstName} {customer.lastName}
        </Text>
      </Space>
    ),
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Referral Link Status",
    key: "link",
    render: (_, customer) => {
      if (customer.referralLinks.length === 0) {
        return (
          <Space>
            <WarningOutlined />
            <Typography.Text>No link created</Typography.Text>
          </Space>
        );
        // return <Button>Create Link</Button>;
      }

      if (customer.referralLinks[0].deliveries.length === 0) {
        return (
          <Space>
            <LinkOutlined />
            <Typography.Text>Created but not sent</Typography.Text>
          </Space>
        );
      }

      return (
        <Space>
          <CheckCircleOutlined />
          <Typography.Text>Created and Sent</Typography.Text>
        </Space>
      );
    },
  },
];

const CustomersTable: React.FC = () => {
  const [tableData, setTableDataWithKeys] = useState<any[]>([]);
  const [loadingMore, setLoadingMore] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);

  const setTableData = (data: any[]) => {
    setTableDataWithKeys(
      data.map((customer) => ({
        ...customer,
        key: customer.id,
      }))
    );
  };

  const {
    loading: initialLoading,
    error,
    data,
  } = useQuery<
    ListCustomersPaginatedQuery,
    ListCustomersPaginatedQueryVariables
  >(LIST_CUSTOMERS_PAGINATED, { variables: { first: 100 } });

  const [loadMoreCustomers] = useLazyQuery<
    ListCustomersPaginatedQuery,
    ListCustomersPaginatedQueryVariables
  >(LIST_CUSTOMERS_PAGINATED);

  const [searchCustomers] = useLazyQuery<
    SearchCustomersQuery,
    SearchCustomersQueryVariables
  >(SEARCH_CUSTOMERS);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const ready = !loading && !error;

  useEffect(() => {
    const fetchMoreData = async (endCursor: string) => {
      let cursor = endCursor;
      let counter = 0;
      let customers: any[] = [];
      do {
        const response = await loadMoreCustomers({
          variables: { first: 500, after: cursor },
        });
        if (response.error) {
          cursor = null;
        } else if (response.data) {
          customers.push(...response.data.listCustomers.customers);
          cursor = response.data.listCustomers.endCursor;
        }
        setTableData([...data.listCustomers.customers, ...customers]);
      } while (cursor && counter < 25);
      setLoadingMore(false);
    };

    if (data?.listCustomers.customers) {
      setTableData(data.listCustomers.customers);
    }

    if (data?.listCustomers.endCursor) {
      fetchMoreData(data?.listCustomers.endCursor);
    } else {
      setLoadingMore(false);
    }
  }, [data]);

  return (
    <CustomersTableStyle className="customersTableRoot">
      <FloatingCard>
        {loading && <Skeleton active />}
        {error && (
          <Empty
            description="Uh oh!"
            style={{ marginTop: 20 }}
          />
        )}
        {ready && (
          <>
            <Row>
              <SearchableTable
                columns={columns}
                data={tableData}
                expandedRowRender={(record: GQLCustomer) => (
                  <ExpandedCustomerRow customer={record} />
                )}
                handleSearch={async (value: string) => {
                  const response = await searchCustomers({
                    variables: { searchText: value },
                  });

                  return response.data.searchCustomers.customers.map(
                    (customer) => ({
                      key: customer.id,
                      ...customer,
                    })
                  );
                }}
              />
            </Row>
            {loadingMore && (
              <Row justify="end">
                <Col>
                  <Spin
                    size="small"
                    tip={`Loaded ${tableData.length} customers `}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </FloatingCard>
    </CustomersTableStyle>
  );
};

export default CustomersTable;
