import * as echarts from "echarts";
import React, { useEffect } from "react";

export interface SeriesData {
  name: string;
  data: number[];
  itemStyle: {
    color: string;
  };
}

const BarChart: React.FC<{
  id: string;
  seriesData: SeriesData[];
  xAxisLabels: string[];
  yAxisRange?: {
    min: number;
    max: number;
  };
}> = ({ id, seriesData, xAxisLabels, yAxisRange }) => {
  useEffect(() => {
    // Initialize echarts instance
    const chart = echarts.init(document.getElementById(id));

    // Chart configuration
    const options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: xAxisLabels,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          ...yAxisRange,
        },
      ],
      series: seriesData.map((seriesData: SeriesData) => {
        return {
          name: seriesData.name,
          type: "bar",
          barWidth: "50%",
          data: seriesData.data,
          itemStyle: seriesData.itemStyle,
        };
      }),
    };

    // Set options and render chart
    chart.setOption(options);

    // Clean up
    return () => {
      chart.dispose();
    };
  }, [id, seriesData, xAxisLabels]);

  return (
    <div
      id={id}
      style={{ width: "100%", height: "300px" }}
    />
  );
};

export default BarChart;
