import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import PosthogHandler from "lib/posthog";
import ThankYouForReferral from "pages/Referral/ThankYouForReferral";
import { InternalUserProvider } from "providers/InternalUserContext";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// eslint-disable-next-line import/extensions
import authConfig from "./authConfig.json";
import { REFERPRO_TOKEN_NAME } from "./constants";
import "./index.css";
import { privateClient, publicClient } from "./lib/apolloClient";
import { isLocalhost } from "./lib/location";
import InternalUser from "./pages/InternalUser/InternalUser";
import CollectReferralData from "./pages/Referral/CollectReferralData";
import { AuthenticationProvider } from "./providers/AuthenticationContext";
import { AuthorizationProvider } from "./providers/AuthorizationContext";
import { CompanyProvider } from "./providers/CompanyContext";

PosthogHandler.initialize();

const history = createBrowserHistory();

const isReferralLinkClick = () => window.location.pathname.match(/^\/r\/.+$/);
const isThankYouPage = () =>
  window.location.pathname.match(/^\/r\/.+\/.+\/thank-you$/);

const isLandingPage = () =>
  ["https://referpro.co", "https://www.referpro.co"].includes(
    window.location.origin
  ) && window.location.pathname === "/";
const isDevPage = () =>
  window.location.pathname.match(/^\/dev\/mark-as-internal$/);

const isLoggedOut = () => localStorage.getItem(REFERPRO_TOKEN_NAME) === null;
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: isLocalhost()
      ? "http://localhost:3000/auth0"
      : "https://referpro.co/auth0",
    audience: authConfig.audience,
  },
};

const contentToRender = () => {
  if (isReferralLinkClick()) {
    if (isThankYouPage()) {
      return (
        <BrowserRouter>
          <InternalUserProvider>
            <ApolloProvider client={publicClient}>
              <ThankYouForReferral target={window.location.href} />
            </ApolloProvider>
          </InternalUserProvider>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <InternalUserProvider>
            <ApolloProvider client={publicClient}>
              <CollectReferralData target={window.location.href} />
            </ApolloProvider>
          </InternalUserProvider>
        </BrowserRouter>
      );
    }
  } else if (isDevPage()) {
    return (
      <InternalUserProvider>
        <InternalUser />
      </InternalUserProvider>
    );
  } else if (isLandingPage() && isLoggedOut()) {
    window.location.replace("https://getrefer.pro");
  } else {
    return (
      <BrowserRouter>
        <Auth0Provider {...providerConfig}>
          <ApolloProvider client={privateClient}>
            <InternalUserProvider>
              <AuthenticationProvider>
                <AuthorizationProvider>
                  <CompanyProvider>
                    <App />
                  </CompanyProvider>
                </AuthorizationProvider>
              </AuthenticationProvider>
            </InternalUserProvider>
          </ApolloProvider>
        </Auth0Provider>
      </BrowserRouter>
    );
  }
};

ReactDOM.render(contentToRender(), document.getElementById("root"));
