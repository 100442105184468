import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  Campaign,
  Campaign as CampaignGql,
  ListCampaignsQuery,
} from "../../__generated__/graphql";
import { LIST_CAMPAIGNS } from "../../data/queries";
import Content from "../../layout/Content";
import CampaignsStyle from "./CampaignsStyle";
import CampaignGroupsTable from "./components/CampaignGroupsTable";
import CampaignsTable from "./components/CampaignsTable";
import CreateCampaignForm from "./components/CreateCampaignForm";
import CreateNewCampaignGroupForm from "./components/CreateCampaignGroupForm";

interface Props {}

const Customers: React.FC<Props> = () => {
  const [activateCampaignsData, setActiveCampaignsData] =
    useState<CampaignGql[]>();
  const [
    finishedOrDeactivatedCampaignsData,
    setFinishedOrDeactivatedCampaignsData,
  ] = useState<CampaignGql[]>();
  const listCampaigns = useQuery<ListCampaignsQuery>(LIST_CAMPAIGNS);

  useEffect(() => {
    const keyCampaign = (campaign: CampaignGql) => {
      return {
        ...campaign,
        key: campaign.id,
      };
    };

    if (listCampaigns.data) {
      const finishedOrDeactivatedCampaigns: CampaignGql[] = [];
      const activeCampaigns: CampaignGql[] = [];
      listCampaigns.data.listCampaigns.forEach((campaign: Campaign) => {
        if (campaign.finishedAt || campaign.deactivatedAt) {
          finishedOrDeactivatedCampaigns.push(campaign);
        } else {
          activeCampaigns.push(campaign);
        }
      });
      setFinishedOrDeactivatedCampaignsData(
        finishedOrDeactivatedCampaigns.map(keyCampaign)
      );
      setActiveCampaignsData(activeCampaigns.map(keyCampaign));
    }
  }, [listCampaigns]);

  return (
    <CampaignsStyle className="campaignsStyleRoot">
      <Content>
        <Row
          style={{ width: "100%" }}
          justify="space-evenly"
        >
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 13, offset: 1 }}
            style={{ marginBottom: 20 }}
          >
            <CampaignsTable
              tableData={activateCampaignsData}
              title="Existing Campaigns"
            />
            <CampaignsTable
              tableData={finishedOrDeactivatedCampaignsData}
              title="Finished and Deactivated Campaigns"
              style={{ marginTop: "20px" }}
            />
            <CampaignGroupsTable style={{ marginTop: "20px" }} />
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
          >
            <CreateCampaignForm />
            <CreateNewCampaignGroupForm style={{ marginTop: "20px" }} />
          </Col>
        </Row>
      </Content>
    </CampaignsStyle>
  );
};

export default Customers;
