import axios from "axios";
import { BASE_URL } from "../constants";

export default class Signin {
  static async verifyEmailPassword(email, password) {
    const response = await axios.post(`${BASE_URL}/login`, { email, password });
    return response.data;
  }

  static async sendPhoneCode(phone) {
    const response = await axios.post(`${BASE_URL}/login/code`, { phone });
    return response.data;
  }

  static async verifyPhoneCode(phone, code) {
    const response = await axios.post(`${BASE_URL}/login/verify`, {
      phone,
      code,
    });
    return response.data;
  }

  static async googleSignIn(token: string) {
    const response = await axios.post(`${BASE_URL}/login/google`, {
      token,
    });
    return response.data;
  }

  static async auth0SignIn(token: string) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${BASE_URL}/login/auth0`, {}, config);
    return response.data;
  }
}
