import { DollarCircleOutlined } from "@ant-design/icons";
import React from "react";
import Summary from "./Summary";

interface Prop {
  amount: number;
}

const ReferralRewardSummary: React.FC<Prop> = ({ amount }) => {
  return (
    <Summary
      title="Referral Rewards"
      amount={amount}
      prefix={<DollarCircleOutlined />}
    />
  );
};

export default ReferralRewardSummary;
