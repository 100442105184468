import { RiseOutlined } from "@ant-design/icons";
import React from "react";
import Summary from "./Summary";

interface Prop {
  amount: number;
  loading?: boolean;
}

const ReferralsReceivedSummary: React.FC<Prop> = ({
  amount,
  loading = false,
}) => {
  return (
    <Summary
      title="Referrals received"
      amount={amount}
      prefix={<RiseOutlined />}
      loading={loading}
    />
  );
};

export default ReferralsReceivedSummary;
