import { Card } from "antd";
import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  title?: ReactNode;
  fontSize?: number;
  loading?: boolean;
  style?: any;
  extra?: ReactNode;
}

const FloatingCard: React.FC<Props> = ({
  children,
  title,
  fontSize,
  loading = false,
  style = {},
  extra = null,
}) => {
  return (
    <Card
      title={title}
      style={{
        textAlign: "center",
        borderRadius: 20,
        boxShadow: "rgb(0 0 0 / 30%) 5px 7px 10px",
        ...(fontSize && { fontSize }),
        ...style,
      }}
      loading={loading}
      extra={extra}
    >
      {children}
    </Card>
  );
};

export default FloatingCard;
