import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import {
  Button,
  Collapse,
  Descriptions,
  Dropdown,
  Popconfirm,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import {
  CampaignExecution,
  Campaign as CampaignGql,
} from "../../../__generated__/graphql";
import FloatingCard from "../../../components/FloatingCard";
import SearchableTable from "../../../components/SearchableTable";
import { DEACTIVATE_CAMPAIGN } from "../../../data/mutations";
import { LIST_CAMPAIGNS } from "../../../data/queries";
import { formatDateTime } from "../../../lib/date";
import { prettyHour } from "../utils";

const { Panel } = Collapse;
const { Text } = Typography;

const CampaignsTableStyle = styled.div({
  "&.campaignsTableRoot": {
    ".ant-table-row:hover": {
      cursor: "pointer",
    },
  },
});

interface TableProps {
  tableData: CampaignGql[];
  title: string;
  style?: React.CSSProperties;
}

const columns: ColumnsType<CampaignGql> = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Created At",
    key: "createdAt",
    render: (_, campaign) => (
      <Text>{formatDateTime(campaign.createdAt, false)}</Text>
    ),
  },
  {
    title: "Status",
    key: "status",
    render: (_, campaign) => {
      let status = "Started";
      if (campaign.finishedAt) {
        status = "Finished";
      } else if (campaign.deactivatedAt) {
        status = "Deactivated";
      }
      return <Text>{status}</Text>;
    },
  },
];

const messagesSent = (executions: CampaignGql["executions"]) => {
  return executions.reduce((acc, execution) => {
    return execution.batches.reduce((acc, batch) => {
      return batch.size + acc;
    }, acc);
  }, 0);
};

const executionStatus = (execution: CampaignExecution) => {
  // Has finished?
  if (execution.finishedAt) {
    return "Finished";
  }

  if (new Date(execution.startsAt).getTime() < Date.now()) {
    return "Started";
  } else {
    return "Not Started";
  }
};

const CampaignsTable: React.FC<TableProps> = ({ tableData, title, style }) => {
  const [deactivateCampaign] = useMutation(DEACTIVATE_CAMPAIGN, {
    refetchQueries: [{ query: LIST_CAMPAIGNS }, "ListCampaignsQuery"],
  });

  const isTableDataReady = !!tableData;

  const expandedRowRender = ({
    config,
    executions,
    name,
    id: campaignId,
    createdAt,
    campaignGroups,
  }: CampaignGql) => (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%" }}
    >
      <Descriptions
        title="Configuration"
        layout="vertical"
        colon={false}
        size="small"
        column={{ xs: 1, lg: 1 }}
        extra={
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                      <Popconfirm
                        title={`Deactivating: ${name}`}
                        description="Are you sure? this action cannot be undone."
                        onConfirm={() =>
                          deactivateCampaign({
                            variables: {
                              input: {
                                campaignId,
                              },
                            },
                          })
                        }
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <DeleteOutlined /> Deactivate
                      </Popconfirm>
                    </>
                  ),
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button type="link">
              <EllipsisOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        }
      >
        <Descriptions.Item label="Iterations">
          {config.iterations}
        </Descriptions.Item>
        <Descriptions.Item label="Send Frequency">
          Every {config.frequency} days
        </Descriptions.Item>
        {config.sendAtHour && (
          <Descriptions.Item label="Send At Hour">
            {prettyHour(config.sendAtHour)}
          </Descriptions.Item>
        )}
        {config.sendAtHour && (
          <Descriptions.Item label="Send At Timezone">
            {config.sendAtTimezone}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Descriptions
        title="Overview"
        layout="vertical"
        colon={false}
        column={{ xs: 1, lg: 1 }}
      >
        <Descriptions.Item label="Message Sent Already">
          {messagesSent(executions)}
        </Descriptions.Item>
        <Descriptions.Item label="Next Send At">
          {executions.map((execution) => (
            <>{formatDateTime(execution.nextBatchAt)}</>
          ))}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title="Iterations"
        layout="vertical"
        colon={false}
        column={{ xs: 1, lg: 1 }}
      >
        <Descriptions.Item label="">
          <Collapse style={{ width: "100%" }}>
            {executions.map((execution, i) => {
              return (
                <Panel
                  header={`Execution #${i + 1} (Start Time ${formatDateTime(
                    execution.startsAt
                  )})`}
                  key="1"
                >
                  <Descriptions title="Overview">
                    <Descriptions.Item label="Status">
                      {executionStatus(execution)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions title="Batches">
                    <Descriptions.Item label="Batch Counter">
                      {execution.batches.length}
                    </Descriptions.Item>
                  </Descriptions>
                  {execution.batches.length > 0 && (
                    <Collapse defaultActiveKey="1">
                      {execution.batches.map((batch, i) => {
                        return (
                          <Panel
                            header={`Batch #${i + 1}`}
                            key={i}
                          >
                            <p>Batch Number: {batch.number}</p>
                            <p>Batch ID: {batch.id}</p>
                            <p>
                              Finished At: {formatDateTime(batch?.finishedAt)}
                            </p>
                            <p>Batch Offset: {batch.offset}</p>
                            <p>Batch Size: {batch.size}</p>
                          </Panel>
                        );
                      })}
                    </Collapse>
                  )}
                </Panel>
              );
            })}
          </Collapse>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title="Groups"
        layout="vertical"
        colon={false}
        column={{ xs: 1, lg: 1 }}
      >
        {campaignGroups && campaignGroups.length > 0 ? (
          campaignGroups.map((group) => (
            <Descriptions.Item label={group.name}>
              {group.description ? (
                <Text key={group.id}>{group.description}</Text>
              ) : null}
            </Descriptions.Item>
          ))
        ) : (
          <Descriptions.Item
            label="No groups assigned"
            children={null}
          />
        )}
      </Descriptions>
    </Space>
  );

  return (
    <CampaignsTableStyle className="campaignsTableRoot">
      <FloatingCard
        title={title}
        style={style || {}}
      >
        {!isTableDataReady && <Skeleton active />}
        {isTableDataReady && (
          <SearchableTable
            columns={columns}
            data={tableData}
            expandedRowRender={expandedRowRender}
          />
        )}
      </FloatingCard>
    </CampaignsTableStyle>
  );
};

export default CampaignsTable;
