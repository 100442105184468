import { Col, Input, Row, Space, Table } from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";

type ExpandedRowRender<ValueType> = (
  record: ValueType,
  index: number,
  indent: number,
  expanded: boolean
) => React.ReactNode;

interface TableParams {
  pagination?: TablePaginationConfig;
}

const SearchableTable: FC<{
  columns;
  data;
  expandedRowRender?: ExpandedRowRender<any>;
  handleSearch?: (value: string) => Promise<any>;
}> = ({ columns, data, expandedRowRender, handleSearch }) => {
  const [initialTableData, setInitialTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchedData, setSearchedData] = useState([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {},
  });

  useEffect(() => {
    setInitialTableData(data);
    setTableData(data);
  }, [data]);

  if (!handleSearch) {
    handleSearch = async (value: string) => {
      if (!value || value === "") return setTableData(data);

      if (data.length === 0) return setTableData([]);

      const keys = Object.keys(data[0]);

      const filteredData = data.filter((item: any) => {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          try {
            if (item[key]?.toLowerCase().includes(value.toLowerCase())) {
              return true;
            }
          } catch (e) {
            // do nothing
          }
        }
        return false;
      });
      return filteredData;
    };
  }

  const handleRowClick = (record: any) => {
    const key = record.id.toString();
    const expanded = expandedRowKeys.includes(key);

    if (expanded) {
      setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
      >
        <Row>
          <Col span={24}>
            <Input.Search
              placeholder="Search . . ."
              onSearch={async (value) => {
                if (value === "") {
                  setTableData(initialTableData);
                } else {
                  setTableData(searchedData);
                }
              }}
              onChange={async (e) => {
                if (e.target.value !== "") {
                  setSearchedData(await handleSearch(e.target.value));
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={tableData}
              onRow={(customer) => ({
                onClick: () => handleRowClick(customer),
              })}
              expandable={
                expandedRowRender
                  ? {
                      expandedRowKeys,
                      expandRowByClick: true,
                      onExpand: (_, record) => handleRowClick(record),
                      expandedRowRender: expandedRowRender,
                    }
                  : undefined
              }
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default SearchableTable;
