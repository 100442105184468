import { Layout, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { isLocalhost } from "../../lib/location";
import { useAuthentication } from "../../providers/AuthenticationContext";

const { Title } = Typography;
const { Content } = Layout;

export default function Landing() {
  const history = useHistory();
  const { isSignedIn } = useAuthentication();

  if (isSignedIn()) {
    history.push("/app");
  } else if (isLocalhost()) {
    history.push("/sign-in");
  } else {
    window.location.replace("https://getrefer.pro");
  }

  return <></>;
}
