import styled from "@emotion/styled";
import { backgroundMain, themeColors } from "../../theme";

export default styled.div({
  "&.referralsStyleRoot": {
    ".ant-list-item-meta-title": {
      fontSize: "16px !important",
    },
    ".mb20": {
      marginBottom: 20,
    },
    ".mb10": {
      marginBottom: 10,
    },
    ".stat-box": {
      boxShadow: "0 6px 10px rgb(0 0 0 / 12%)",
      borderRadius: 20,
    },
    ".colorMain": {
      color: themeColors["main"],
    },
    ".backgroundMain": {
      ...backgroundMain,
    },
  },
});
