import { Col, Row, Space, Spin } from "antd";
import React from "react";
import Content from "../../layout/Content";
import CompanySettings from "./components/CompanySettings";
import Integrations from "./components/Integrations";
import UserProfile from "./components/UserProfile";
import Users from "./components/Users";

import { useCompany } from "../../providers/CompanyContext";
import SettingsStyle from "./SettingsStyle";

interface Props {}

const CompanySettingsPage: React.FC<Props> = () => {
  const { loading, company, user } = useCompany();

  return (
    <SettingsStyle className="settingsStyleRoot">
      <Content>
        {loading ? (
          <Row
            justify="center"
            style={{ marginTop: 180, width: "100%" }}
          >
            <Spin size="large" />
          </Row>
        ) : (
          <>
            <Row
              justify="center"
              style={{ width: "100%" }}
            >
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                xl={{ span: 10 }}
              >
                <Space direction="vertical">
                  <Row>
                    <CompanySettings />
                  </Row>
                  <Row>{user && <UserProfile />}</Row>
                </Space>
              </Col>
              <Col
                xs={{ span: 22, offset: 0 }}
                sm={{ span: 20 }}
                xl={{ span: 10, offset: 1 }}
              >
                <Space direction="vertical">
                  <Row style={{ width: "100%" }}>
                    <Users />
                  </Row>
                  <Row>
                    {company && (
                      <Integrations zapierApiKey={company.zapierApiToken} />
                    )}
                  </Row>
                </Space>
              </Col>
            </Row>
          </>
        )}
      </Content>
    </SettingsStyle>
  );
};

const Settings: React.FC<Props> = () => {
  return <CompanySettingsPage />;
};

export default Settings;
