import { Layout } from "antd";

import { useAuth0 } from "@auth0/auth0-react";

const { Content } = Layout;

export default ({ setReferProToken }) => {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect();

  return <Content className="p-0"></Content>;
};
