import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";

import {
  CheckOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { useAuthorization } from "../providers/AuthorizationContext";
import ScheduleADemo from "./ScheduleADemo";
import "./components.css";

type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const CREATE_EMPLOYEE = gql`
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      employee {
        id
        firstName
        lastName
      }
    }
  }
`;

const EmployeeFormWrapper = styled.div`
  @media screen and (max-width: 576px) {
    .responsive-input {
      font-size: 16px;
      padding: 7px 10px;
    }
  }
`;

const CreateNewEmployee = ({ onEmployeeChange }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const employee = { firstName, lastName, email, phone };
    onEmployeeChange(employee);
  }, [firstName, lastName, email, phone]);

  return (
    <div>
      <EmployeeFormWrapper>
        <Form
          name="createNewEmployee"
          onFinish={(values) => alert("Finished!")}
          initialValues={{}}
          size="middle"
          scrollToFirstError
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input a first name!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="responsive-input"
              placeholder="First name"
              prefix={<UserOutlined />}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input a last name!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="responsive-input"
              placeholder="Last name"
              prefix={<UserOutlined />}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                message: "Please input an email!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="responsive-input"
              placeholder="Email"
              prefix={<MailOutlined />}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                message: "Please input a phone number!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Phone number"
              className="responsive-input"
              prefix={<PhoneOutlined />}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
        </Form>
      </EmployeeFormWrapper>
    </div>
  );
};

interface Props {
  onFinish: () => void;
}

const INITIAL_STATE = {
  sendType: "",
  employee: {} as any,
  isSubmitting: false,
  showSuccess: false,
  showFailure: false,
  stepOneFinished: false,
  stepTwoFinished: false,
  successMessage: "",
};

const EmployeeCreateForm: React.FC<Props> = ({ onFinish }) => {
  const [employee, setEmployee] = useState<Employee>(INITIAL_STATE.employee);
  const [isSubmitting, setIsSubmitting] = useState(INITIAL_STATE.isSubmitting);
  const [showSuccess, setShowSuccess] = useState(INITIAL_STATE.showSuccess);
  const [showFailure, setShowFailure] = useState(INITIAL_STATE.showFailure);
  const [successMessage, setSuccessMessage] = useState(
    INITIAL_STATE.successMessage
  );

  const showForm = !(showSuccess || showFailure);

  const [stepOneFinished, setStepOneFinished] = useState(
    INITIAL_STATE.stepOneFinished
  );

  const [createEmployee, _] = useMutation(CREATE_EMPLOYEE);

  const { hasAllLinkPermissions } = useAuthorization();

  useEffect(() => {
    if (
      employee.firstName &&
      employee.lastName &&
      employee.email &&
      employee.phone
    ) {
      setStepOneFinished(true);
    } else {
      setStepOneFinished(false);
    }
  }, [employee]);
  const readyToSubmit = stepOneFinished;

  const resetState = () => {
    setEmployee(INITIAL_STATE.employee);
    setIsSubmitting(INITIAL_STATE.isSubmitting);
    setShowSuccess(INITIAL_STATE.showSuccess);
    setShowFailure(INITIAL_STATE.showFailure);
    setStepOneFinished(INITIAL_STATE.stepOneFinished);
    setSuccessMessage(INITIAL_STATE.successMessage);
  };

  return (
    <div>
      <div>
        {showSuccess && (
          <Result
            status="success"
            subTitle={successMessage}
            extra={[
              <Space direction="vertical">
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      type="primary"
                      key="done"
                      onClick={() => {
                        resetState();
                        onFinish();
                      }}
                    >
                      Done
                    </Button>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      key="again"
                      onClick={resetState}
                    >
                      Create Another
                    </Button>
                  </Col>
                </Row>
              </Space>,
            ]}
          />
        )}
        {showFailure && (
          <Result
            status="error"
            extra={[
              <Space direction="vertical">
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      type="primary"
                      key="done"
                      onClick={resetState}
                    >
                      Try Again
                    </Button>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col flex="auto">
                    <Button
                      key="again"
                      onClick={onFinish}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </Space>,
            ]}
          />
        )}
        {showForm && (
          <Space
            direction="vertical"
            style={{ width: "100%" }}
            size={30}
          >
            <Row>
              <Col flex="auto">
                {!hasAllLinkPermissions && (
                  <Alert
                    message={
                      <>
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                        >
                          <Row justify="center">
                            <Col>
                              <Typography.Text>
                                Free accounts do not include Employee creation.
                                Want all the features?
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col>
                              <ScheduleADemo />
                            </Col>
                          </Row>
                        </Space>
                      </>
                    }
                    type="info"
                    showIcon={false}
                    closable={false}
                    banner
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col
                span={3}
                style={{ paddingTop: 5 }}
              >
                <div>
                  <div
                    className="ant-steps ant-steps-item-tail"
                    style={{
                      width: 1,
                      height: "100%",
                      left: 12,
                      paddingLeft: 0,
                      paddingRight: 0,
                      borderLeft: "1px solid rgba(0, 0, 0, 0.20)",
                      top: 30,
                    }}
                  ></div>
                  <Button
                    shape="circle"
                    size="small"
                    type="primary"
                    style={{
                      backgroundColor: stepOneFinished ? "transparent" : "",
                    }}
                  >
                    {!stepOneFinished && <>1</>}
                    {stepOneFinished && (
                      <CheckOutlined style={{ color: "rgb(62, 145, 247)" }} />
                    )}
                  </Button>
                </div>
              </Col>
              <Col flex="auto">
                <CreateNewEmployee onEmployeeChange={setEmployee} />
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <div>
                  <Button
                    shape="circle"
                    size="small"
                    type={readyToSubmit ? "primary" : "default"}
                    disabled={!readyToSubmit}
                  >
                    2
                  </Button>
                </div>
              </Col>
              <Col flex="auto">
                <Spin spinning={isSubmitting}>
                  <Button
                    onClick={async () => {
                      try {
                        setIsSubmitting(true);

                        if (employee.id && hasAllLinkPermissions) {
                          // do nothing
                        } else if (hasAllLinkPermissions) {
                          await createEmployee({
                            variables: {
                              input: {
                                firstName: employee.firstName,
                                lastName: employee.lastName,
                                ...(employee.email && {
                                  email: employee.email,
                                }),
                                ...(employee.phone && {
                                  phone: employee.phone,
                                }),
                              },
                            },
                          });
                        }

                        setSuccessMessage(
                          `Created new employee: ${employee.firstName} ${employee.lastName}`
                        );
                        setShowSuccess(true);
                      } finally {
                        setIsSubmitting(false);
                      }
                    }}
                    block
                    disabled={!readyToSubmit}
                    type={readyToSubmit ? "primary" : "default"}
                  >
                    CREATE
                  </Button>
                </Spin>
              </Col>
            </Row>
          </Space>
        )}
      </div>
    </div>
  );
};

export default EmployeeCreateForm;
