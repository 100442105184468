import { Form, Typography } from "antd";
import React, { useEffect, useState } from "react";

import EmailInput from "./basicComponents/EmailInput";
import NameInput from "./basicComponents/NameInput";
import PhoneNumberInput from "./basicComponents/PhoneNumberInput";

const { Text } = Typography;

const ReferringPersonInput: React.FC<{
  disabled?: boolean;
  setIsValid: (valid: Boolean) => unknown;
  fadeInOffset?: number;
  setValues: (values: {
    name: string;
    phoneNumber: string;
    email: string;
  }) => unknown;
}> = ({ disabled, setIsValid, fadeInOffset = 0, setValues }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [nameIsValid, setNameIsValid] = useState(false);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);

  useEffect(() => {
    // for communication with parent component
    setIsValid(nameIsValid && phoneNumberIsValid && emailIsValid);
    setValues({ name, phoneNumber, email });
  }, [nameIsValid, phoneNumberIsValid, emailIsValid]);

  return (
    <>
      <Form.Item
        className={`appear-from-top-${fadeInOffset}`}
        name="referredByName"
        label={
          <Text
            style={{
              fontWeight: 200,
              fontSize: "20px",
            }}
          >
            What's your name?
          </Text>
        }
      >
        <NameInput
          disabled={disabled}
          onChange={setName}
          setIsValid={setNameIsValid}
          placeholder="Enter your name"
        />
      </Form.Item>
      <Form.Item
        className={`appear-from-top-${1 + fadeInOffset}`}
        name="referredByPhoneNumber"
        label={
          <Text
            style={{
              fontWeight: 200,
              fontSize: "20px",
            }}
          >
            What's your phone number?
          </Text>
        }
      >
        <PhoneNumberInput
          disabled={!nameIsValid || disabled}
          onChange={setPhoneNumber}
          placeholder="Enter your phone number"
          setIsValid={setPhoneNumberIsValid}
        />
      </Form.Item>
      <Form.Item
        className={`appear-from-top-${2 + fadeInOffset}`}
        name="referredByEmail"
        label={
          <Text
            style={{
              fontWeight: 200,
              fontSize: "20px",
            }}
          >
            And what's your email?
          </Text>
        }
      >
        <EmailInput
          disabled={!phoneNumberIsValid || !nameIsValid || disabled}
          onChange={setEmail}
          placeholder="Enter your email"
          setIsValid={setEmailIsValid}
        />
      </Form.Item>
    </>
  );
};

export default ReferringPersonInput;
