import { Col, Radio, Row } from "antd";
import BarChart, { SeriesData } from "components/BarChart";
import React, { useState } from "react";
import { BarChartTimeRangeType } from "./createData";

interface MetricsBarChartProps {
  seriesData: SeriesData[];
  xAxisLabels: string[];
  yAxisRange?: {
    min: number;
    max: number;
  };
  id_appender?: string;
}

const MetricsBarChart: React.FC<MetricsBarChartProps> = ({
  seriesData,
  xAxisLabels,
  yAxisRange,
  id_appender = "", // this is used to append to the id of the chart so that it is unique and can be filled when the library asks for the id
}) => {
  if (seriesData.length === 0) {
    return (
      <Row justify="center">
        <Col flex="auto">
          <p>No data available</p>
        </Col>
      </Row>
    );
  }
  return (
    <Row justify="center">
      <Col flex="auto">
        <BarChart
          id={"metrics-bar-chart" + id_appender}
          seriesData={seriesData}
          xAxisLabels={xAxisLabels}
          yAxisRange={yAxisRange}
        />
      </Col>
    </Row>
  );
};

interface ChooseBarChartTimeRangeProps {
  chooseTimeRangeType?: (timeRangeType: BarChartTimeRangeType) => void;
  chooseTimeRange?: (timeRange: any) => void;
  defaultTimeRangeType?: BarChartTimeRangeType;
}

export const ChooseBarChartTimeRange: React.FC<
  ChooseBarChartTimeRangeProps
> = ({
  chooseTimeRangeType,
  chooseTimeRange,
  defaultTimeRangeType = BarChartTimeRangeType.MONTH,
}) => {
  const [timeRangeType, setTimeRangeType] =
    useState<BarChartTimeRangeType>(defaultTimeRangeType);
  return (
    <Row
      justify="center"
      align="middle"
    >
      <span style={{ paddingRight: ".5em" }}>Group by: </span>
      <Radio.Group
        onChange={(e) => {
          setTimeRangeType(e.target.value);
          chooseTimeRangeType(e.target.value);
        }}
        value={timeRangeType}
      >
        <Radio value={BarChartTimeRangeType.MONTH}>Month</Radio>
        <Radio value={BarChartTimeRangeType.DAY}>Day</Radio>
        <Radio value={BarChartTimeRangeType.YEAR}>Year</Radio>
      </Radio.Group>
    </Row>
  );
};

export default MetricsBarChart;
