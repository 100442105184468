export function formatDateTime(
  dateTimeString: string,
  includeTime: boolean = true
): string {
  const dateTime = new Date(dateTimeString);
  if (dateTime.getTime() > 0) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      ...(includeTime && {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }),
    };
    return dateTime.toLocaleString("en-US", options);
  }

  return "";
}
