import { FeedGroup } from "__generated__/graphql";

export enum BarChartTimeRangeType {
  MONTH,
  DAY,
  YEAR,
}

export interface SeriesData {
  name: string;
  data: number[];
  itemStyle: {
    color: string;
  };
}

// const { RangePicker } = DatePicker;

function formatDate(
  date: Date,
  includeMonth = true,
  includeDay = true
): string {
  const month = date.getMonth() + 1; // getMonth() returns 0-11, add 1 for 1-12
  const day = date.getDate();
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of year
  let dateString = "";
  if (includeMonth) {
    dateString += month + "/";
  }
  if (includeDay) {
    dateString += day + "/";
  }
  dateString += year;
  return dateString;
}

function createBarChartDataFromFeedGroups(
  feedGroups: FeedGroup[],
  timeRangeType: BarChartTimeRangeType,
  calculateData: (
    previousAccumulatedValue: number,
    feedGroup: FeedGroup,
    totalPreviousAccumulatedAmount?: number
  ) => number
) {
  let currentTimeRange: Date | null = null;
  let totalReferralAmount = 0;
  let currentTimeRangeReferrals = 0;
  let referralsInTimeRange: number[] = [];
  let xAxisLabels = [];
  for (let i = feedGroups.length - 1; i >= 0; i--) {
    let feedGroup = feedGroups[i];
    let date = new Date(feedGroup.date);
    switch (timeRangeType) {
      case BarChartTimeRangeType.MONTH:
        // if the month has changed, add the current month's referral count to the list and reset the count
        if (
          currentTimeRange === null ||
          date.getMonth() !== currentTimeRange.getMonth()
        ) {
          if (currentTimeRange !== null) {
            xAxisLabels.push(formatDate(currentTimeRange, true, false));
            referralsInTimeRange.push(currentTimeRangeReferrals);
          }
          totalReferralAmount = 0;
          currentTimeRangeReferrals = 0;
          currentTimeRange = date;
        }
        currentTimeRangeReferrals = calculateData(
          currentTimeRangeReferrals,
          feedGroup,
          totalReferralAmount
        );
        totalReferralAmount += feedGroup.entries.length;
        break;
      case BarChartTimeRangeType.DAY:
        if (
          currentTimeRange === null ||
          date.getDate() !== currentTimeRange.getDate()
        ) {
          if (currentTimeRange !== null) {
            xAxisLabels.push(formatDate(currentTimeRange));
            referralsInTimeRange.push(currentTimeRangeReferrals);
          }
          totalReferralAmount = 0;
          currentTimeRangeReferrals = 0;
          currentTimeRange = date;
        }
        currentTimeRangeReferrals = calculateData(
          currentTimeRangeReferrals,
          feedGroup,
          totalReferralAmount
        );
        totalReferralAmount += feedGroup.entries.length;
        break;
      case BarChartTimeRangeType.YEAR:
        if (
          currentTimeRange === null ||
          date.getFullYear() !== currentTimeRange.getFullYear()
        ) {
          if (currentTimeRange !== null) {
            xAxisLabels.push(formatDate(currentTimeRange, false, false));
            referralsInTimeRange.push(currentTimeRangeReferrals);
          }
          totalReferralAmount = 0;
          currentTimeRangeReferrals = 0;
          currentTimeRange = date;
        }
        currentTimeRangeReferrals = calculateData(
          currentTimeRangeReferrals,
          feedGroup,
          totalReferralAmount
        );
        totalReferralAmount += feedGroup.entries.length;
        break;
      default:
    }
  }
  // add the last time range to the list
  switch (timeRangeType) {
    case BarChartTimeRangeType.MONTH:
      xAxisLabels.push(formatDate(currentTimeRange as Date, true, false));
      referralsInTimeRange.push(currentTimeRangeReferrals);
      break;
    case BarChartTimeRangeType.DAY:
      xAxisLabels.push(formatDate(currentTimeRange as Date));
      referralsInTimeRange.push(currentTimeRangeReferrals);
      break;
    case BarChartTimeRangeType.YEAR:
      xAxisLabels.push(formatDate(currentTimeRange as Date, false, false));
      referralsInTimeRange.push(currentTimeRangeReferrals);
      break;
    default:
  }
  return { referralsInTimeRange, xAxisLabels };
}

export function createTotalReferralBarChartData(
  feedGroups: FeedGroup[],
  timeRangeType: BarChartTimeRangeType
) {
  let seriesData: SeriesData[] = [];
  // go through the feedGroups in reverse order to display the oldest data on the left and newest data on the right
  if (feedGroups.length === 0) {
    return { seriesData, xAxisLabels: [] };
  }
  function calculateTotalReferrals(
    previousAccumulatedValue: number,
    feedGroup: FeedGroup
  ) {
    let referralAmount = feedGroup.entries.length;
    return previousAccumulatedValue + referralAmount;
  }

  const { referralsInTimeRange, xAxisLabels } =
    createBarChartDataFromFeedGroups(
      feedGroups,
      timeRangeType,
      calculateTotalReferrals
    );

  seriesData.push({
    name: "Referrals",
    data: referralsInTimeRange,
    itemStyle: {
      color: "#1890ff",
    },
  });
  return { seriesData, xAxisLabels };
}

export function createTotalSuccessfulReferralBarChartData(
  feedGroups: FeedGroup[],
  timeRangeType: BarChartTimeRangeType
) {
  let seriesData: SeriesData[] = [];
  // go through the feedGroups in reverse order to display the oldest data on the left and newest data on the right
  if (feedGroups.length === 0) {
    return { seriesData, xAxisLabels: [] };
  }
  function calculateTotalSuccessfulReferrals(
    previousAccumulatedValue: number,
    feedGroup: FeedGroup
  ) {
    let successfulReferralAmount = 0;
    for (let entry of feedGroup.entries) {
      if (
        // this conversion is done because the query grabs the data even if the type does not have it. This is a workaround
        (entry as unknown as { markedAsSuccessfulDate: number })
          .markedAsSuccessfulDate
      ) {
        successfulReferralAmount++;
      }
    }
    return previousAccumulatedValue + successfulReferralAmount;
  }

  const { referralsInTimeRange, xAxisLabels } =
    createBarChartDataFromFeedGroups(
      feedGroups,
      timeRangeType,
      calculateTotalSuccessfulReferrals
    );

  seriesData.push({
    name: "Referrals",
    data: referralsInTimeRange,
    itemStyle: {
      color: "#1890ff",
    },
  });
  return { seriesData, xAxisLabels };
}

export function createReferralConversionRateBarChartData(
  feedGroups: FeedGroup[],
  timeRangeType: BarChartTimeRangeType
) {
  let seriesData: SeriesData[] = [];
  // go through the feedGroups in reverse order to display the oldest data on the left and newest data on the right
  if (feedGroups.length === 0) {
    return { seriesData, xAxisLabels: [] };
  }
  function calculateReferralConversionRates(
    previousAccumulatedValue: number,
    feedGroup: FeedGroup,
    totalPreviousAccumulatedAmount: number
  ) {
    const totalReferralAmount = feedGroup.entries.length;
    if (totalReferralAmount + totalPreviousAccumulatedAmount === 0) {
      return 0;
    }
    let successfulReferralAmount = 0;
    for (let entry of feedGroup.entries) {
      if (
        // this conversion is done because the query grabs the data even if the type does not have it. This is a workaround
        (entry as unknown as { markedAsSuccessfulDate: number })
          .markedAsSuccessfulDate
      ) {
        successfulReferralAmount++;
      }
    }
    // in this case previousAccumulatedValue is the ratio of successful referrals to total referrals, so this gets the total amount
    const totalPreviousSuccessfulReferrals =
      totalPreviousAccumulatedAmount * previousAccumulatedValue;
    return (
      (successfulReferralAmount + totalPreviousSuccessfulReferrals) /
      (totalReferralAmount + totalPreviousAccumulatedAmount)
    );
  }

  const { referralsInTimeRange, xAxisLabels } =
    createBarChartDataFromFeedGroups(
      feedGroups,
      timeRangeType,
      calculateReferralConversionRates
    );

  seriesData.push({
    name: "Referrals",
    data: referralsInTimeRange,
    itemStyle: {
      color: "#1890ff",
    },
  });
  return { seriesData, xAxisLabels };
}
